import React from 'react';
import {
  FormControl,
  Table,
} from 'react-bootstrap';

import { installmentStatus } from '../../utils/consts';

class GenerateInstallmentsTable extends React.Component {

  handleChange = (id, key) => ({ target: { value } }) =>  {
    const {
      updateInstallment,
    } = this.props;

    updateInstallment(id, key, value);
  }

  handleDelete = ({ id, _destroy }) => () => {
    const {
      updateInstallment,
    } = this.props;

    updateInstallment(id, '_destroy', !_destroy);
  }

  render() {
    const {
      installments,
      cleanAll,
      showCleanAll,
    } = this.props;

    return (
      <div className="mt-10">
        {
          showCleanAll && (
            <div className="alert alert-warning">
              Apenas os campos que possuírem o fundo amarelo serão calculados automaticamente.
              <a
                onClick={cleanAll}
              >
                {" "}
                Clique aqui para recalcular todos os valores.
              </a>
            </div>
          )
        }

        <Table responsive striped id="installments-table">
          <thead>
            <tr>
              <th>
                Número
              </th>
              <th>
                Data de vencimento
              </th>
              <th>
                Valor
              </th>
              <th>
                Excluir
              </th>
            </tr>
          </thead>
          <tbody>
            {
              Object.values(installments).map(i => (
                <tr key={i.id || i.generated_id} className={i._destroy ? "destroyed-row" : ""}>
                  <td>{i.number}</td>
                  <td>{i.due_date}</td>
                  <td>
                    <FormControl
                      disabled={i.status === installmentStatus.PAID || i._destroy}
                      className={i.touched ? "" : "clean"}
                      type="number"
                      value={i.value}
                      onChange={this.handleChange(i.id || i.generated_id, "value")}
                    />
                  </td>
                  <td>
                    {
                      i.id && i.status !== installmentStatus.PAID && (
                        <i className="material-icons clickable" onClick={this.handleDelete(i)}>
                          delete
                        </i>
                      )
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

export default GenerateInstallmentsTable;
