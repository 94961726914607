import React from "react";
import {
  Col,
  Row,
  FormGroup,
  ControlLabel,
} from 'react-bootstrap';

import Select from 'react-select';
import { DatePicker } from '@blueprintjs/datetime';

import LocaleUtils, { getDefaultMaxDate } from "../../utils/LocaleUtils";

class InstallmentForm extends React.Component {

  render() {
    const {
      showDatePicker = true,
      disableInput = false,
      company,
      companies,
      bank_account,
      bankAccounts,
      payment_method,
      paymentMethods,
      paid_at,
      handleSelect
    } = this.props;

    const {
      id: selectedCompanyId,
    } = company || {};
    const filteredBankAccounts = company.id ? bankAccounts.filter(({ company_id }) => selectedCompanyId === company_id) : [];

    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <ControlLabel>Empresa</ControlLabel>
            <Select
              isDisabled={disableInput}
              value={company}
              placeholder="Selecione..."
              onChange={handleSelect("company")}
              options={companies}
              getOptionLabel={({ company_name }) => company_name}
              getOptionValue={({ id }) => id}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Conta bancária</ControlLabel>
            <Select
              isDisabled={filteredBankAccounts.length === 0 || disableInput}
              value={bank_account}
              placeholder="Selecione..."
              onChange={handleSelect("bank_account")}
              options={filteredBankAccounts}
              getOptionLabel={({ identification }) => identification}
              getOptionValue={({ id }) => id}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Método de pagamento</ControlLabel>
            <Select
              isDisabled={disableInput}
              value={payment_method}
              placeholder="Selecione..."
              onChange={handleSelect("payment_method")}
              options={paymentMethods}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <ControlLabel>Data de pagamento</ControlLabel>
            {
              showDatePicker
                ? (
                  <DatePicker
                    maxDate={getDefaultMaxDate()}
                    value={paid_at}
                    onChange={handleSelect("paid_at")}
                    localeUtils={LocaleUtils}
                  />
                )
                : (
                  <p>{moment(paid_at).format("DD/MM/YYYY")}</p>
                )
            }
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default InstallmentForm;
