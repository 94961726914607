import React from "react";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  colorBox: {
    width: 20,
    height: 20,
    marginRight: 10,
  }
};

const LegendItem = ({
  borderColor = undefined,
  color,
  description,
}) => {
  const border = {
    borderColor,
    borderWidth: borderColor ? 2 : 0,
    borderStyle: borderColor ? "solid" : "initial",
  };
  return (
    <div style={styles.container}>
      <div style={{ ...styles.colorBox, backgroundColor: color, ...border }} />

      <span>
        {description}
      </span>
    </div>
  );
}

export default LegendItem;
