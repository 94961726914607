import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Panel, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { loadingLabel } from "../../commons/strings"

const { Body } = Panel;

class ServiceInvoicesTable extends React.Component {

  invoiceStatusMapper = {
    Autorizada: ({ pdf_link, xml_link }) => [
      {
        icon: "file-pdf-o",
        tooltip: "Autorizada, clique para baixar PDF",
        link: pdf_link,
        target: "_blank"
      },
      {
        icon: "file-code-o",
        tooltip: "Autorizada, clique para baixar XML",
        link: xml_link,
        target: "_blank"
      },
    ],
    Negada: ({ serviceOrderId, reason }) => ({
      icon: "exclamation",
      tooltip: `Negada${reason ? `: ${reason}` : ""}`,
      link: `/close_service_order/${serviceOrderId}`,
    }),
    Cancelada: () => ({
      icon: "remove",
      tooltip: "Nota fiscal cancelada!",
      link: "javascript:void(0)",
    }),
    CancelamentoNegado: ({ id }) => ({
      icon: "exclamation-circle",
      tooltip: "Nota fiscal não pode ser cancelada, tentar novamente?",
      link: "javascript:void(0)",
      onClick: this.cancelNfse(id),
    })
  }

  state = {
    loadingItemId: null,
  };

  displayResult = successMessage => ({ data }) => {
    this.setState(
      { loadingItemId: null },
      () => {
        if (data.success) {
          toastr.success(successMessage);
        } else {
          toastr.error(data.errors, "Erro");
        }
      }
    );
  };

  cancelNfse = id => () => {
    this.setState(
      { loadingItemId: id },
      () => {
        axios
          .put(`/cancel_nfse/${id}`)
          .then(this.displayResult("O cancelamento da nota fiscal foi solicitado"))
          .catch(error => {
            this.setState(
              { loadingItemId: null },
              () => toastr.error(error, "Erro")
            );
          });
      }
    );
  }

  emitNfse = id => () => {
    this.setState(
      { loadingItemId: id },
      () => {
        axios
          .put(`/emit_nfse/${id}`)
          .then(this.displayResult("Nota fiscal enviada com sucesso"))
          .catch(error => {
            this.setState(
              { loadingItemId: null },
              () => toastr.error(error, "Erro")
            );
          });
      }
    );
  }

  renderTooltip = tooltip => <Tooltip id={tooltip}>{tooltip}</Tooltip>

  renderInvoiceStatusIcon = (status, serviceOrder) => {
    let invoiceIcons = this.invoiceStatusMapper[status] ? this.invoiceStatusMapper[status](serviceOrder) : [];
    invoiceIcons = Array.isArray(invoiceIcons) ? invoiceIcons : [invoiceIcons];


    return (
      <div
        style={{
          display: "flex",
          justifyContent: status ? "space-around" : "center"
        }}
      >
        <span>{status === "CancelamentoNegado" ? "Cancelamento Negado" : status}</span>

        {invoiceIcons.map(({ icon, tooltip, link, onClick }) => (
          <OverlayTrigger
            overlay={this.renderTooltip(tooltip)}
            placement="right"
            key={tooltip}
          >
            <a href={link} onClick={onClick}>
              <i className={`fa fa-2x fa-${icon}`} />
            </a>
          </OverlayTrigger>
        ))}
      </div>
    );


  }

  renderCancelInvoiceIcon = (id, row) => {
    if (id && this.state.loadingItemId === id) {
      return (
        <div className="sk-spinner-pulse sk-spinner" role="status" style={{background: "#337ABA"}}>
          <span className="sr-only">{loadingLabel}</span>
        </div>
      );
    }

    if (row.id && row.enotas_id === null) {
      return (
        <div style={{display: "flex", justifyContent: "center"}}>
          <OverlayTrigger
            overlay={this.renderTooltip('Tentar enviar novamente')}
            placement="right"
            key={'Tentar enviar novamente'}
          >
            <a href="javascript:void(0)" onClick={this.emitNfse(id)}>
              <i className="fa fa-2x fa-rotate-right"/>
            </a>
          </OverlayTrigger>
        </div>
      );
    } else if (id && row.status !== 'Cancelada') {
      return (
        <div style={{display: "flex", justifyContent: "center"}}>
          <OverlayTrigger
            overlay={this.renderTooltip('Cancelar nota fiscal')}
            placement="right"
            key={'Cancelar nota fiscal'}
          >
            <a href="javascript:void(0)" onClick={this.cancelNfse(id)}>
              <i className="fa fa-2x fa-ban"/>
            </a>
          </OverlayTrigger>
        </div>
      );
    }

    return null;
  };

  render() {
    return this.props.serviceInvoices && (
      <BootstrapTable
        data={this.props.serviceInvoices}
      >
        <TableHeaderColumn dataField="rps_number" dataSort>
          Número RPS
        </TableHeaderColumn>

        <TableHeaderColumn dataField="number" dataSort>
          Número Nota
        </TableHeaderColumn>

        <TableHeaderColumn dataField="status" dataFormat={this.renderInvoiceStatusIcon} dataSort>
          Status da Nota Fiscal
        </TableHeaderColumn>

        <TableHeaderColumn isKey dataField="id" dataFormat={this.renderCancelInvoiceIcon}>
          Ações
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }
}

export default ServiceInvoicesTable;
