import React from 'react';
import ReactDOM from 'react-dom';

import { onCheckOverlap } from 'utils';
import TableCell from './TableCell';

class UnassignedOrders extends React.Component {
  render() {
    const {
      equipments,
      service_orders,
      timeslots,
      unassigned_orders,
      onMoveCell,
      tableClass,
      soTableClass,
    } = this.props;

    const Header = () => {
      const unassigned_order_amount = unassigned_orders.length;

      return (
        <thead>
          <tr>
            <th style={{ display: "table", width: "100%" }}>
              <span style={{ display: "table-cell", verticalAlign: "middle" }}>
                Ordens de serviço pendentes {unassigned_order_amount ? `(${unassigned_order_amount})` : ""}
              </span>
            </th>
          </tr>
        </thead>
      )
    };

    const Body = () => {
      let orders_stack = [...unassigned_orders];

      return (
        <tbody style={{ width: "100%", overflowY: "auto" }} >
          {equipments.map((equipment, e_index) => {
            let unassigned_order = orders_stack.find(uo => uo.suggested_eq_id === equipment.id);
            if(!unassigned_order){
              unassigned_order = orders_stack.find(uo => !uo.suggested_eq_id && uo.compatible_equipments.some(uo_eq_code => uo_eq_code === equipment.code));

              if(!unassigned_order){
                unassigned_order = orders_stack.find(uo => !uo.suggested_eq_id);
              }
            }
            const uo_index = orders_stack.indexOf(unassigned_order);

            if(unassigned_order){
              orders_stack.splice(uo_index, 1);
            }

            return (
              <tr key={e_index}>
                <TableCell
                  time={'unassigned_so'}
                  equipment={equipment}
                  service_order={unassigned_order}
                  onMoveCell={cells_props => onMoveCell(cells_props)}
                  onCheckOverlap={props => onCheckOverlap(timeslots, service_orders, props)}
                />
              </tr>
            )
          })}
          {orders_stack.map((uo, uo_index) => {
            return (
              <tr key={uo_index}>
                <TableCell
                  time={'unassigned_so'}
                  service_order={uo}
                  onMoveCell={cells_props => onMoveCell(cells_props)}
                  onCheckOverlap={props => onCheckOverlap(timeslots, service_orders, props)}
                />
              </tr>
            )
          })}
        </tbody>
      )
    };


    return (
      <table
        className={tableClass}
        style={{ tableLayout: "fixed", width: "15%" }}
        onMouseEnter={() => {
          const domNode = ReactDOM.findDOMNode(this);
          const $tbody = $(domNode).find("tbody");
          $tbody.css({
            height: "100%",
            maxHeight: window.innerHeight * 0.5,
          });
          clearTimeout(this.timeout);
        }}
        onMouseLeave={() => {
          const domNode = ReactDOM.findDOMNode(this);
          const $tbody = $(domNode).find("tbody");

          this.timeout = setTimeout(
            () => $tbody.css({
              height: $(`.${soTableClass} > tbody`).height(),
            }),
            1000
          );
        }}
      >
        <Header />
        <Body />
      </table>
    );
  }
}

export default UnassignedOrders;
