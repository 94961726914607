import React, { Component } from "react";
import {
  Grid,
  FormControl,
  Col,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import axios from "axios";
import fileSaver from "file-saver";
import { dropdownPlaceholder } from '../../commons/strings';

const { Header, Title, Body, Footer } = Modal;

const FormRow = ({ label, marginTop, children }) => (
  <FormGroup style={{ marginTop: marginTop || 0 }}>
    <Grid fluid>
      <Col componentClass={ControlLabel} sm={4}>
        {label}
      </Col>
      <Col sm={8}>
        {children}
      </Col>
    </Grid>
  </FormGroup>
);

class CreatePaymentModal extends Component {

  state = {
    serviceOrder: null,
    company: null,
    bankAccount: null,
    paymentMethod: null,
    sentSuccessful: false,
    parcelAmount: "",
    dueDate: "",
    totalValue: "",
    comments: "",
    payments: [],
  }

  componentDidMount() {
    axios.get("/load_options", {
      params: { bank_accounts: true }
    })
      .then(({ data: { options } }) => this.setState({ bank_accounts: options }))
      .catch(error => toastr.error(error, "Erro"));
  }

  handleSelect = key => value => this.setState({ [key]: value })

  handleChange = key => ({ target: { value } }) => this.setState({ [key]: value })

  clearParcels = () => this.setState({ payments: [] })

  generateParcels = () => {
    const {
      parcelAmount,
      dueDate,
      totalValue,
    } = this.state;
    const payments = (new Array(parseInt(parcelAmount))).fill(0).map(
      (_, index) => ({
        created_at: moment().toISOString(),
        due_date: moment(dueDate).add(index, "months").toISOString(),
        parcel: index + 1,
        value: totalValue / parcelAmount,
      })
    );

    this.setState({ payments });
  }

  getAccountsOptions = () => {
    const { company } = this.state;
    if (!company) return [];

    return this.state.bank_accounts.filter(({ company_id }) => company_id === company.id);
  }

  priceFormatter = price => price ? `R$ ${parseFloat(price).toFixed(2).toLocaleString("pt-BR")}` : ""

  dateFormatter = date => moment(date).format("DD/MM/YYYY")

  downloadPayments = () => {
    const {
      parcelAmount,
      paymentMethod,
      payments,
      serviceOrder,
    } = this.state;

    if (paymentMethod.payment_type !== "bank_slip") return ;
    const paymentIds = payments.map(({ id }) => id);

    axios({
      url: "/download_payments",
      method: "POST",
      responseType: "blob",
      data: { paymentIds }
    })
    .then((response) => {
      const blob = new Blob(
        [response.data],
        { type: "application/octet-stream" }
      );
      const todayDate = moment().format("DD_MM_YYYY");
      const filename = `OS ${serviceOrder.code} - ${parcelAmount} parcelas - ${todayDate}.zip`;

      fileSaver.saveAs(blob, filename);
    })
  }

  sendPayments = () => {
    axios.post("/payments", this.state)
    .then(({ data: { payments, errors } }) => {
      if (errors.length) {
        toastr.error(`Os seguintes erros ocorreram: ${errors.join(", ")}`);
      } else {
        this.setState(
          { payments, sentSuccessful: true },
          this.downloadPayments
        );
      }
    })
    .catch(error => toastr.error(error, "Erro"));
  }

  handleCloseModal = () => this.props.handleClose(this.state.sentSuccessful)

  loadOptions = (paramName, compareAttr) => (inputValue, callback) => {
    if (this.state[paramName]) {
      callback([{}, ...this.props.filterOptions(this.state[paramName], inputValue, compareAttr)]);
    }

    axios.get("/load_options", {
      params: { [paramName]: true }
    })
      .then(({ data: { options } }) => {
        this.setState({ [paramName]: options });

        callback([{}, ...this.props.filterOptions(options, inputValue, compareAttr)]);
      })
      .catch(error => toastr.error(error, "Erro"));
  }

  render() {
    return (
      <Modal
        show
        onHide={this.handleCloseModal}
        className="horizontal-centered"
        dialogClassName="payments-modal"
      >
        <Header
          style={{ fontSize: 45 }}
          closeButton
        >
          <Title>Parcela avulsa</Title>
        </Header>

        <Body>
          <Grid fluid className="no-padding">
            <Col xs={6} style={{ borderRight: "1px solid #e5e5e5", padding: 0 }}>
              <FormRow label="OS:">
                <AsyncSelect
                  placeholder={dropdownPlaceholder}
                  defaultOptions
                  loadOptions={this.loadOptions("service_order_codes", "code")}
                  value={this.state.serviceOrder}
                  onChange={this.handleSelect("serviceOrder")}
                  getOptionLabel={c => c.code || dropdownPlaceholder}
                  getOptionValue={c => c.id}
                />
              </FormRow>

              <FormRow label="Empresa favorecida:">
                <AsyncSelect
                  placeholder={dropdownPlaceholder}
                  defaultOptions
                  loadOptions={this.loadOptions("companies", "company_name")}
                  value={this.state.company}
                  onChange={this.handleSelect("company")}
                  getOptionLabel={c => c.company_name || dropdownPlaceholder}
                  getOptionValue={c => c.id}
                />
              </FormRow>

              <FormRow label="Conta empresa favorecida:">
                <Select
                  placeholder={dropdownPlaceholder}
                  value={this.state.bankAccount}
                  options={this.getAccountsOptions()}
                  onChange={this.handleSelect("bankAccount")}
                  getOptionLabel={c => c.identification || dropdownPlaceholder}
                  getOptionValue={c => c.id}
                />
              </FormRow>

              <FormRow label="Forma de pagamento:" marginTop={40}>
                <AsyncSelect
                  placeholder={dropdownPlaceholder}
                  defaultOptions
                  loadOptions={this.loadOptions("payment_methods", "name")}
                  value={this.state.paymentMethod}
                  onChange={this.handleSelect("paymentMethod")}
                  getOptionLabel={pm => pm.name || dropdownPlaceholder}
                  getOptionValue={pm => pm.id}
                />
              </FormRow>

              <FormRow label="Qtd de parcelas:">
                <FormControl
                  value={this.state.parcelAmount}
                  onChange={this.handleChange("parcelAmount")}
                  type="text"
                  placeholder="Qtd de parcelas"
                />
              </FormRow>

              <FormRow label="Dt do 1º vencimento:">
                <FormControl
                  value={this.state.dueDate}
                  onChange={this.handleChange("dueDate")}
                  type="date"
                  placeholder="Dt do 1º vencimento"
                />
              </FormRow>

              <FormRow label="Valor total:">
                <FormControl
                  value={this.state.totalValue}
                  onChange={this.handleChange("totalValue")}
                  type="number"
                  placeholder="Valor total"
                />
              </FormRow>

              <FormGroup>
                <Grid fluid>
                  <Col componentClass={ControlLabel} sm={12}>
                    Observações:
                  </Col>
                </Grid>

                <Grid fluid style={{ marginTop: 15 }}>
                  <Col sm={12}>
                    <FormControl
                      componentClass="textarea"
                      placeholder="Observações"
                      onChange={this.handleChange("comments")}
                      style={{ height: 120 }}
                    />
                  </Col>
                </Grid>
              </FormGroup>

              <Grid fluid>
                <Col xs={6} xsOffset={6}>
                  <Button bsStyle="primary" onClick={this.generateParcels} style={{ float: "right" }}>
                    Gerar parcelas
                  </Button>
                </Col>
              </Grid>
            </Col>

            <Col xs={6}>
              <Grid fluid>
                <h2 style={{ textAlign: "center", marginBottom: 30 }}>
                  Parcelas geradas
                </h2>

                <BootstrapTable
                  data={this.state.payments}
                >
                  <TableHeaderColumn isKey dataField="bank_slip_number" dataSort>
                    N. Boleto
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="created_at" dataSort dataFormat={this.dateFormatter}>
                    Dt. Emissão
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="due_date" dataSort dataFormat={this.dateFormatter}>
                    Dt. Vencimento
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="parcel" dataSort>
                    Parcela
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="value" dataSort dataFormat={this.priceFormatter}>
                    Valor
                  </TableHeaderColumn>
                </BootstrapTable>

                {this.state.sentSuccessful
                  ? <h2 className="text-center">
                      {this.state.parcelAmount} Parcelas criadas com sucesso
                    </h2>
                  :
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
                      <Button bsStyle="danger" onClick={this.clearParcels} style={{ marginRight: 30 }}>
                        Cancelar
                      </Button>
                      <Button bsStyle="primary" onClick={this.sendPayments}>
                        Emitir documentos
                      </Button>
                    </div>
                }
              </Grid>
            </Col>
          </Grid>
        </Body>

        <Footer>
          <Button onClick={this.handleCloseModal}>Close</Button>
        </Footer>
      </Modal>
    );
  }
}

export default CreatePaymentModal;
