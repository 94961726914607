import React from "react";
import {
  Row,
  Col,
  FormControl,
  FormGroup,
  ControlLabel,
} from 'react-bootstrap';
import moment from "moment";

import IboxTitle from "../../../commons/IboxTitle";
import DarkIbox from "../../../commons/DarkIbox";
import OSStatus from "./OSStatus";

class ServiceOrderStatus extends React.Component {
  state = {
    startDate: moment(new Date()).format("DD/MM/YYYY"),
    endDate:  moment(new Date()).format("DD/MM/YYYY"),
  }

  componentWillMount() {
    this.initDatePickers();
  }

  componentWillReceiveProps() {
    this.initDatePickers();
  }

  initDatePickers = () => {
    setTimeout(() => {
      $('#start-datepicker')
        .datetimepicker({
          format: 'DD/MM/YYYY',
          locale: 'pt-br',
        })
        .on('dp.change', ({ date }) => {
          this.onChangeDate('startDate')(date.format("DD/MM/YYYY"));
        });

      $('#end-datepicker')
        .datetimepicker({
          format: 'DD/MM/YYYY',
          locale: 'pt-br',
        })
        .on('dp.change', ({ date }) => {
          this.onChangeDate('endDate')(date.format("DD/MM/YYYY"));
        });
    }, 400);
  }

  onChangeDate = (key) => (value) => {
    const {
      startDate,
      endDate,
    } = this.state;
    const { fetchCounters } = this.props;

    this.setState({ [key]: value }, fetchCounters(startDate, endDate));
  }

  render() {
    const {
      serviceOrdersCount: {
        recent,
        total,
        accepted,
        rejected,
        approved,
        refused,
        finished,
        canceled,
      },
    } = this.props;
    const {
      startDate,
      endDate,
    } = this.state;

    return (
      <DarkIbox>
        <IboxTitle>
          Situação das Ordens de Serviço
        </IboxTitle>
        <Row>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Início do período</ControlLabel>
              <FormControl
                id="start-datepicker"
                defaultValue={startDate}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Final do período</ControlLabel>
              <FormControl
                id="end-datepicker"
                defaultValue={endDate}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <OSStatus type="recent" count={recent} />
          </Col>
          <Col md={6}>
            <OSStatus type="total" count={total} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <OSStatus type="accepted" count={accepted} />
          </Col>
          <Col md={6}>
            <OSStatus type="rejected" count={rejected} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <OSStatus type="approved" count={approved} />
          </Col>
          <Col md={6}>
            <OSStatus type="refused" count={refused} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <OSStatus type="finished" count={finished} />
          </Col>
          <Col md={6}>
            <OSStatus type="canceled" count={canceled} />
          </Col>
        </Row>
      </DarkIbox>
    )
  }
}

export default ServiceOrderStatus;
