import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { createDragPreview } from 'react-dnd-text-dragpreview';
import Popover from "react-popover";

export const cell_colors = {
  started: '#c1d3c4',
  recent: 'rgba(250, 99, 99, 0.4)',
  approved: 'rgba(214, 212, 47, 0.45)',
  accepted: '#E4DFEB',
  isDragging: '#91CF66',
  cantDrop: '',
  isOver: '#FFFD59',
  OverUnassigned: '#F3F2F5'
};

const equipment_colors = {
  normal: {
    light: 'rgba(224, 241, 213, 0.45)',
    dark: '#BCE0A3'
  },
  warning: {
    light: 'rgba(252, 251, 145, 0.45)',
    dark: 'rgba(214, 212, 47, 0.45)'
  },
  danger: {
    light: 'rgba(250, 162, 162, 0.15)',
    dark: '#FAA2A2'
  }
};

const dragPreviewStyle = {
  backgroundColor: cell_colors.accepted,
  borderColor: '#000',
  fontSize: 15,
  paddingTop: 4,
  paddingRight: 7,
  paddingBottom: 6,
  paddingLeft: 7
};

const cellSource = {
  beginDrag(props) {
    const { service_order } = props;

    return { service_order };
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const { service_order } = props;

    const { time, equipment } = monitor.getDropResult();

    props.onMoveCell({ service_order, time, equipment });
  },
  canDrag(props){
    const { service_order, time } = props;

    const isUnassignedOrder = time === 'unassigned_so';

    const isBlocked = (service_order && service_order.is_confirmed) || (service_order && service_order.has_started);

    return service_order && (isUnassignedOrder || !isBlocked);
  }
};

const cellTarget = {
  drop(props) {
    const { time, equipment } = props;

    return { time, equipment };
  },
  canDrop(props, monitor) {
    const so_source = monitor.getItem().service_order;
    const { time, equipment } = props;

    // Can drop on unassigned order list or if equipment is compatible and SO does not overlap with other SO or Time Slot
    if(time === 'unassigned_so'){
      return true;
    }

    const wouldOverlap = props.onCheckOverlap({ so_source, time, equipment });
    const isEquipCompatible = so_source.compatible_equipments.some(eq_code => eq_code === equipment.code);

    return isEquipCompatible && !wouldOverlap;
  }
};

function collectDragSource(connect, monitor) {
  const movingCell = monitor.getItem() ? monitor.getItem().service_order : null;

  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
    movingCell,
  };
}

function collectDropTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}


class TableCell extends Component {
  handleConfirmButton = (service_order) => () => {
    const { confirmServiceOrder, saveChanges } = this.props;

    Swal.fire({
      title: 'Você quer confirmar esta OS?',
      text: 'Ao confirmar uma programação, ela será disponibilizada no aplicativo de controle. Esta alteração não pode ser desfeita.',
      type: 'warning',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(({ value: confirmed }) => {
      if (confirmed) {
        window.show_loader();

        saveChanges();

        $.ajax({
          headers: {
            'X-Transaction': 'POST Example',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
          },
          url: "/daily_schedule/confirm",
          type: 'POST',
          data: {"data": JSON.stringify({ id: service_order.service_order_id })},
          dataType: 'json',
          success: ({ message }) => {
            confirmServiceOrder && confirmServiceOrder(service_order.code);

            if (message) {
              toastr.success(message);
            }
          },
          error: ({ message }) => {
            if (message) {
              toastr.error(message);
            } else {
              toastr.error("Problema na conexão com o servidor. Tente novamente mais tarde");
            }
          },
          complete: () => {
            window.hide_loader();
          },
        });
      }
    });
  }

  renderServiceOrder = (service_order, isUnassignedOrder) => (
    <div className="daily-service-order">
      <div>
        <span>
          {`OS ${service_order.code}`}
        </span>
        <span>
          {service_order.scheduled_start_time}
        </span>
      </div>
      <div>
        <span>
          {service_order.identification}
        </span>
      </div>
      <div>
        <span>
          {`${service_order.quantity ? service_order.quantity : 0} ${service_order.unit}`}
        </span>
        {
          isUnassignedOrder && (
            <span>
              {`${parseInt(service_order.duration / 60)}h${service_order.duration % 60}min `}
            </span>
          )
        }
      </div>
    </div>
  );

  render() {
    const { children, isEven, equipment, so_cells_left, service_order, time,
      isOver, canDrop, movingCell, connectDragSource, connectDropTarget, connectDragPreview, openPopover } = this.props;

    const isUnassignedOrder = time === 'unassigned_so';

    const className = service_order
      ? isUnassignedOrder
        ? "so-unassigned"
        : service_order.start_time === time ? "so-header" : "so-body"
      : "isEmpty";

    const colSpan = className === "so-header" ? 1 + parseInt(so_cells_left) : undefined;

    const tdDisplay = className === "so-body"
      ? "none"
      : (className === "so-unassigned" || isUnassignedOrder)
        ? "block"
        : "table-cell";

    const shipmentClass = (service_order && service_order.has_started) ? "cursor-not-allowed" : "";

    const backgroundColor = isOver && canDrop
      ? equipment_colors[equipment.status].dark
      : (movingCell && !service_order && !isUnassignedOrder)
        ? movingCell.compatible_equipments.some(eq_code => eq_code === equipment.code)
          ? equipment_colors[equipment.status].light
          : cell_colors.cantDrop
        : isUnassignedOrder && isOver
          ? cell_colors.OverUnassigned
          : service_order
            ? service_order.has_started
              ? cell_colors.started
              : cell_colors[service_order.status]
            : '';

    if(service_order){
      // handles first time dragPreview setup
      this.dragPreview = createDragPreview(
        `OS ${service_order.code} - ${service_order.identification} | ${service_order.scheduled_start_time} - ${service_order.quantity ? service_order.quantity : 0} m³`,
        dragPreviewStyle
      );
      connectDragPreview(this.dragPreview);
    }

    const border_color = (service_order && service_order.is_confirmed) ? '#1EA131' : '#C0C0C0';
    const so_border = `2px solid ${border_color}`;

    return connectDragSource(connectDropTarget(
      <td
        colSpan={colSpan}
        className={`${className} ${shipmentClass}`}
        style={{
          display: tdDisplay,
          backgroundColor,
          opacity: movingCell && service_order && movingCell.id === service_order.id ? 0.5 : 1,
          borderTop: service_order ? so_border : '',
          borderBottom: service_order ? so_border : '',
          borderLeft: className === 'so-header'
            ? so_border
            : 'none',
          borderRight: service_order
            ? so_border
            : isEven
              ? 'none'
              : ''
        }}
      >
        {service_order && (service_order.start_time === time || isUnassignedOrder)
          ? (
            <Popover
              body={(
                <div className="daily-popover">
                  {this.renderServiceOrder(service_order, isUnassignedOrder)}

                  <div className="mt-10">
                    {
                      service_order.is_confirmed
                      ? (
                        <span className="label label-primary">
                          OS confirmada
                        </span>
                      )
                      : (
                        <button
                          className="btn btn-sm btn-primary btn-outline"
                          onClick={this.handleConfirmButton(service_order)}
                        >
                          Confirmar OS
                        </button>
                      )
                    }
                  </div>
                </div>
              )}
              isOpen={this.props.openedCode === service_order.id}
            >
              <div
                onClick={openPopover && openPopover(service_order.id)}
                style={{
                  display: movingCell && movingCell.id === service_order.id ? 'none' : 'initial',
                  overflow: "hidden",
                }}
              >
                {this.renderServiceOrder(service_order, isUnassignedOrder)}
              </div>
            </Popover>
          )
          : children
        }
      </td>
    ));
  }
}

export default flow(
  DragSource('TableCell', cellSource, collectDragSource),
  DropTarget('TableCell', cellTarget, collectDropTarget)
)(TableCell);
