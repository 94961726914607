const getPaymentMethod = ({ paymentMethods }, id) => paymentMethods.find(
  (paymentMethod) => paymentMethod.id === id
);

const getBankAccount = ({ bankAccounts }, id) => bankAccounts.find(
  (bankAccount) => bankAccount.id === id
);

export const getCompanyAccounts = ({ bankAccounts, company }) => bankAccounts.filter(
  (account) => account.company_id === company.id
);

export const buildPaymentState = (props) => {
  const accounts = getCompanyAccounts(props);

  return {
    paymentMethod: accounts.length ? props.paymentInfo.paymentMethod : {},
    bankAccount: {},
    numberOfInstallments: Number.isInteger(props.paymentInfo.parcels) ? props.paymentInfo.parcels : 1,
    firstDueDate: props.paymentInfo.firstDueDate,
    payments: [],
    generateParcels: false,
    instruction: '',
    additionalInstruction: '',
    interest: 0,
    accounts,
    applyDiscount: false,
    discountTime: 0,
    discountValue: 0,
    chargeFine: false,
    finePercent: 0,
    maxOverdueDays: 0,
  };
};

export const loadPaymentState = (props, billingItem, company) => {
  if (!Object.keys(billingItem).length) {
    return undefined;
  }

  if (!billingItem.payments.length) {
    return undefined;
  }

  const payment = billingItem.payments[0] || {};
  const accounts = getCompanyAccounts({ ...props, company });

  return {
    paymentMethod: getPaymentMethod(props, payment.payment_method_id),
    accounts,
    numberOfInstallments: billingItem.payments.length,
    bankAccount: getBankAccount(props, payment.bank_account_id),
    payments: billingItem.payments.map((payment) => ({
      ...payment,
      installmentNumber: payment.parcel,
      value: parseFloat(payment.value).toFixed(2),
      due_date: moment(payment.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      max_overdue_date: !!payment.max_overdue_date ? moment(payment.max_overdue_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
      additionalInstruction: payment.additional_instruction,
    })),
    generateParcels: true,
    instruction: payment.instruction || "",
    additionalInstruction: payment.additional_instruction || "",
    interest: parseFloat(payment.monthly_interest) || 0,
    applyDiscount: !!payment.apply_discount,
    discountTime: parseInt(payment.discount_time) || 0,
    discountValue: parseFloat(payment.discount_value) || 0,
    chargeFine: !!payment.charge_fine,
    finePercent: parseFloat(payment.fine_percentual) || 0,
    maxOverdueDays: parseInt(payment.max_overdue_days) || 0,
  };
};
