import React from "react";

import ScannedInvoices from "./invoices/ScannedInvoices";
import ProductInvoices from "./invoices/ProductInvoices";

class ServiceOrderInvoices extends React.Component {

  render() {
    const {
      pdf_link,
      scanned_invoices,
      product_invoices,
      team: serviceOrderTeam,
    } = this.props.serviceOrder || {};

    const {
      data,
      onAddOperator,
      onRemoveOperator,
      userAdmin,
      permissions,
      isFinished,
    } = this.props;

    const team = (
      data
      && data.operators_service_orders_attributes
      && Object.values(data.operators_service_orders_attributes)
    ) || serviceOrderTeam;

    return (
      <div>
        <ScannedInvoices
          invoices={scanned_invoices}
          team={team}
          onAddOperator={onAddOperator}
          onRemoveOperator={onRemoveOperator}
          userAdmin={userAdmin}
          permissions={permissions}
          isFinished={isFinished}
          pdfLink={pdf_link}
        />

        <ProductInvoices
          invoices={product_invoices}
        />
      </div>
    )
  }
}

export default ServiceOrderInvoices;
