import React from 'react';
import { Panel, FormControl, Button, Col, Row, Grid, FormGroup, ControlLabel } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import axios from "axios";
import { dropdownPlaceholder } from '../../../commons/strings';

import debounce from "../../../utils/debounce.js";
import CreatePaymentModal from "../CreatePaymentModal";

class FilterFinancial extends React.Component {

  state = {
    modalOpen: false,
  }

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen })

  closeModal = shouldReload => {
    if (shouldReload === true) {
      window.location = "/financial";
    }

    this.toggleModal();
  }

  filterOptions = (options, inputValue, compareAttr) => {
    if (!inputValue) return options;

    return options.filter(
      ({ [compareAttr]: value }) => `${value}`.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  loadOptions = (paramName, compareAttr) => debounce((inputValue, callback) => {
    if (this.state[paramName]) {
      callback([
        {},
        ...this.filterOptions(this.state[paramName], inputValue, compareAttr)
      ]);
    }

    const loadingKey = `${paramName}Loading`;

    !this.state[loadingKey] && axios.get("/load_options", {
      params: { [paramName]: true }
    })
      .then(({ data: { options } }) => {
        this.setState({
          [paramName]: options,
          [loadingKey]: false,
        });

        callback([
          {},
          ...this.filterOptions(options, inputValue, compareAttr)
        ]);
      })
      .catch(error => toastr.error(error, "Erro"));

    this.setState({ [loadingKey]: true });
  }, 500)

  render() {
    return (
      <Panel>
        <Panel.Body>
          <Grid fluid={true}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Button bsStyle='primary' onClick={this.toggleModal}>
                    Emitir cobrança avulso
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Contrato</ControlLabel>
                  <AsyncSelect
                    placeholder={dropdownPlaceholder}
                    defaultOptions
                    loadOptions={this.loadOptions("contracts", "identification")}
                    value={this.props.selectedContract}
                    onChange={this.props.handleSelectContract}
                    getOptionLabel={c => c.identification || dropdownPlaceholder}
                    getOptionValue={c => c.id}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Situação</ControlLabel>
                  <Select
                    placeholder={dropdownPlaceholder}
                    value={this.props.selectedStatus}
                    onChange={this.props.handleSelectStatus}
                    options={[
                      { label: dropdownPlaceholder },
                      ...this.props.paymentStatuses.map(s => ({ label: s, value: s }))
                    ]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <ControlLabel>Data inicial</ControlLabel>
                      <FormControl
                        type="date"
                        placeholder="Dt Inicial"
                        value={this.props.selectedInitialDate}
                        onChange={this.props.handleInitialDateChange}
                      />
                    </Col>
                    <Col md={6}>
                      <ControlLabel>Data final</ControlLabel>
                      <FormControl
                        type="date"
                        placeholder="Dt Final"
                        value={this.props.selectedFinalDate}
                        onChange={this.props.handleFinalDateChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Empresa</ControlLabel>
                  <AsyncSelect
                    placeholder={dropdownPlaceholder}
                    defaultOptions
                    loadOptions={this.loadOptions("companies", "company_name")}
                    value={this.props.selectedEmpresa}
                    onChange={this.props.handleSelectCompany}
                    getOptionLabel={c => c.company_name || dropdownPlaceholder}
                    getOptionValue={c => c.company_name}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>OS</ControlLabel>
                  <AsyncSelect
                    placeholder={dropdownPlaceholder}
                    defaultOptions
                    loadOptions={this.loadOptions("service_order_codes", "code")}
                    value={this.props.selectedOS}
                    onChange={this.props.handleSelectOS}
                    getOptionLabel={so => so.code ? `OS ${so.code}` : dropdownPlaceholder}
                    getOptionValue={so => so.id}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>BM</ControlLabel>
                  <AsyncSelect
                    placeholder={dropdownPlaceholder}
                    defaultOptions
                    loadOptions={this.loadOptions("payment_report_codes", "code")}
                    value={this.props.selectedBM}
                    onChange={this.props.handleSelectBM}
                    getOptionLabel={pr => pr.code ? `BM ${pr.code}` : dropdownPlaceholder}
                    getOptionValue={pr => pr.id}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>N° da fatura</ControlLabel>
                  <AsyncSelect
                    placeholder={dropdownPlaceholder}
                    defaultOptions
                    loadOptions={this.loadOptions("billing_items", "billing_number")}
                    value={this.props.selectedBillingNumber}
                    onChange={this.props.handleSelectBillingNumber}
                    getOptionLabel={bi => bi.billing_number || dropdownPlaceholder}
                    getOptionValue={bi => bi.billing_number}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel.Body>

        {this.state.modalOpen &&
          <CreatePaymentModal
            {...this.props}
            filterOptions={this.filterOptions}
            handleClose={this.closeModal}
          />
        }
      </Panel>
    );
  }
}
export default FilterFinancial;
