import React from "react";

class InputFile extends React.Component {
  constructor (props) {
    super(props);

    const {
      file,
    } = props;
    const {
      name: filename,
    } = file || {};

    this.state = {
      filename,
    };
  }

  onUpload = (event) => {
    const { onChange } = this.props;
    const files = Array.from(event.target.files);

    if (files.length === 0) {
      return;
    }

    const parsedFiles = files.map((file) => {
      const parsedFile = {
        name: file.name,
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ({
        target: {
          result,
        },
      }) => {
        parsedFile.file = result;
      };

      return parsedFile;
    });

    const { multiple } = this.props;

    const suffix = parsedFiles.length > 1 ? "s" : "";
    const result = !!multiple ? parsedFiles : parsedFiles[0];
    const filename = !!multiple ? `${parsedFiles.length} arquivo${suffix} selecionado${suffix}` : result.name;

    onChange(result);
    this.setState({ filename });
  }

  onClick = () => {
    this.ref.click();
  }

  saveRef = ref => {
    this.ref = ref;
  }

  render () {
    const {
      accept,
      multiple,
    } = this.props;
    const { filename } = this.state;
    const buttonText = filename ? "Alterar arquivo" : "Adicionar arquivo";

    return (
      <div className="file-input-wrapper">
          {filename && (
            <span
              className="filename"
            >
              {filename}
            </span>
          )}
          <span
            className="fake-button"
            onClick={this.onClick}
          >
            {buttonText}
          </span>
          <input
            multiple={!!multiple}
            accept={accept || "image/*"}
            ref={this.saveRef}
            type="file"
            onChange={this.onUpload}
          />
      </div>
    );
  }
}

export default InputFile;
