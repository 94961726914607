import React from 'react';
import {
  Col,
  Row,
  Grid,
  FormGroup,
  ControlLabel,
  Button,
  Modal
} from 'react-bootstrap';
import _ from 'lodash';
import moment from "moment";

import InstallmentForm from './InstallmentForm';
import CurrencyInput from '../inputs/CurrencyInput';
import { currencyHumanized } from "../../utils/helpers";

class InstallmentModal extends React.Component {
  constructor(props) {
    super(props);

    const { installment } = props;

    this.state = this.parse(installment);
  }

  componentWillReceiveProps(props){
    const { installment } = props;

    this.setState(this.parse(installment));
  }

  momentToDate = (date) => date ? moment(date, 'DD/MM/YYYY').toDate() : undefined;

  parse = (installment) => {
    const {
      paid_at,
      bank_account,
      company,
      payment_method,
      discount,
      fees,
      status,
      due_date,
    } = installment || {};

    return {
      paid_at: this.momentToDate(paid_at) || this.momentToDate(due_date) || new Date(),
      bank_account: bank_account || {},
      company: company || {},
      payment_method: payment_method || {},
      discount: discount || 0,
      fees: fees || 0,
      editMode: false,
      isPaid: status === "paid",
    };
  }

  getInitialState = () => {
    const { installment } = this.props;

    return this.parse(installment);
  }

  handleChange = key => ({ target: { value } }) => this.setState({ [key]: value });

  handleSelect = key => value => this.setState({ [key]: value });

  onClear = () => this.setState(this.getInitialState());

  onEdit = () => this.setState(({ editMode }) => ({ editMode: !editMode }));

  onCancel = () => {
    const {
      onCancel,
    } = this.props;

    this.onClear();
    onCancel();
  }

  onCancelPayment = () => {
    const {
      onSubmit,
    } = this.props;

    Swal.fire({
      title: 'Você quer cancelar este pagamento?',
      text: 'Ele voltará a ser pendente e todas as suas informações serão redefinidas.',
      type: 'warning',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(({ value: confirmed }) => {
      if (confirmed) {
        onSubmit({
          paid_at: null,
          discount: null,
          fees: null,
          bank_account_id: null,
          company_id: null,
          payment_method_id: null,
        }, this.onClear);
      }
    });
  }

  onSubmit = () => {
    const {
      onSubmit,
    } = this.props;
    const {
      paid_at,
      bank_account,
      company,
      payment_method,
      discount,
      fees,
    } = this.state;

    onSubmit({
      paid_at,
      discount,
      fees,
      bank_account_id: bank_account.id,
      company_id: company.id,
      payment_method_id: payment_method.id,
    }, this.onClear);
  };

  render() {
    const {
      show,
      bankAccounts,
      paymentMethods,
      companies,
      installment
    } = this.props;
    const {
      discount,
      fees,
      paid_at,
      bank_account,
      company,
      payment_method,
      editMode,
      isPaid,
    } = this.state;

    const {
      nf_number,
      number,
      value,
    } = installment || {};

    const liquidValue = parseFloat(value) + parseFloat(fees) - parseFloat(discount);
    const title = isPaid ? "Detalhes do pagamento" : "Confirmar dados do pagamento";
    const editButtonText = editMode ? "Cancelar edição" : "Editar";
    const showDatePicker = !isPaid || editMode;
    const disableInput = isPaid && !editMode;

    return (
      <Modal
        className="horizontal-centered"
        show={show}
        onHide={this.onCancel}
        bsSize="large"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Nota fiscal</ControlLabel>
                  <p>{nf_number}</p>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Parcela</ControlLabel>
                  <p>{number}</p>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Valor da parcela</ControlLabel>
                  <p>{currencyHumanized(value)}</p>
                </FormGroup>
              </Col>
            </Row>

            <InstallmentForm
              showDatePicker={showDatePicker}
              disableInput={disableInput}
              company={company}
              companies={companies}
              bank_account={bank_account}
              bankAccounts={bankAccounts}
              payment_method={payment_method}
              paymentMethods={paymentMethods}
              paid_at={paid_at}
              handleSelect={this.handleSelect}
            />

            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Descontos/Acréscimos</ControlLabel>
                  <CurrencyInput
                    disabled={disableInput}
                    value={discount}
                    onChange={this.handleChange("discount")}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Taxas</ControlLabel>
                  <CurrencyInput
                    disabled={disableInput}
                    value={fees}
                    onChange={this.handleChange("fees")}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Valor líquido</ControlLabel>
                  <h3 className="text-green">{currencyHumanized(liquidValue)}</h3>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-between">
            <div>
              {
                isPaid && (
                  <div>
                    <Button bsStyle="warning" onClick={this.onEdit} className="btn-outline">{editButtonText}</Button>
                    <Button bsStyle="danger" onClick={this.onCancelPayment} className="btn-outline">Cancelar pagamento</Button>
                  </div>
                )
              }
            </div>
            <div>
              <Button bsStyle="default" onClick={this.onCancel} className="btn-outline">Cancelar</Button>
              <Button bsStyle="primary" onClick={this.onSubmit} className="btn-outline">Confirmar</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };
}

export default InstallmentModal;
