import React from 'react';

const styles = {
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "red",
    marginTop: 15,
    padding: 15,
    borderRadius: 10,
    backgroundColor: "#2F4050"

  },
  smallCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "red",
    marginTop: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#2F4050"
  }
}

const borderType = type => {
  const allowedTypes = ["success", "danger", "default"];

  if (allowedTypes.includes(type)) {
    return type;
  }

  return "default";
}

const InfoCard = ({
  border,
  small,
  ...props
}) => (
  <div style={small ? styles.smallCard : styles.card} className={`bordered-${borderType(border)}`}>
     {props.children}
  </div>
);

export default InfoCard;
