import React from 'react';

const backgroundColor = '#713599';

export default ({ children, ts_cells_left }) => {
  return (
    <td
      className={"timeslot"}
      colSpan={ts_cells_left === 1 ? 2 : 1}
      style={{ backgroundColor }}
    >
      {children}
    </td>
  )
}