import React from 'react';
import {
  FormControl,
  Col,
  Row,
  Grid,
  FormGroup,
  ControlLabel,
  Button,
  Modal
} from 'react-bootstrap';
import Select from 'react-select';
import { Checkbox } from 'react-icheck';
import update from 'immutability-helper';

import InstallmentForm from "./InstallmentForm";
import GenerateInstallmentsTable from './GenerateInstallmentsTable';
import { objectlize, sum, generateRandomId, coerce, currencyHumanized } from '../../utils/helpers';
import { installmentStatus, MAX_NUMBER_OF_INSTALLMENTS } from '../../utils/consts';
import InputFile from '../inputs/InputFile';
import CurrencyInput from '../inputs/CurrencyInput';
import PDFIcon from '../../images/pdf_icon.png';

const INITIAL_STATE = {
  description: "",
  total_value: 0,
  discount: 0,
  fees: 0,
  number_of_installments: 0,
  department: {},
  account_plan: {},
  provider: {},
  selected_photos: null,
  selected_attachments: null,
  competence_date: moment().format('YYYY-MM-DD'),
  expiration_date: moment().format('YYYY-MM-DD'),
  first_due_date: moment().format('YYYY-MM-DD'),
  cash_payment: true,
  xml: "",
  nf_number: "",
  nf_access_key: "",
  installments: {},
  photos: {},
  attachments: {},
  center: {},
  paid: false,
  paid_at: new Date(),
  bank_account: {},
  company: {},
  payment_method: {},
};

class ExpenseModal extends React.Component {
  constructor(props) {
    super(props);

    const { expense } = props;

    if (!!expense) {
      this.state = this.set(expense);
    } else {
      this.state = INITIAL_STATE;
    }
  }

  componentWillReceiveProps(props){
    const { expense } = props;

    if (expense) {
      this.setState({
        ...this.set(expense),
      });
    }
  }

  set = (expense) => {
    const {
      description,
      nf_number,
      nf_access_key,
      total_value,
      discount,
      fees,
      department,
      provider,
      competence_date,
      expiration_date,
      first_due_date,
      installments,
      photos,
      cash_payment,
      number_of_installments,
      account_plan,
      xml,
      attachments,
    } = expense;

    return {
      description: description || "",
      nf_number: nf_number || "",
      nf_access_key: nf_access_key || "",
      total_value: total_value || 0,
      discount: discount || 0,
      fees: fees || 0,
      department: department || {},
      provider: provider || {},
      account_plan: account_plan || {},
      competence_date,
      expiration_date,
      first_due_date,
      cash_payment: cash_payment,
      number_of_installments,
      xml,
      installments: objectlize(
        installments || [],
        ({ id }) => id,
        ({ status }) => {
          return { touched: status === installmentStatus.PAID, _destroy: false }
        }
      ),
      photos: objectlize(
        photos || [],
        ({ id }) => id,
        () => ({ _destroy: false }),
      ),
      attachments: objectlize(
        attachments || [],
        ({ id }) => id,
        () => ({ _destroy: false }),
      ),
    };
  }

  hasPaidInstallment = () => {
    const { installments } = this.state;

    return !!Object.values(installments).filter((installment) => installment.status === installmentStatus.PAID).length;
  }

  destroy = (type, id) => () => {
    this.setState(
      update(this.state, {
        [type]: {
          [id]: {
            $merge: {
              _destroy: !this.state[type][id]._destroy,
            }
          },
        }}
      )
    );
  }

  updateInstallment = (id, key, value) => {
    this.setState(
      update(this.state, {
        installments: {
          [id]: {
            $merge: {
              [key]: value,
              touched: true,
            }
          },
        }}
      ),
      () => key === '_destroy' ? this.reindex(value) : this.recalculate(),
    );
  }

  buildInstallment = (
    number,
    due_date,
    value,
  ) => ({
    id: null,
    number,
    due_date,
    value,
    _destroy: false,
    touched: false,
    generated_id: generateRandomId(),
  });

  generate = (
    number_of_installments,
    first_due_date,
    total_value,
    initial_index = 0,
  ) => {
    let counter = initial_index;

    return Array.apply(
      null,
      { length: number_of_installments },
    ).map((_, index) => {
      counter++;
      const dividedValue = (total_value / number_of_installments).toFixed(2);
      const lastInstallment = counter === parseInt(number_of_installments);
      const remainingValue = (total_value - (dividedValue * (number_of_installments - 1))).toFixed(2);
      const value = lastInstallment ? remainingValue : dividedValue;

      return this.buildInstallment(
        counter,
        moment(first_due_date).add(initial_index ? index + 1 : index, 'M').format('DD/MM/YYYY'),
        value,
      );
    });
  }

  recalculate = () => {
    const {
      installments,
      number_of_installments,
      first_due_date,
    } = this.state;

    const dirties = Object.values(installments).filter(({ touched, id }) => (touched || !!id));

    if (dirties.length === 0) {
      this.setState({
        installments: objectlize(
          this.generate(number_of_installments, first_due_date, this.getLiquidValue()),
          ({ id, generated_id }) => id || generated_id,
        ),
      });
    } else {
      const hasPaidInstallment = this.hasPaidInstallment();

      const installmentsList = Object.values(installments).map((installment, index) => ({
        ...installment,
        due_date: hasPaidInstallment ? installment.due_date : moment(first_due_date).add(index, 'M').format('DD/MM/YYYY'),
       }));

      const availableInstallments = installmentsList.filter(({ _destroy }) => !_destroy);
      const destroyingInstallments = installmentsList.filter(({ _destroy }) => _destroy);

      const newAmount = availableInstallments.length;

      const lastInstallment = availableInstallments[availableInstallments.length - 1];
      const initialDate = moment(lastInstallment.due_date, 'DD/MM/YYYY').format('YYYY-MM-DD');

      const newInstallments = number_of_installments <= newAmount
        ? [
          ...availableInstallments.slice(0, number_of_installments),
          ...destroyingInstallments,
        ]
        : [
          ...installmentsList,
          ...this.generate(
            number_of_installments - newAmount,
            initialDate,
            0,
            parseInt(lastInstallment.number),
          )
        ];

      this.updateInstallments(newInstallments);
    }
  }

  reindex = (value) => {
    const {
      installments,
      number_of_installments,
    } = this.state;

    this.setState(
      {
        number_of_installments: value ? number_of_installments - 1 : number_of_installments + 1,
      },
      this.updateInstallments(Object.values(installments)),
    );
  }

  updateInstallments = (installments) => {
    const toucheds = installments.filter(({ touched, _destroy }) => (touched && !_destroy));
    const touchedSum = sum(toucheds, ({ value }) => value);

    const installmentsLeft = installments.filter(({ touched, status }) => !(touched || status === installmentStatus.PAID)).length;

    const diff = coerce(this.getLiquidValue()).minus(touchedSum);
    const amountLeft = diff >= 0 ? diff : 0;

    let counter = 0;

    this.setState({
      installments: installments.reduce(
        (acc, current) => {
          const id = current.id || current.generated_id;
          if (!current._destroy) {
            counter++;
          }

          const dividedValue = coerce(amountLeft).dividedBy(installmentsLeft).toFixed(2);
          const lastInstallment = counter === parseInt(installments.length);
          const remainingValue = (amountLeft - (dividedValue * (installmentsLeft - 1))).toFixed(2);
          const value = lastInstallment ? remainingValue : dividedValue;

          return {
            ...acc,
            [id]: {
              ...current,
              value: current.touched ? current.value : value,
              number: current._destroy ? current.number : counter,
            },
          }
        },
        {}
      )
    });
  }

  cleanAll = () => {
    const { installments } = this.state;

    this.setState({
      installments: Object.values(installments).reduce(
        (acc, current) => {
          const id = current.id || current.generated_id;

          return {
            ...acc,
            [id]: {
              ...current,
              touched: current.status === installmentStatus.PAID,
            },
          }
        },
        {}
      )
    }, this.recalculate)
  }

  updateState = (key, value) => {
    const {
      installments,
      cash_payment,
    } = this.state;

    let extra = {};

    if (key === 'number_of_installments') {
      if (value > MAX_NUMBER_OF_INSTALLMENTS) {
        toastr.warning("Não é possível informar um número de parcelas maior que 99", "Atenção");
        return;
      }

      if (value < Object.values(installments).filter(({ id, _destroy }) => (!!id && !_destroy)).length) {
        toastr.warning("Não é possível informar um número de parcelas menor do que o já existente.", "Atenção");
        return;
      }
    }

    if (key === 'expiration_date' && cash_payment) {
      extra = {
        ...extra,
        first_due_date: value,
        paid_at: moment(value).toDate()
      };
    }

    if (key === 'department') {
      extra = {
        ...extra,
        center: {},
      }
    }

    const shouldRecalculate = ['number_of_installments', 'first_due_date', 'total_value', 'discount', 'fees'].includes(key);

    this.setState({
      [key]: value,
      ...extra,
    }, () => {
      if (shouldRecalculate) {
        this.recalculate();
      }
    });
  }

  handleChange = key => ({ target: { value } }) => this.updateState(key, value);

  handleChangeDate = key => ({ target: { value } }) => this.updateState(key, value);

  handleSelect = key => value => this.updateState(key, value);

  toggle = (key) => () => {
    this.setState({
      [key]: !this.state[key],
    });
  }

  toggleCashPayment = () => {
    const {
      installments,
      cash_payment,
      expiration_date
    } = this.state;

    if (Object.values(installments).filter(({ status }) => status === installmentStatus.PAID).length > 0) {
      toastr.warning("Já existem pagamentos pagos para esta despesa", "Não é possível alterar para pagamento à vista");
      return;
    }

    let extra = {
      installments: {},
      paid: false,
      company: {},
      bank_account: {},
      payment_method: {},
      paid_at: moment(expiration_date).toDate(),
    };

    if (Object.values(installments).filter(({ id }) => !!id).length > 0) {
      extra = {
        ...extra,
        installments: Object.values(installments).reduce(
          (acc, current) => {
            const id = current.id || current.generated_id;

            return {
              ...acc,
              [id]: {
                ...current,
                _destroy: !cash_payment,
                touched: status === installmentStatus.PAID,
              },
            }
          },
          {}
        )
      }
    }

    this.setState({
      ...extra,
      cash_payment: !cash_payment,
    });
  }

  getLiquidValue = () => {
    const {
      total_value,
      discount,
      fees,
    } = this.state;

    return coerce(total_value).plus(coerce(fees)).minus(coerce(discount));
  }

  onClear = () => this.setState(INITIAL_STATE);

  onCancel = () => {
    const {
      onCancel,
    } = this.props;

    this.onClear();
    onCancel();
  }

  onSubmit = () => {
    const {
      onSubmit,
    } = this.props;
    const {
      provider,
      department,
      number_of_installments,
      photos,
      attachments,
      first_due_date,
      expiration_date,
      total_value,
      installments,
      cash_payment,
      account_plan,
      selected_photos,
      selected_attachments,
      center,
      paid,
      paid_at,
      company,
      bank_account,
      payment_method,
      ...formValues,
    } = this.state;

    const availableInstallments = Object.values(installments).filter(({ _destroy }) => !_destroy);

    if (!cash_payment && availableInstallments.some(({ value }) => value <= 0)) {
      toastr.warning("Não é possível cadastrar uma despesa com parcelas de valor menor ou igual que R$ 0,00", "Atenção");
      return;
    }

    const diff = parseFloat(sum(availableInstallments, ({ value }) => value)) - parseFloat(this.getLiquidValue());
    if (!cash_payment && parseFloat(diff) !== 0.0) {
      toastr.warning("A soma das parcelas deve ser igual ao valor total da nota.", "Atenção");
      return;
    }

    const requiredFields = [company, bank_account, payment_method];
    const fieldValues = requiredFields.filter(({ id }) => !!id);

    if (cash_payment && paid && fieldValues.length < requiredFields.length) {
      toastr.warning("Para definir uma despesa como paga é necessário informar a empresa, conta bancária e forma de pagamento.", "Atenção");
      return;
    }

    onSubmit({
      ...formValues,
      cash_payment,
      total_value,
      provider_id: provider.id,
      department_id: department.id,
      account_plan_id: account_plan.id,
      installments_attributes: (cash_payment && paid)
        ? {
          0: {
            number: 1,
            due_date: expiration_date,
            paid_at,
            value: parseFloat(this.getLiquidValue()),
            company: company.id,
            bank_account_id: bank_account.id,
            payment_method_id: payment_method.id,
          }
        }
        : installments,
      photos_attributes: photos,
      selected_photos,
      selected_attachments,
      attachments_attributes: attachments,
      direct_cost_attributes: {
        center_id: center.id,
      },
    }, this.onClear);
  };

  render() {
    const {
      show,
      departments,
      centers,
      providers,
      account_plans,
      expense,
      bankAccounts,
      paymentMethods,
      companies,
    } = this.props;
    const {
      department,
      nf_number,
      account_plan,
      competence_date,
      expiration_date,
      description,
      total_value,
      provider,
      discount,
      fees,
      number_of_installments,
      first_due_date,
      cash_payment,
      installments,
      xml,
      attachments,
      photos,
      center,
      paid,
      paid_at,
      company,
      bank_account,
      payment_method,
    } = this.state;

    const filteredCenters = department.id
      ? centers.filter(({ department_id }) => department.id === department_id)
      : [];

    const liquidValue = currencyHumanized(this.getLiquidValue());
    const hasPaidInstallment = this.hasPaidInstallment();

    const availableInstallments = Object.values(installments).filter(({ _destroy }) => !_destroy);
    const installmentsSum = currencyHumanized(sum(availableInstallments, ({ value }) => value));

    return (
      <Modal
        show={show}
        onHide={this.onCancel}
        bsSize="large"
        backdrop="static"
        className="horizontal-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Adicionar despesa
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Descrição</ControlLabel>
                  <FormControl
                    value={description}
                    onChange={this.handleChange("description")}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Nota fiscal</ControlLabel>
                  <FormControl
                    disabled={!!xml}
                    value={nf_number}
                    onChange={this.handleChange("nf_number")}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Fornecedor</ControlLabel>
                  <Select
                    isDisabled={!!xml}
                    value={provider}
                    placeholder="Selecione..."
                    onChange={this.handleSelect("provider")}
                    options={providers}
                    getOptionLabel={({ name, cpf_cnpj }) => name && `${name} (${cpf_cnpj})`}
                    getOptionValue={({ id }) => id}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Plano de contas</ControlLabel>
                  <Select
                    value={account_plan}
                    placeholder="Selecione..."
                    onChange={this.handleSelect("account_plan")}
                    options={account_plans}
                    getOptionLabel={({ code, name }) => name && `${code}: ${name}`}
                    getOptionValue={(account_plan) => account_plan.id}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Departamento</ControlLabel>
                  <Select
                    value={department}
                    placeholder="Selecione..."
                    onChange={this.handleSelect("department")}
                    options={departments}
                    getOptionLabel={(department) => department.name}
                    getOptionValue={(department) => department.id}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <ControlLabel>Centro de custo</ControlLabel>
                <Select
                  isDisabled={filteredCenters.length === 0}
                  value={center}
                  placeholder="Selecione..."
                  onChange={this.handleSelect("center")}
                  options={filteredCenters}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Data de competência</ControlLabel>
                  <FormControl
                    type="date"
                    value={competence_date}
                    onChange={this.handleChangeDate("competence_date")}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Data de vencimento</ControlLabel>
                  <FormControl
                    type="date"
                    value={expiration_date}
                    onChange={this.handleChangeDate("expiration_date")}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Primeiro vencimento</ControlLabel>
                  <FormControl
                    type="date"
                    disabled={cash_payment || hasPaidInstallment}
                    value={first_due_date}
                    onChange={this.handleChangeDate("first_due_date")}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Valor total</ControlLabel>
                  <CurrencyInput
                    disabled={!!xml}
                    value={total_value}
                    onChange={this.handleChange("total_value")}
                    className="mb-10"
                  />

                  <Checkbox
                    checked={cash_payment}
                    checkboxClass="icheckbox_square-green"
                    increaseArea="20%"
                    label="&emsp;Pagamento à vista"
                    onClick={this.toggleCashPayment}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Descontos/Acréscimos</ControlLabel>
                  <CurrencyInput
                    value={discount}
                    onChange={this.handleChange("discount")}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Taxas</ControlLabel>
                  <CurrencyInput
                    value={fees}
                    onChange={this.handleChange("fees")}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <FormGroup>
                  <ControlLabel>Valor líquido</ControlLabel>
                  <h3 className="text-green">{liquidValue}</h3>
                </FormGroup>
              </Col>

              {
                !cash_payment && (
                  <Col md={3}>
                    <FormGroup>
                      <ControlLabel>Soma das parcelas</ControlLabel>
                      <h3 className="text-green">{installmentsSum}</h3>
                    </FormGroup>
                  </Col>
                )
              }

              <Col md={6} className={cash_payment ? "col-md-offset-3" : ""}>
                <FormGroup>
                  <ControlLabel>Nº de parcelas</ControlLabel>
                  <FormControl
                    disabled={cash_payment}
                    type="number"
                    value={number_of_installments}
                    onChange={this.handleChange("number_of_installments")}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <hr />

                <div className="mb-20">
                  <Checkbox
                    disabled={!cash_payment}
                    checked={paid}
                    checkboxClass="icheckbox_square-green"
                    increaseArea="20%"
                    label="&emsp;Pago"
                    onClick={this.toggle("paid")}
                  />
                </div>

                {
                  paid && (
                    <InstallmentForm
                      company={company}
                      companies={companies}
                      bank_account={bank_account}
                      bankAccounts={bankAccounts}
                      payment_method={payment_method}
                      paymentMethods={paymentMethods}
                      paid_at={paid_at}
                      handleSelect={this.handleSelect}
                    />
                  )
                }

                <hr />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Anexos PDF</ControlLabel>
                  <InputFile
                    onChange={this.handleSelect("selected_attachments")}
                    accept="application/pdf"
                    multiple
                  />
                  {
                    attachments && (
                      <div className="photos">
                        {
                          Object.values(attachments).map((attachment) => (
                            <div
                              key={attachment.id}
                              className={`photo-wrapper ${attachment._destroy ? "destroyed" : ""}`}
                            >
                              <i className="fa fa-times" onClick={this.destroy('attachments', attachment.id)}></i>
                              <div className="photo">
                                <img src={PDFIcon} height="50"></img>
                                <a
                                  alt="download"
                                  href={attachment.url}
                                  className="mt-5"
                                >
                                  <p className="attachment-name">{attachment.name}</p>
                                </a>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Fotos anexadas</ControlLabel>
                  <InputFile
                    onChange={this.handleSelect("selected_photos")}
                    multiple
                  />
                  {
                    photos && (
                      <div className="photos">
                        {
                          Object.values(photos).map((photo) => (
                            <div className={`photo-wrapper ${photo._destroy ? "destroyed" : ""}`} key={photo.id}>
                              <i className="fa fa-times" onClick={this.destroy('photos', photo.id)}></i>
                              <img className="photo" src={photo.url}/>
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                {!cash_payment && (
                  <GenerateInstallmentsTable
                    installments={installments}
                    updateInstallment={this.updateInstallment}
                    cleanAll={this.cleanAll}
                    showCleanAll={!expense}
                  />
                )}
              </Col>
            </Row>
          </Grid>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="default" onClick={this.onCancel} className="btn-outline">Cancelar</Button>
          <Button bsStyle="primary" onClick={this.onSubmit} className="btn-outline">Confirmar</Button>
        </Modal.Footer>
      </Modal>
    );
  };
}

export default ExpenseModal;
