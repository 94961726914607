import React, { Component } from 'react';
import Ibox from '../../../commons/Ibox';

class ClientContractInfo extends Component {

  render = () => {
    const {
      creating,
      title,
      contract,
      currentUser,
      serviceOrder
    } = this.props;
    const programmer = (creating && currentUser.name) || serviceOrder.programmer || "-";

    return (
      <Ibox title={title}>
        <h4 className="text-navy info-section m-b-md">Informações do contrato</h4>

        <div className="row">
          <div className="col-lg-3 info-group">
            <strong className="info-title">
              Código do contrato
            </strong>
            <span>
              {contract.code}
            </span>
          </div>
          <div className="col-lg-3 info-group">
            <strong className="info-title">
              Identificação do contrato
            </strong>
            <span>
              {contract.identification}
            </span>
          </div>
          <div className="col-lg-3 info-group">
            <strong className="info-title">
              Programador
            </strong>
            <span>
              {programmer}
            </span>
          </div>
          <div className="col-lg-3 info-group">
            <strong className="info-title">
              Vendedor
            </strong>
            <span>
              {contract.seller}
            </span>
          </div>
        </div>
      </Ibox>
    );
  }
}

export default ClientContractInfo;
