import React from "react";
import { Modal } from 'react-bootstrap';

import ServiceOrderInvoices from '../shared/ServiceOrderInvoices/ServiceOrderInvoices';

class ServiceOrderDetails extends React.Component {

  render() {
    const {
      show,
      onHide,
      serviceOrder,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        bsSize="large"
        backdrop="static"
        className="horizontal-centered"
        dialogClassName="dark os-details-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Detalhes das notas fiscais
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ServiceOrderInvoices
            serviceOrder={serviceOrder}
          />
        </Modal.Body>
      </Modal>
    )
  }
}

export default ServiceOrderDetails;
