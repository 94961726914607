import React from 'react';

const styles = {
  titlebar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  button: {
    width: 250
  }
}

const Ibox = ({
  buttonValue,
  buttonAction,
  withStatus,
  isOK,
  failMessage,
  tableOnly,
  ...props
}) => {
  return (
    <div className="ibox">
      <div className="ibox-title" style={styles.titlebar}>
        <h3>
          {props.title}
          {
            withStatus && (
              isOK && (
                <span className="m-l-sm">
                  <i className="fa fa-check-circle-o text-navy"></i>
                </span>
              ) ||
              <span className="m-l-sm">
                <i className="fa fa-times-circle-o text-danger"></i>
                <span className="m-l-sm badge badge-danger">{failMessage}</span>
              </span>
            )
          }

        </h3>
        {buttonValue && buttonAction && <div style={styles.button}>
          <button className="btn btn-outline btn-success btn-block" onClick={buttonAction}>
            {buttonValue}
          </button>
        </div>}
      </div>
      <div className="ibox-content" style={tableOnly ? { padding: 0 } : {}}>
        {props.children}
      </div>
    </div>
  );
}

export default Ibox;
