import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Panel } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const { Body } = Panel;

import PaymentsTable from "./PaymentsTable";
import ServiceInvoicesTable from "./ServiceInvoicesTable";

class BillingItemsTable extends React.Component {

  paymentStatusClassMapper = {
    "Pago": "payment-paid",
    "Atrasado": "payment-overdue",
    "Pendente": "payment-pending",
    "Não criado": "payment-none",
  }

  state = {
    loadingItemId: null,
  }

  renderTooltip = tooltip => <Tooltip id={tooltip}>{tooltip}</Tooltip>

  rowClassNameFormat = row => this.paymentStatusClassMapper[row.paymentStatus]

  isExpandableRow = ({ payments, serviceInvoices }) => payments.length >= 1 || serviceInvoices.length >= 1;

  expandComponent = ({ payments, serviceInvoices }) => (
    <div>
      <Body>
        <h4>Notas fiscais emitidas</h4>
        {serviceInvoices && <ServiceInvoicesTable serviceInvoices={serviceInvoices} />}
        <h4 style={{ marginTop: 20 }}>Pagamentos gerados</h4>
        {payments && <PaymentsTable payments={payments} />}
      </Body>
    </div>
  );

  expandColumnComponent = ({ isExpandableRow, isExpanded }) => isExpandableRow && (
    <div>
      {isExpanded ? "(-)" : "(+)"}
    </div>
  );

  renderRentalBillDownloadIcon = (hasRentalBill, { id }) => (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      {hasRentalBill && (
        <OverlayTrigger
          overlay={this.renderTooltip("Baixar fatura de locação")}
          placement="right"
        >
          <a href={`/download_invoice/${id}`}>
            <i className="fa fa-2x fa-file-pdf-o" />
          </a>
        </OverlayTrigger>
      )}
    </div>
  );

  render() {
    return this.props.billingItems && (
      <BootstrapTable
        data={this.props.billingItems}
        options={{ expandRowBgColor: "white" }}
        expandableRow={this.isExpandableRow}
        expandColumnOptions={{
          expandColumnVisible: true,
          expandColumnComponent: this.expandColumnComponent,
          columnWidth: 50,
        }}
        expandComponent={this.expandComponent}
        trClassName={this.rowClassNameFormat}
        expandColumnOptions={{ expandColumnVisible: true }}
      >
        <TableHeaderColumn dataField="billingNumber" dataSort>
          N° Fatura
        </TableHeaderColumn>

        <TableHeaderColumn isKey dataField="companyName" dataSort>
          Empresa
        </TableHeaderColumn>

        <TableHeaderColumn dataField="nickname" dataSort>
          Apelido
        </TableHeaderColumn>

        <TableHeaderColumn dataField="hasRentalBill" dataFormat={this.renderRentalBillDownloadIcon}>
          Fatura de locação
        </TableHeaderColumn>

      </BootstrapTable>
    );
  }
}

export default BillingItemsTable;
