import React from "react";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
} from "react-bootstrap";

import ServiceOrderInvoices from "../../ServiceOrderInvoices/ServiceOrderInvoices";
import DarkIbox from "../../../../commons/DarkIbox";

class Travels extends React.Component {

  render() {
    const {
      os,
      onChangeData,
      onSave,
      data,
      onAddOperator,
      onRemoveOperator,
      userAdmin,
      permissions,
      isFinished,
    } = this.props;
    const {
      notes,
    } = data;

    return (
      <div>
        <Row>
          <Col>
            <ServiceOrderInvoices
              serviceOrder={os}
              data={data}
              onAddOperator={onAddOperator}
              onRemoveOperator={onRemoveOperator}
              userAdmin={userAdmin}
              permissions={permissions}
              isFinished={isFinished}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <DarkIbox>
              <FormGroup>
                <ControlLabel>Notas de finalização</ControlLabel>
                <FormControl
                  disabled={isFinished}
                  value={notes}
                  onChange={({ target: { value } }) => onChangeData('notes')(value)}
                  componentClass="textarea"
                />
              </FormGroup>

              <FormGroup>
                <div className="d-flex justify-content-center align-items-center pt-10">
                  {
                    isFinished
                      ? (
                        <span>Esta ordem de serviço já foi finalizada</span>
                      )
                      : (
                        <Button
                          onClick={onSave}
                          bsClass="btn btn-primary btn-lg"
                        >
                          Finalizar ordem de serviço
                        </Button>
                      )
                  }
                </div>
              </FormGroup>
            </DarkIbox>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Travels;
