import React from 'react';

const cell_colors = {
  equipmentHeader: '#F5F5F6',
  businessHours: '#B8CDE2',
  nonBusinessHours: '#FED078',
};

const TableHeader = ({step, start, end}) => {
  const DisplayedRow = () => {
    const numCells = end - start;

    return (
      <tr>
        {['equipments_column',...new Array(numCells).keys()].map((time, index) => {
          const cellColor = (time < 7 || time > 17) ? cell_colors.nonBusinessHours : cell_colors.businessHours;

          if (index === 0) {
            return <th key={index} style={{ backgroundColor: cell_colors.equipmentHeader, width: 100 }}>Cód. Equipamento</th>
          }
          else {
            if(time === 24) time = 0;
            return <th key={index} colSpan={2} style={{ backgroundColor: cellColor }}>{time.toString().length === 2 ? time : '0' + time}:00</th>
          }
        })}
      </tr>
    );
  };

  const HiddenRow = () => {
    const numCells = (end - start)*(60/step) + 1;

    return (
      <tr style={{ display: 'none' }}>
        {[...new Array(numCells)].map((x, index) => <th key={index} /> )}
      </tr>
    );
  };

  return (
    <thead>
      <DisplayedRow />
      <HiddenRow />
    </thead>
  );
}

export default TableHeader;
