import React, { Component } from "react";
import {
  Grid,
  FormControl,
  Modal,
  Button,
  ControlLabel,
  Row,
  Col,
  FormGroup,
} from "react-bootstrap";
import { Checkbox, Radio, RadioGroup } from 'react-icheck';
import axios from "axios";
import AsyncSelect from 'react-select/lib/Async';
import debounce from "../../utils/debounce.js";
import { dropdownPlaceholder } from '../../commons/strings';
import Select from "react-select";

const { Header, Body, Footer } = Modal;

const PROVIDER = "provider";
const TAKER = "taker";
const CUSTOM = "custom";

const BLANK_STATE = { id: '', text: '' };

const states = [
  { text: 'Acre', id: 'AC' },
  { text: 'Alagoas', id: 'AL' },
  { text: 'Amapá', id: 'AP' },
  { text: 'Amazonas', id: 'AM' },
  { text: 'Bahia', id: 'BA' },
  { text: 'Ceará', id: 'CE' },
  { text: 'Distrito Federal', id: 'DF' },
  { text: 'Espírito Santo', id: 'ES' },
  { text: 'Goiás', id: 'GO' },
  { text: 'Maranhão', id: 'MA' },
  { text: 'Mato Grosso', id: 'MT' },
  { text: 'Mato Grosso do Sul', id: 'MS' },
  { text: 'Minas Gerais', id: 'MG' },
  { text: 'Pará', id: 'PA' },
  { text: 'Paraíba', id: 'PB' },
  { text: 'Paraná', id: 'PR' },
  { text: 'Pernambuco', id: 'PE' },
  { text: 'Piauí', id: 'PI' },
  { text: 'Rio de Janeiro', id: 'RJ' },
  { text: 'Rio Grande do Norte', id: 'RN' },
  { text: 'Rio Grande do Sul', id: 'RS' },
  { text: 'Rondônia', id: 'RO' },
  { text: 'Roraima', id: 'RR' },
  { text: 'Santa Catarina', id: 'SC' },
  { text: 'São Paulo', id: 'SP' },
  { text: 'Sergipe', id: 'SE' },
  { text: 'Tocantins', id: 'TO' }
];

class ServiceInvoiceModal extends Component {

  state = {
    lastServiceInvoice: null,
    nsfeComments: '',
    withheldTaxes: false,
    customServiceLocation: false,
    ufServiceLocation: BLANK_STATE,
    cityServiceLocation: '',
    serviceCode: '',
    serviceDescription: '',
    reason: '',
    status: '',
  }

  componentWillReceiveProps({ billingItem, companyId }) {
    this.setState({
      lastServiceInvoice: billingItem.lastServiceInvoice,
      nsfeComments: billingItem.nsfeComments,
      withheldTaxes: billingItem.withheldTaxes || false,
      customServiceLocation: billingItem.customServiceLocation || false,
      serviceLocationFromContract: billingItem.serviceLocationFromContract || false,
      ufServiceLocation: this.findState(billingItem.ufServiceLocation) || BLANK_STATE,
      cityServiceLocation: billingItem.cityServiceLocation,
      serviceAddressLocation: billingItem.serviceAddressLocation,
      serviceCepLocation: billingItem.serviceCepLocation,
      serviceNeighborhoodLocation: billingItem.serviceNeighborhoodLocation,
      serviceNumberLocation: billingItem.serviceNumberLocation,
      serviceCode: billingItem.serviceCode,
      serviceDescription: billingItem.serviceDescription,
      status: billingItem.status,
      reason: billingItem.reason
    });
  }

  findState = uf => {
    if (typeof uf === "string") {
      return states.find(state => state.id == uf);
    }

    return uf;
  }

  loadOptions = debounce((inputValue, callback) => {
    this.loadMunicipalServices(inputValue, callback);
  }, 500);

  loadMunicipalServices = (inputValue, callback) => {
    axios.get(`/load_municipal_services?company_id=${this.props.companyId}&q=${inputValue}`)
      .then(response => {
        callback(response.data);
      })
      .catch(response => {
        toastr.warning('Erro ao carregar informações', 'Nenhum resultado encontrado');
      });
  };

  handleSelectChange = ({ text, value }) => {
    this.setState({
      serviceCode: value,
      serviceDescription: text
    });
  }

  handleChange = ({ target: { value: nsfeComments } }) => this.setState({ nsfeComments })

  handleCheckboxChange = () => this.setState(
    ({ withheldTaxes }) => ({ withheldTaxes: !withheldTaxes })
  )

  handleServiceLocationChange = ({ target }) => {
    const value = target && target.value;

    if (value === CUSTOM) {
      this.setState({
        customServiceLocation: true,
        serviceLocationFromContract: false,
      });
      return;
    }

    if (value === TAKER) {
      this.setState({
        customServiceLocation: false,
        serviceLocationFromContract: true,
      });
      return;
    }

    this.setState({
      customServiceLocation: false,
      serviceLocationFromContract: false,
    });
  }

  getServiceLocationType = () => {
    const {
      customServiceLocation,
      serviceLocationFromContract,
    } = this.state;

    if (customServiceLocation) {
      return CUSTOM;
    }

    if (serviceLocationFromContract) {
      return TAKER;
    }

    return PROVIDER;
  }

  handleUfServiceLocationChange = (ufServiceLocation) => this.setState({ ufServiceLocation });

  handleLocationChange = (key) => ({ target: { value } }) => {
    this.setState({ [key]: value })
  };

  isFormValid = () => {
    const { customServiceLocation, cityServiceLocation, ufServiceLocation: { id: selectedUF } } = this.state;

    return !customServiceLocation || !!(cityServiceLocation.length && selectedUF && selectedUF.length);
  }

  onModalClose = () => {
    if (this.isFormValid()) {
      const shouldEmitNfse = !this.state.lastServiceInvoice || ['Negada', 'Cancelada'].includes(this.state.status) || (this.state.status === '' && !this.state.lastServiceInvoice.enotas_id);

      this.props.closeModal({
        ...this.state,
        shouldEmitNfse,
      });
    } else {
      toastr.warning("Verifique se você preencheu todos os campos.");
    }
  };

  renderCard = () => {
    const { lastServiceInvoice, status } = this.state;

    if (lastServiceInvoice === null
      || status === "Cancelada"
      || status === "Negada"
      || (lastServiceInvoice && !status && !lastServiceInvoice.enotas_id)
    ) {
      return this.renderForm();
    }

    if (!status) {
      return (
        <div className="alert alert-info">
          A última nota fiscal emitida para esta fatura, ainda está aguardando retorno da prefeitura. Por favor, aguarde
          antes de emitir uma nova.
        </div>
      );
    }

    return (
      <div className="alert alert-info">
        Já existe uma nota emitida e autorizada para esta fatura. Por favor, efetue o cancelamento da nota, antes de
        tentar emitir uma nova.
      </div>
    );
  };

  getDisplayAddress = (source = {}, sendMetadata = false) => {
    if (sendMetadata) {
      return source.full_address;
    }

    return `${source.city}, ${source.state}`;
  }

  renderForm = () => {
    const {
      customServiceLocation,
      serviceLocationFromContract,
      cityServiceLocation,
      ufServiceLocation,
      serviceAddressLocation,
      serviceCepLocation,
      serviceNeighborhoodLocation,
      serviceNumberLocation,
    } = this.state;

    const {
      selectedCompany,
      contractInfo,
    } = this.props;

    return (
      <div>
        <Grid fluid className="no-padding">
          {
            this.state.reason && this.state.status === 'Negada' && (
              <div className="alert alert-warning">
                <strong>Esta nota foi negada pela prefeitura</strong>
                <br />
                Motivo: {this.state.reason}
              </div>
            )
          }
          {
            this.state.lastServiceInvoice && (
              <div className="alert alert-info">
                <strong>Dados carregados da última nota emitida.</strong><br />
                Por favor, confira os dados antes de confirmar o envio.
              </div>
            )
          }

          <div style={{ marginBottom: 30 }}>
            <ControlLabel>
              Local de prestação do serviço
            </ControlLabel>

            <RadioGroup
              name="radio"
              value={this.getServiceLocationType()}
              onChange={this.handleServiceLocationChange}
            >
              <Radio
                label="Usar endereço do prestador"
                increaseArea="20%"
                radioClass="iradio_square-green mr-10"
                value={PROVIDER}
              />
              <Radio
                label="Usar endereço do tomador"
                increaseArea="20%"
                radioClass="iradio_square-green mr-10 ml-20"
                value={TAKER}
              />
              <Radio
                label="Informar endereço"
                increaseArea="20%"
                radioClass="iradio_square-green mr-10 ml-20"
                value={CUSTOM}
              />
            </RadioGroup>

            {!customServiceLocation
              ? (
                <Grid fluid style={{ marginTop: 10 }}>
                  <Row>
                    <Col md={12}>
                      <div className="address-info">
                        <label>
                          Endereço selecionado:
                        </label>

                        <p>
                          {!customServiceLocation && !serviceLocationFromContract && (
                            this.getDisplayAddress(selectedCompany, selectedCompany.send_metadata)
                          )}

                          {serviceLocationFromContract && (
                            this.getDisplayAddress(contractInfo, selectedCompany.send_metadata)
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              )
              : (
                <div style={{ marginTop: 10 }}>
                  <Grid fluid>
                    {selectedCompany.send_metadata && (
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <ControlLabel>Logradouro</ControlLabel>
                            <FormControl
                              value={serviceAddressLocation}
                              onChange={this.handleLocationChange("serviceAddressLocation")}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={4}>
                          <FormGroup>
                            <ControlLabel>Número</ControlLabel>
                            <FormControl
                              value={serviceNumberLocation}
                              onChange={this.handleLocationChange("serviceNumberLocation")}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={4}>
                          <FormGroup>
                            <ControlLabel>Bairro</ControlLabel>
                            <FormControl
                              value={serviceNeighborhoodLocation}
                              onChange={this.handleLocationChange("serviceNeighborhoodLocation")}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>UF</ControlLabel>
                          <Select
                            placeholder={dropdownPlaceholder}
                            onChange={this.handleUfServiceLocationChange}
                            value={ufServiceLocation}
                            options={states}
                            getOptionLabel={({ text }) => text}
                            getOptionValue={({ id }) => id}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>Cidade</ControlLabel>
                          <FormControl
                            value={cityServiceLocation}
                            onChange={this.handleLocationChange("cityServiceLocation")}
                          />
                        </FormGroup>
                      </Col>

                      {selectedCompany.send_metadata && (
                        <Col md={4}>
                          <FormGroup>
                            <ControlLabel>CEP</ControlLabel>
                            <FormControl
                              value={serviceCepLocation}
                              onChange={this.handleLocationChange("serviceCepLocation")}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </Grid>
                </div>
              )
            }
          </div>

          <div style={{ marginBottom: 30 }}>
            <Checkbox
              checked={this.state.withheldTaxes}
              checkboxClass="icheckbox_square-green"
              increaseArea="20%"
              label="&emsp;ISS retido na fonte?"
              onClick={this.handleCheckboxChange}
            />
          </div>

          <div style={{ marginBottom: '30px !important' }} className="row">
            <div className="col-lg-6">
              <label className="control-label" style={{ marginBottom: 20 }}>
                Descricão do Serviço Municipal
              </label>
              <AsyncSelect
                defaultOptions
                loadOptions={this.loadOptions}
                value={{ text: this.state.serviceDescription }}
                onChange={this.handleSelectChange}
                getOptionLabel={service => service.text}
                getOptionValue={service => service.text}
              />
              <p>
                Deixe em branco para utilizar o código de serviço padrão desta empresa.
              </p>
            </div>
            <div className="col-lg-6">
              <label className="control-label" style={{ marginBottom: 20 }}>
                Código do Serviço Municipal
              </label>
              <h4>
                {this.state.serviceCode}
              </h4>
            </div>
          </div>

          {this.renderTextArea({
            title: "Descrição da Nota Fiscal",
            textAreaProps: {
              value: this.state.nsfeComments,
              placeholder: "Descrição da Nota Fiscal",
              onChange: this.handleChange,
            },
          })}
        </Grid>
      </div>
    );
  }

  renderTextArea = ({ title, textAreaProps }) => (
    <div>
      <ControlLabel style={{ marginBottom: 20 }}>
        {title}
      </ControlLabel>

      <FormControl
        componentClass="textarea"
        style={{ height: 80, marginBottom: 30 }}
        {...textAreaProps}
      />
    </div>
  )

  render() {
    return (
      <Modal
        className="horizontal-centered"
        show={this.props.show}
        onHide={this.onModalClose}
        dialogClassName="default-modal"
      >
        <Header style={{ border: 0 }} closeButton >
          <h2 style={{ textAlign: "center", marginBottom: 30 }}>
            Emitir Nota Fiscal Eletrônica de Serviços
          </h2>
        </Header>
        <Body>
          {
            this.renderCard()
          }
        </Body>
        <Footer>
          <Button onClick={this.onModalClose}>Close</Button>
        </Footer>
      </Modal>
    );
  }
}

export default ServiceInvoiceModal;
