export default [
  'scheduled_concreting_date',
  'scheduled_start_time',
  'scheduled_end_time',
  'part',
  'client_observations',
  'internal_observations',
  'partner_observations',
  'equipment_request_orders_attributes',
  'additional_service_request_orders_attributes',
  'service_order_partners_attributes',
  'notify',
  'status',
  'recurrence',
  'end_date',
  'measure',
  'limit',
  'units',
  'days_of_week',
  'use_date',
  'repeat',
];
