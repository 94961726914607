import React from 'react';
import {
  OverlayTrigger,
  Tooltip,
  ProgressBar,
} from "react-bootstrap";
import OperatorsList from '../../shared/OperatorsList';

class ServiceOrdersTable extends React.Component {
  componentDidMount() {
    this.initializeDataTable();
  }

  componentDidUpdate() {
    if (!$.fn.DataTable.isDataTable('#dashboard-datatable') ) {
      this.initializeDataTable();
    }
  }

  initializeDataTable = () => {
    $('#dashboard-datatable').DataTable({
      "scrollX": true,
      "language": defaultDatatableLanguageOption,
    });
  }

  renderRow = (serviceOrder) => {
    const {
      id,
      code,
      contract,
      team,
      start_time,
      scheduled_start_time,
      arrival_time,
      end_time,
      invoices_total,
      planned_total,
      lat,
      lng,
    } = serviceOrder;
    const planned = parseFloat(planned_total) || 1;
    const progress = (100.0 * parseFloat(invoices_total) / planned).toFixed(0);
    const {
      openServiceOrder,
      addMarker,
      modals,
    } = this.props;
    const position = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    return (
      <tr key={id}>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={(
                <Tooltip id={id}>
                  Visualizar OS
                </Tooltip>
              )}
          >
            <a href={`/service_orders/${id}`}>
              {code}
            </a>
          </OverlayTrigger>

        </td>
        <td>{contract.identification}</td>
        <td>
          <OperatorsList operators={team} />
        </td>
        <td>{scheduled_start_time}</td>
        <td>{arrival_time}</td>
        <td>{start_time}</td>
        <td>{end_time}</td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={(
                <Tooltip id={id}>
                  {`${invoices_total}/${planned_total}`}
                </Tooltip>
              )}
          >
            <ProgressBar now={parseFloat(progress)} striped />
          </OverlayTrigger>
        </td>
        <td>
          <div className="d-flex justify-content-around">
            <OverlayTrigger
              placement="top"
              overlay={(
                  <Tooltip id="Detalhe das notas fiscais">
                    Detalhes das notas fiscais
                  </Tooltip>
                )}
            >
              <a
                onClick={openServiceOrder(serviceOrder, modals.SERVICE_ORDER_DETAILS)}
              >
                <i className="fa fa-info-circle fa-2x"></i>
              </a>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={(
                  <Tooltip id="Finalizar OS">
                    Finalizar OS
                  </Tooltip>
                )}
            >
               <a
                onClick={openServiceOrder(serviceOrder, modals.END_SERVICE_ORDER_MODAL)}
              >
                <i className="fa fa-archive fa-2x"></i>
              </a>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={(
                  <Tooltip id="Exibir no mapa">
                    Exibir no mapa
                  </Tooltip>
                )}
            >
              <a
                onClick={() => addMarker(id, 'crane', contract.identification, position)}
              >
                <i className="fa fa-map-marker fa-2x"></i>
              </a>
            </OverlayTrigger>
          </div>
        </td>
      </tr>
    );
  }

  renderOperator = ({ code, name, photo }) => (
    <OverlayTrigger
      key={code}
      placement="top"
      overlay={(
          <Tooltip id={code}>
            {name}
          </Tooltip>
        )}
    >
      <img
        className="dashboard-operator"
        src={photo}
        alt={name}
      />
    </OverlayTrigger>
  );

  render() {
    const {
      serviceOrders,
    } = this.props;

    return (
      <table className="table table-dark table-striped table-responsive" id="dashboard-datatable">
        <thead>
          <tr>
            <th className="col-md-1">Código</th>
            <th className="col-md-3">Contrato</th>
            <th className="col-md-2">Equipe</th>
            <th className="col-md-1">Programado</th>
            <th className="col-md-1">Chegada</th>
            <th className="col-md-1">Início</th>
            <th className="col-md-1">Finalização</th>
            <th className="col-md-1">Status</th>
            <th className="col-md-1">Ações</th>
          </tr>
        </thead>
        <tbody>
          {serviceOrders.map(this.renderRow)}
        </tbody>
      </table>
    );
  }
}


export default ServiceOrdersTable;
