import React, { Component } from 'react';
import { Row, Col, Table, FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import PropTypes from "prop-types";
import { Checkbox } from 'react-icheck';
import Select from 'react-select';

class BillingItem extends Component {
  constructor(props) {
    super(props);
    const { billingItem } = props;

    this.state = {
      equipmentsRatio: billingItem.rental_ratio || 100,
      issRatio: billingItem.iss_tax || 0,
      inssRatio: billingItem.inss_tax || 0,
      irpjRatio: billingItem.irpj_tax || 0,
      pisRatio: billingItem.pis_tax || 0,
      cofinsRatio: billingItem.cofins_tax || 0,
      csllRatio: billingItem.csll_tax || 0,
      otherRatio: billingItem.other_tax || 0,
      discount: billingItem.discount || 0,
      noTaxes: parseInt(billingItem.rental_ratio || '100') === 100,
      additionalInfo: billingItem.additional_info || "",
      accountPlan: billingItem.accountPlan,
    };
  }

  componentDidMount() {
    this.props.updateBillingItem(this.state, this.props.company.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.totalValue !== prevProps.totalValue
      || this.state.issRatio !== prevState.issRatio
      || this.state.inssRatio !== prevState.inssRatio
      || this.state.irpjRatio !== prevState.irpjRatio
      || this.state.pisRatio !== prevState.pisRatio
      || this.state.cofinsRatio !== prevState.cofinsRatio
      || this.state.csllRatio !== prevState.csllRatio
      || this.state.otherRatio !== prevState.otherRatio
      || this.state.equipmentsRatio !== prevState.equipmentsRatio
      || this.state.additionalInfo !== prevState.additionalInfo
      || this.state.discount !== prevState.discount
      || this.state.accountPlan !== prevState.accountPlan
    ) {
      this.props.updateBillingItem(this.state, this.props.company.id);
    }
  }

  saturateRatio = (ratio) => {
    ratio = ratio < 0 ? 0 : ratio;
    ratio = ratio > 100 ? 100 : ratio;

    return ratio;
  }

  discountedValue = () => {
    return parseFloat(this.props.totalValue) - parseFloat(this.state.discount);
  };

  issPrice = () => {
    return this.discountedValue() * this.state.issRatio / 100.0;
  }

  inssPrice = () => {
    const servicesCost = this.discountedValue() * (100 - this.state.equipmentsRatio) / 100.0;
    return servicesCost * this.state.inssRatio / 100.0;
  }

  irpjPrice = () => {
    return this.discountedValue() * this.state.irpjRatio / 100.0;
  }

  pisPrice = () => {
    return this.discountedValue() * this.state.pisRatio / 100.0;
  };

  cofinsPrice = () => {
    return this.discountedValue() * this.state.cofinsRatio / 100.0;
  };

  csllPrice = () => {
    return this.discountedValue() * this.state.csllRatio / 100.0;
  };

  otherPrice = () => {
    if (parseInt(this.state.equipmentsRatio) === 100) return 0;
    const servicesCost = this.discountedValue() * (100 - this.state.equipmentsRatio) / 100.0;
    return servicesCost * this.state.otherRatio / 100.0;
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  }

  equipmentsRatioChanged = (newRatio) => {
    newRatio = this.saturateRatio(newRatio);
    this.setState({ equipmentsRatio: newRatio });

    if (newRatio == 100) {
      this.setState({
        noTaxes: true,
        issRatio: 0,
        irpjRatio: 0,
        inssRatio: 0,
        pisRatio: 0,
        cofinsRatio: 0,
        csllRatio: 0,
        otherRatio: 0
      });
    } else {
      this.setState({
        noTaxes: false,
        issRatio: 2,
        inssRatio: 11,
        irpjRatio: 1.5,
        pisRatio: 0,
        cofinsRatio: 0,
        csllRatio: 0,
        otherRatio: 0
      });
    }
  }

  additionalInfoChanged = ({ target: { value: additionalInfo } }) => {
    this.setState({ additionalInfo });
  }

  taxRatioChanged = (tax, value) => {
    value = this.saturateRatio(value);
    this.setState({
      [tax]: value
    })
  }

  toggleModal = modalKey => () => {
    const { company, toggleModal } = this.props;
    toggleModal({ modalKey, company });
  }

  toggleReport = () => {
    const { company, toggleModal } = this.props;
    toggleModal({ modalKey: "shouldEmitReport", company });
  }

  priceTable = (rows) => (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>Razão</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
        {rows.map(({ name, ratio, finalCost, onChange, readOnly }) => (
          <tr key={name}>
            <th>{name}</th>

            <td className='form-inline'>
              <FormGroup controlId="ratio" bsSize='small'>
                <InputGroup>
                  <FormControl
                    type='text'
                    style={{ width: 40 }}
                    value={ratio}
                    onChange={(e) => onChange(e.target.value)}
                    readOnly={readOnly}
                  />
                  <InputGroup.Addon>%</InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </td>

            <td>R$ {finalCost.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )

  render() {
    const {
      billingItem,
      company,
      accountPlans,
    } = this.props;
    const totalCost = parseFloat(this.props.totalValue);
    const servicesCost = parseFloat(this.discountedValue()) * (100 - this.state.equipmentsRatio) / 100.0;
    const totalTax = this.issPrice() + this.inssPrice() + this.irpjPrice() + this.csllPrice() + this.pisPrice() + this.cofinsPrice() + this.otherPrice();
    const profit = this.discountedValue() - totalTax;

    const hasBillOrInvoice = billingItem.shouldEmitNfse || billingItem.shouldEmitRentalBill;

    return (
      <div>
        <Row>
          <Col xs={4}>
            <div className="billing-item-p">
              {company.enotas_id && (
                <Checkbox
                  checked={billingItem.shouldEmitNfse}
                  checkboxClass="icheckbox_square-green"
                  increaseArea="20%"
                  label="&emsp;Emitir Nota Fiscal de Serviço"
                  onClick={this.toggleModal('nfseModalOpen')} //serviceInvoice = Nota Fiscal
                />
              )}
            </div>
            <div className="billing-item-p">
              <Checkbox
                checked={billingItem.shouldEmitRentalBill}
                checkboxClass="icheckbox_square-green"
                increaseArea="20%"
                label="&emsp;Emitir Fatura de Locação"
                onClick={this.toggleModal('rentalBillModalOpen')} //emitir Fatura de Locação
              />
            </div>

            <div className="billing-item-p">
              <Checkbox
                checked={billingItem.shouldEmitPayments}
                checkboxClass="icheckbox_square-green"
                increaseArea="20%"
                label="&emsp;Emitir Pagamentos"
                onClick={this.toggleModal('paymentsModalOpen')} // Emitir Boletos
              />

              {
                hasBillOrInvoice && !billingItem.shouldEmitPayments && (
                  <div className="alert alert-warning">
                    <strong>Atenção!</strong>

                    <p>
                      Você está emitindo uma fatura de locação ou nota fiscal de serviço.
                      Não se esqueça de emitir os pagamentos desta cobrança.
                    </p>
                  </div>
                )
              }
            </div>

            <div className="billing-item-p">
              <Checkbox
                checked={billingItem.shouldEmitReport}
                checkboxClass="icheckbox_square-green"
                increaseArea="20%"
                label="&emsp;Anexar relatório da OS"
                onClick={this.toggleReport}
              />
            </div>
          </Col>

          <Col xs={3}>
            <label>Desconto / Acréscimo:</label>
            <FormGroup controlId="discount" bsSize='small'>
              <InputGroup>
                <InputGroup.Addon>R$</InputGroup.Addon>
                <FormControl
                  type='text'
                  style={{ width: 80 }}
                  value={this.state.discount}
                  onChange={({ target: { value: discount } }) => this.setState({ discount })}
                />
              </InputGroup>
            </FormGroup>
          </Col>

          <Col xs={5}>
            <p className="billing-item-p">
              RATEIO: <strong>{this.props.percent ? `${this.props.percent} %` : ""}</strong>
            </p>
            <p className="billing-item-p">
              VALOR BRUTO: <strong>R$ {totalCost.toFixed(2)}</strong>
            </p>
            <p className="billing-item-p">
              VALOR DESCONTADO/ACRESCIDO: <strong>R$ {parseFloat(this.discountedValue()).toFixed(2)}</strong>
            </p>
            <p className="billing-item-p">
              VALOR LÍQUIDO (à faturar): <strong>R$ {profit.toFixed(2)}</strong>
            </p>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col xs={6}>
            {this.priceTable([
              {
                name: 'Locação',
                ratio: this.state.equipmentsRatio,
                finalCost: totalCost - servicesCost,
                onChange: this.equipmentsRatioChanged,
                readOnly: false
              }, {
                name: 'Mão de Obra',
                ratio: 100 - this.state.equipmentsRatio,
                finalCost: servicesCost,
                readOnly: true
              }
            ])}
            <FormGroup controlId="discount" bsSize='small'>
              <label>Observações</label>
              <InputGroup>
                <FormControl
                  value={this.state.additionalInfo}
                  type="text"
                  style={{ width: 400, height: 50 }}
                  onChange={this.additionalInfoChanged}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup controlId="discount" bsSize='small'>
              <label>Plano de contas</label>
              <Select
                value={this.state.accountPlan}
                placeholder="Selecione..."
                onChange={this.handleChange("accountPlan")}
                options={accountPlans}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
              />
            </FormGroup>
          </Col>

          <Col xs={6}>
            {this.priceTable([
              { name: 'ISS', ratio: this.state.issRatio, finalCost: this.issPrice(), onChange: (value) => { this.taxRatioChanged('issRatio', value) }, readOnly: this.state.noTaxes },
              { name: 'INSS', ratio: this.state.inssRatio, finalCost: this.inssPrice(), onChange: (value) => { this.taxRatioChanged('inssRatio', value) }, readOnly: this.state.noTaxes },
              { name: 'IRPJ', ratio: this.state.irpjRatio, finalCost: this.irpjPrice(), onChange: (value) => { this.taxRatioChanged('irpjRatio', value) }, readOnly: this.state.noTaxes },
              { name: 'PIS', ratio: this.state.pisRatio, finalCost: this.pisPrice(), onChange: (value) => { this.taxRatioChanged('pisRatio', value) }, readOnly: this.state.noTaxes },
              { name: 'COFINS', ratio: this.state.cofinsRatio, finalCost: this.cofinsPrice(), onChange: (value) => { this.taxRatioChanged('cofinsRatio', value) }, readOnly: this.state.noTaxes },
              { name: 'CSLL', ratio: this.state.csllRatio, finalCost: this.csllPrice(), onChange: (value) => { this.taxRatioChanged('csllRatio', value) }, readOnly: this.state.noTaxes },
              { name: 'Outros', ratio: this.state.otherRatio, finalCost: this.otherPrice(), onChange: (value) => { this.taxRatioChanged('otherRatio', value) }, readOnly: this.state.noTaxes },])}
          </Col>
        </Row>
      </div>
    );
  }
}

BillingItem.propTypes = {
  totalValue: PropTypes.number.isRequired, // The price for this company, considering the ratio
};

export default BillingItem;
