import React from 'react';

import {
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import InfoCard from "../../../commons/InfoCard";
import StatusIcon from "./StatusIcon";

const EquipmentChecklist = ({
  id,
  code,
  type,
  detail,
  operator,
  status,
  has_checklist,
}) => {
  const borderType = has_checklist ? "success" : "danger";

  return (
    <InfoCard border={borderType} >
      <span className="text-normal text-italic w-25-p">
        <OverlayTrigger
          placement="top"
          overlay={(
              <Tooltip id={id}>
                Visualizar equipamento
              </Tooltip>
            )}
        >
          <a href={`/equipments/${id}`}>
            {code}
          </a>
        </OverlayTrigger>
      </span>
      <span className="text-normal w-25-p">
        {type}
      </span>
      <span className="text-normal w-25-p">
        {detail}
      </span>
      <span className="text-normal w-25-p">
        {operator}
      </span>
      <StatusIcon type={status}/>
    </InfoCard>
  );
}

export default EquipmentChecklist;
