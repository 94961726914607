import React from 'react';
import {
  FormGroup, ControlLabel, FormControl, HelpBlock,
  Col, Button, Modal, Grid, Row,
} from 'react-bootstrap';
import { Checkbox } from 'react-icheck';
import Select from "react-select";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-widgets/dist/css/react-widgets.css';
import Calendar from 'react-calendar'
import moment from "moment";
import { dropdownPlaceholder } from '../../commons/strings';
import { buildPaymentState } from "../../utils/payments.js";
import BankSlipPreview from './BankSlipPreview';

const { Header, Title, Body, Footer } = Modal;

const iconStyle = {
  fontSize: '20px'
};

const boldTextStyle = {
  color: "#18a689",
  fontWeight: "bold",
  textAlign: "center"
}

class PaymentsModal extends React.Component {
  footerData = [[
    {
      label: 'Total',
      columnIndex: 0,
    },
    {
      label: 'Total value',
      columnIndex: 2,
      align: 'left',
      formatter: (tableData) => {
        const value = tableData.reduce(
          (acc, { value }) => acc + parseFloat(value),
          0
        );

        return (
          <span>
            R$ {value.toFixed(2)}
          </span>
        );
      }
    }
  ]]

  constructor(props) {
    super(props);

    this.state = buildPaymentState(props);
  }

  componentDidMount = () => {
    this.changeInstallmentsNumber({ action: 0 });
  }

  componentWillReceiveProps({ payments, ...props }) {
    this.setState(payments || buildPaymentState(props));
  }

  handleSelect = state => value => {
    let newState = { [state]: value };

    if (state === "bankAccount") {
      newState = {
        ...newState,
        instruction: value.default_instruction || '',
        additionalInstruction: value.default_additional_instruction || '',
        interest: value.default_monthly_interest || 0,
        discountTime: value.default_discount_time || 0,
        discountValue: value.default_discount_value || 0,
        finePercent: value.default_fine_percentual || 0,
        chargeFine: value.charge_fine || false,
        applyDiscount: value.apply_discount || false,
        maxOverdueDays: value.default_max_overdue_days || 0,
      };
    }

    this.setState(newState);
  }

  handleDueDateChange = firstDueDate => this.setState({ firstDueDate })

  generateParcels = () => {
    this.setState({ generateParcels: true });
    this.changeInstallmentsNumber({ action: 0 });
  }

  changeInstallmentsNumber = (action) => {
    const numberOfInstallments = parseInt(this.state.numberOfInstallments) + parseInt(action.action)
    const dueDate = moment(this.state.firstDueDate).format('DD/MM/YYYY')
    const installmentPrice = (this.props.price / numberOfInstallments).toFixed(2);
    const payments = Array.apply(null, { length: numberOfInstallments }).map((payment, index) => ({
      ...payment,
      installmentNumber: index + 1,
      value: installmentPrice,
      max_overdue_date: moment(dueDate, 'DD/MM/YYYY').add(index + 1, 'M').format('DD/MM/YYYY'),
      due_date: moment(dueDate, 'DD/MM/YYYY').add(index, 'M').format('DD/MM/YYYY'),
      billing_item_id: 0,
      client_id: this.props.client.code,
      instruction: this.state.instruction,
      additionalInstruction: this.state.additionalInstruction,
      company_info_id: this.props.company.id,
      bank_account_id: this.state.bankAccount.id
    }));

    this.setState({
      payments,
      numberOfInstallments: numberOfInstallments >= 0 ? numberOfInstallments : 0,
    });
  }

  changeInstructions = ({ target: { value: instruction } }) => instruction.length <= 80 ? this.setState({ instruction }) : toastr.warning("A instrucão bancária deve ter no máximo 80 caracteres.");

  changeAdditionalInstructions = ({ target: { value: additionalInstruction } }) => additionalInstruction.length <= 80 ? this.setState({ additionalInstruction }) : toastr.warning("A instrucão bancária deve ter no máximo 80 caracteres.");

  changeInterest = ({ target: { value: interest } }) => {
    if (interest == "" || (parseFloat(interest) >= 0 && parseFloat(interest) < 100)) {
      this.setState({ interest })
    } else {
      toastr.warning("Por favor, informe um valor entre 0 e 99.");
    }
  }

  changeFinePercent = ({ target: { value: finePercent } }) => {
    if (finePercent == "" || (parseFloat(finePercent) >= 0 && parseFloat(finePercent) < 100)) {
      this.setState({ finePercent })
    } else {
      toastr.warning("Por favor, informe um valor entre 0 e 99.");
    }
  }

  changeDiscountTime = ({ target: { value: discountTime } }) => {
    if (discountTime == "" || parseInt(discountTime) >= 0) {
      this.setState({ discountTime })
    } else {
      toastr.warning("Por favor, informe um valor maior ou igual a 0.");
    }
  }

  changeDiscountValue = ({ target: { value: discountValue } }) => {
    if (discountValue == "" || parseFloat(discountValue) >= 0) {
      this.setState({ discountValue })
    } else {
      toastr.warning("Por favor, informe um valor maior ou igual a 0.");
    }
  }

  changeMaxOverdueDays = ({ target: { value: maxOverdueDays } }) => {
    if (maxOverdueDays == "" || parseFloat(maxOverdueDays) >= 0) {
      this.setState({ maxOverdueDays });
    } else {
      toastr.warning("Por favor, informe um valor maior ou igual a 0.");
    }
  }

  onModalClose = () => this.props.closeModal({
    ...this.state,
    firstDueDate: typeof this.state.firstDueDate === "object" ? this.state.firstDueDate.toISOString() : this.state.firstDueDate,
  })

  renderPreviewButton = (_, { installmentNumber, value, due_date }) => {
    const {
      company: { id: companyId },
      client: { id: clientId },
      contractId,
      billingItem: { id: billingItemId }
    } = this.props;
    const {
      instruction,
      additionalInstruction,
      interest,
      bankAccount: { id: bankAccountId },
      maxOverdueDays,
    } = this.state;

    return (
      <BankSlipPreview
        installmentNumber={installmentNumber}
        value={value}
        dueDate={due_date}
        billingItemId={billingItemId}
        companyId={companyId}
        clientId={clientId}
        instruction={instruction}
        bankAccountId={bankAccountId}
        contractId={contractId}
        additionalInstruction={additionalInstruction}
        interest={interest}
        maxOverdueDays={maxOverdueDays}
      />
    );
  }

  toggleApplyDiscount = () => {
    this.setState(({ applyDiscount }) => ({ applyDiscount: !applyDiscount }));
  }

  toggleChargeFine = () => {
    this.setState(({ chargeFine }) => ({ chargeFine: !chargeFine }));
  }

  render() {
    const {
      company,
      client,
      paymentMethods,
    } = this.props;
    const {
      payments,
      bankAccount,
      paymentMethod,
      instruction,
      additionalInstruction,
      interest,
      firstDueDate,
      numberOfInstallments,
      generateParcels,
      applyDiscount,
      chargeFine,
      accounts,
      discountValue,
      discountTime,
      finePercent,
      maxOverdueDays
    } = this.state;
    const isBankSlip = paymentMethod && paymentMethod.payment_type === "bank_slip";

    return (
      <Modal
        className="horizontal-centered"
        show={this.props.show}
        onHide={this.onModalClose}
        dialogClassName="default-modal"
      >
        <Header
          style={{ fontSize: '45px' }}
          closeButton
        >
          <Title style={boldTextStyle}>Emitir Pagamento</Title>
        </Header>

        <Body>
          <Grid fluid className="no-padding">
            <Row>
              <Col xs={6}>
                <div>
                  <h4 style={{ textAlign: "left" }}>
                    Razão social da empresa que receberá o pagamento:
                </h4>

                  <div style={{ textAlign: "left" }}>
                    <Select
                      placeholder={dropdownPlaceholder}
                      isDisabled
                      value={company}
                      options={[company]}
                      getOptionLabel={({ company_name }) => company_name}
                      getOptionValue={({ id }) => id}
                    />
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <h4 style={{ textAlign: "left" }}>
                    Conta bancária que receberá o pagamento:
                </h4>

                  <div style={{ textAlign: "left" }}>
                    <Select
                      placeholder={dropdownPlaceholder}
                      value={bankAccount}
                      onChange={this.handleSelect("bankAccount")}
                      options={accounts}
                      getOptionLabel={({ identification }) => identification}
                      getOptionValue={({ id }) => id}
                    />
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <h4 style={{ textAlign: "left" }}>
                    Dados do Cliente:
                </h4>

                  <div style={{ textAlign: "left" }}>
                    <Select
                      placeholder={dropdownPlaceholder}
                      isDisabled
                      value={client}
                      options={[client]}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ code }) => code}
                    />
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <h4 style={{ textAlign: "left" }}>
                    Forma de pagamento:
                </h4>

                  <div style={{ textAlign: "left" }}>
                    <Select
                      placeholder={dropdownPlaceholder}
                      value={paymentMethod}
                      onChange={this.handleSelect("paymentMethod")}
                      options={accounts.length ? paymentMethods : paymentMethods.filter(({ payment_type }) => payment_type === "cash")}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                    />
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <h4 style={{ textAlign: "left" }}>
                    Valor Total:
                </h4>
                  <h1 style={boldTextStyle}>
                    R$ {this.props.price.toFixed(2)}
                  </h1>
                </div>

                <div>
                  <h4 style={{ textAlign: "left" }}>
                    Vencimento
                </h4>

                  <h1 style={boldTextStyle}>
                    {moment(firstDueDate).format('DD/MM/YYYY')}
                  </h1>
                </div>

                <Calendar onClickDay={(day) => this.handleDueDateChange(day)} />

                <div>
                  <h1 style={boldTextStyle}>{numberOfInstallments} parcelas</h1>
                </div>

                {!generateParcels && (
                  <Button bsStyle="success" onClick={this.generateParcels}>Gerar Parcelas</Button>
                )}
              </Col>

              {generateParcels && (
                <Col xs={6}>
                  <div>
                    <BootstrapTable
                      ref='table'
                      data={payments}
                      cellEdit={{
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: this.onAfterSaveCell,
                      }}
                      footerData={this.footerData}
                      footer
                    >
                      <TableHeaderColumn width='68px' isKey dataField='installmentNumber'>
                        Parcela
                    </TableHeaderColumn>
                      <TableHeaderColumn dataField='due_date' >
                        Vencimento
                    </TableHeaderColumn>
                      <TableHeaderColumn dataField='value' editable dataFormat={this.priceFormatter}>
                        Valor Líquido
                    </TableHeaderColumn>
                      {isBankSlip && (
                        <TableHeaderColumn dataField='id' dataFormat={this.renderPreviewButton} editable={false} >
                          Visualizar
                      </TableHeaderColumn>
                      )}

                    </BootstrapTable>

                    <div style={{ textAlign: 'right', marginTop: 20 }}>
                      <i
                        className='glyphicon glyphicon-plus'
                        style={iconStyle}
                        href={'./#'}
                        onClick={() => this.changeInstallmentsNumber({ action: 1 })}
                      />

                      <i
                        className='glyphicon glyphicon-minus'
                        style={iconStyle}
                        href={'./#'}
                        onClick={() => this.changeInstallmentsNumber({ action: -1 })}
                      />
                    </div>
                  </div>
                  <FormGroup
                    controlId="formControlsTextarea"
                    style={{ marginTop: 15 }}
                  >
                    <ControlLabel>Instruções para pagamento dos boletos</ControlLabel>
                    <FormControl
                      type="text"
                      value={instruction || ""}
                      onChange={this.changeInstructions}
                    />
                    <FormControl.Feedback />
                  </FormGroup>

                  <FormGroup
                    controlId="additionalInstruction"
                    style={{ marginTop: 15 }}
                  >
                    <ControlLabel>Instruções adicionais para pagamento dos boletos</ControlLabel>
                    <FormControl
                      type="text"
                      value={additionalInstruction || ""}
                      onChange={this.changeAdditionalInstructions}
                    />
                    <FormControl.Feedback />
                  </FormGroup>


                  <FormGroup
                    controlId="interestField"
                    style={{ marginTop: 15 }}
                  >
                    <ControlLabel>Juros das parcelas (% ao mês)</ControlLabel>
                    <FormControl
                      type="number"
                      step={0.01}
                      min={0}
                      max={99}
                      value={interest}
                      onChange={this.changeInterest}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>Valor utilizado para calcular o valor dos juros enviados ao banco.</HelpBlock>
                  </FormGroup>

                  <Row>
                    <Col sm={12}>
                      <Checkbox
                        checked={chargeFine}
                        checkboxClass="icheckbox_square-green"
                        increaseArea="20%"
                        onClick={this.toggleChargeFine}
                        label="&emsp;Cobrar multa por atraso"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <FormGroup
                        controlId="interestField"
                        style={{ marginTop: 15 }}
                      >
                        <ControlLabel>Percentual de multa por atraso %</ControlLabel>
                        <FormControl
                          disabled={!chargeFine}
                          type="number"
                          step={0.01}
                          min={0}
                          min={99}
                          value={finePercent}
                          onChange={this.changeFinePercent}
                        />
                        <FormControl.Feedback />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      <Checkbox
                        checked={applyDiscount}
                        checkboxClass="icheckbox_square-green"
                        increaseArea="20%"
                        onClick={this.toggleApplyDiscount}
                        label="&emsp;Aplicar desconto"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <FormGroup
                        controlId="interestField"
                        style={{ marginTop: 15 }}
                      >
                        <ControlLabel>Aplicar desconto até (dias):</ControlLabel>
                        <FormControl
                          disabled={!applyDiscount}
                          type="number"
                          step={1}
                          min={0}
                          value={discountTime}
                          onChange={this.changeDiscountTime}
                        />
                        <FormControl.Feedback />
                        <HelpBlock>O desconto será aplicado caso o pagamento seja efetuado {discountTime} {discountTime == 1 ? 'dia' : 'dias'} antes da data de vencimento.</HelpBlock>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup
                        controlId="interestField"
                        style={{ marginTop: 15 }}
                      >
                        <ControlLabel>Valor do desconto (R$)</ControlLabel>
                        <FormControl
                          disabled={!applyDiscount}
                          type="number"
                          step={0.01}
                          min={0}
                          value={discountValue}
                          onChange={this.changeDiscountValue}
                        />
                        <FormControl.Feedback />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      <FormGroup
                        controlId="maxOverdueDaysField"
                        style={{ marginTop: 15 }}
                      >
                        <ControlLabel>Prazo máximo para pagamento após o vencimento: (em dias)</ControlLabel>
                        <FormControl
                          type="number"
                          step={1}
                          min={0}
                          value={maxOverdueDays}
                          onChange={this.changeMaxOverdueDays}
                        />
                        <FormControl.Feedback />
                        <HelpBlock>
                          { maxOverdueDays > 0 && `Será adicionada uma instrução ao boleto: "Não receber após DD/MM/YYYY"` }
                        </HelpBlock>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Grid>
        </Body>

        <Footer>
          <Button bsStyle="success" onClick={this.onModalClose}>Concluir Emissão</Button>
        </Footer>
      </Modal>
    )
  }
}

export default PaymentsModal;
