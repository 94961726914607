import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import InstallmentsTable from "./InstallmentsTable";
import InstallmentModal from "./InstallmentModal";
import ExpenseModal from "./ExpenseModal";
import ImportModal from "./ImportModal";
import moment from "moment";

class AccountsPayable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importModal: false,
      expenseModal: !!props.selected_expense,
      selectedInstallment: null,
      selectedExpense: props.selected_expense,
      installments: props.installments,
    };
  }

  refetchInstallments = () => {
    const {
      start_date,
      end_date,
      status,
      archived,
    } = this.props;

    const startDate = moment(start_date).format("DD-MM-YYYY");
    const endDate = moment(end_date).format("DD-MM-YYYY");
    const endpoint = `/finances/accounts_payable.json?start_date=${startDate}&end_date=${endDate}&status=${status}&archived=${archived}`;

    axios.get(endpoint)
      .then(({ data: { installments } } ) => {
        this.setState({ installments });
      })
      .catch(this.handleUnexpectedError);
  }

  onSubmit = (expense, callback) => {
    const {
      selectedExpense,
    } = this.state;

    const id = selectedExpense && selectedExpense.id;

    if (id) {
      this.updateExpense(expense, callback, id);
    } else {
      this.createExpense(expense, callback);
    }
  }

  updateExpense = (expense, callback, id) => {
    axios.put(`/expenses/${id}.json`, { expense })
      .then(({ data }) => {
        if (data.success) {
          toastr.success("Despesa atualizada com sucesso");

          callback();
          this.refetchInstallments();
          this.handleCloseExpense();
        } else {
          toastr.error(data.error, "Falha ao atualizada despesa");
        }
      })
      .catch(this.handleUnexpectedError);
  }

  createExpense = (expense, callback) => {
    axios.post("/expenses.json", { expense })
      .then(({ data }) => {
        if (data.success) {
          window.location = '/finances/accounts_payable';
        } else {
          toastr.error(data.error, "Falha ao criar despesa");
        }
      })
      .catch(this.handleUnexpectedError);
  }

  setInstallmentAsPaid = (installment, callback) => {
    const {
      selectedInstallment,
    } = this.state;

    axios.post(`/installments/set_as_paid/${selectedInstallment.id}.json`, { installment })
      .then(({ data }) => {
        if (data.success) {
          toastr.success("Parcela atualizada com sucesso");

          callback();
          this.refetchInstallments();
          this.handleCloseInstallment();
        } else {
          toastr.error(data.error, "Falha ao atualizar parcela");
        }
      })
      .catch(this.handleUnexpectedError);
  }

  fetchExpense = (expense_id) => {
    axios.get(`/expenses/${expense_id}.json`,)
      .then(({ data: { expense } }) => {
        this.setState({
          selectedExpense: expense,
          expenseModal: true,
        })
      })
      .catch(this.handleUnexpectedError);
  }

  deleteExpense = (expense_id) => {
    axios.delete(`/expenses/${expense_id}.json`,)
    .then(({ data }) => {
      if (data.success) {
        toastr.success("Despesa excluída com sucesso");

        this.refetchInstallments();
      } else {
        toastr.error(data.error, "Falha ao excluir despesa");
      }
    })
      .catch(this.handleUnexpectedError);
  }

  handleCloseExpense = () => {
    this.setState({ expenseModal: false, selectedExpense: null });
  }

  handleOpenExpense = () => {
    this.setState({ expenseModal: true });
  }

  toggleImportModal = () => {
    const { importModal } = this.state;

    this.setState({ importModal: !importModal });
  }

  handleCloseInstallment = () => {
    this.setState({ selectedInstallment: null });
  }

  handleOpenInstallment = (selectedInstallment) => {
    this.setState({ selectedInstallment });
  }

  handleUnexpectedError = (error) => {
    toastr.error("Por favor, tente novamente mais tarde", "Ocorreu um erro inesperado");
  }

  render() {
    const {
      departments,
      centers,
      providers,
      bank_accounts,
      payment_methods,
      account_plans,
      companies,
      permissions,
      user_admin,
    } = this.props;
    const {
      importModal,
      expenseModal,
      selectedInstallment,
      installments,
      selectedExpense,
    } = this.state;

    return (
      <div>
        <div className="ibox">
          <div className="ibox-content">
            <Button onClick={this.handleOpenExpense} bsClass="btn btn-outline btn-primary">
              Adicionar despesa
            </Button>

            <Button onClick={this.toggleImportModal} bsClass="btn btn-outline btn-primary ml-10">
              Importar nota fiscal
            </Button>

            <InstallmentsTable
              installments={installments}
              refetchInstallments={this.refetchInstallments}
              handleUnexpectedError={this.handleUnexpectedError}
              handleOpenInstallment={this.handleOpenInstallment}
              fetchExpense={this.fetchExpense}
              deleteExpense={this.deleteExpense}
              permissions={permissions}
              userAdmin={user_admin}
            />
          </div>
        </div>

        <ExpenseModal
          show={expenseModal}
          expense={selectedExpense}
          departments={departments}
          centers={centers}
          providers={providers}
          account_plans={account_plans}
          paymentMethods={payment_methods}
          bankAccounts={bank_accounts}
          companies={companies}
          onCancel={this.handleCloseExpense}
          onSubmit={this.onSubmit}
        />

        <InstallmentModal
          show={selectedInstallment != null}
          paymentMethods={payment_methods}
          bankAccounts={bank_accounts}
          companies={companies}
          installment={selectedInstallment}
          onCancel={this.handleCloseInstallment}
          onSubmit={this.setInstallmentAsPaid}
        />

        <ImportModal
          show={importModal}
          onClose={this.toggleImportModal}
          handleUnexpectedError={this.handleUnexpectedError}
        />
      </div>
    );
  }
}

export default AccountsPayable;
