import React from "react";
import {
  Col,
  Row,
  Modal
} from "react-bootstrap";
import classNames from "classnames";
import axios from "axios";
import update from "immutability-helper";
import _ from "lodash";

import ContractInfo from "./steps/ContractInfo";
import EquipmentsServices from "./steps/EquipmentsServices";
import Travels from "./steps/Travels";
import { objectlize, generateRandomId } from "../../../utils/helpers";
import calculateShipmentPrice from "../../../utils/calculateShipmentPrice";

const steps = {
  CONTRACT_INFO: {
    key: 'CONTRACT_INFO',
    component: ContractInfo,
  },
  EQUIPMENTS_SERVICES: {
    key: 'EQUIPMENTS_SERVICES',
    component: EquipmentsServices,
  },
  TRAVELS: {
    key: 'TRAVELS',
    component: Travels,
  },
}

const SHIPMENT_ATTRIBUTES = [
  'id',
  'quantity',
  'price',
  'action',
  'payment_method_id',
  '_destroy',
];

const OPERATORS_ATTRIBUTES = [
  'id',
  'operator_id',
  'observation',
  '_destroy',
];

const REQUEST_ORDER_ATTRIBUTES = [
  'id',
  'comments',
  '_destroy',
  'additional_service_request_id',
  'equipment_request_id',
  'additional_service_shipment_attributes',
  'equipment_shipment_attributes',
];

class EndServiceOrderModal extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      activeStep: steps.CONTRACT_INFO.key,
      ...this.parse(props),
    }
  }

  componentWillReceiveProps(props) {
    if (props.serviceOrder) {
      if (!this.state.data || (this.state.data.id !== props.serviceOrder.id)) {
        this.setState(this.parse(props));
      }
    }
  }

  parse = (props) => {
    if (props.serviceOrder === null) {
      return {};
    }

    return {
      data: {
        id: props.serviceOrder.id,
        start_time: props.serviceOrder.start_time,
        end_time: props.serviceOrder.end_time,
        equipment_request_orders_attributes: objectlize(
          props.serviceOrder.equipments,
          ({ id }) => id,
          ({ shipment, ...request }) => ({
            equipment_shipment_attributes: objectlize(
              shipment !== null ? [shipment] : [],
              ({ id }) => id,
              ({ quantity }) => ({
                price: calculateShipmentPrice(request, quantity),
                action: shipment.action || 0,
              }),
            )
          })
        ),
        additional_service_request_orders_attributes: objectlize(
          props.serviceOrder.services,
          ({ id }) => id,
          ({ shipment, ...request }) => ({
            additional_service_shipment_attributes: objectlize(
              shipment !== null ? [shipment] : [],
              ({ id }) => id,
              ({ quantity }) => ({
                price: calculateShipmentPrice(request, quantity),
                action: shipment.action || 0,
              }),
            )
          })
        ),

        operators_service_orders_attributes: objectlize(
          props.serviceOrder.operators_service_orders,
          ({ id }) => id,
          () => ({
            _destroy: false,
          })
        ),
        notes: props.serviceOrder.notes || "",
        status: "finished",
      }
    };
  };

  onSave = () => {
    const { serviceOrder, dashboard } = this.props;

    const {
      equipment_request_orders_attributes: equipments,
      additional_service_request_orders_attributes: additional_services,
      operators_service_orders_attributes: operators,
      ...service_order,
    } = this.state.data;

    const operators_service_orders_attributes = Object.keys(operators)
      .reduce((acc, id) => ({
        ...acc,
        [id]: _.pick(operators[id], OPERATORS_ATTRIBUTES),
      }), {});

    const equipment_request_orders_attributes = Object.keys(equipments)
      .reduce((acc, id) => {
        const current = equipments[id];

        const request_order = {
          ..._.pick(current, REQUEST_ORDER_ATTRIBUTES),
          equipment_shipment_attributes: Object.keys(current.equipment_shipment_attributes)
            .reduce((shipments, shipment_id) => _.pick(current.equipment_shipment_attributes[shipment_id], SHIPMENT_ATTRIBUTES), {})
        };

        return {
          ...acc,
          [id]: request_order,
        };
      }, {});

    const additional_service_request_orders_attributes = Object.keys(additional_services)
      .reduce((acc, id) => {
        const current = additional_services[id];

        const request_order = {
          ..._.pick(current, REQUEST_ORDER_ATTRIBUTES),
          additional_service_shipment_attributes: Object.keys(current.additional_service_shipment_attributes)
            .reduce((shipments, shipment_id) => _.pick(current.additional_service_shipment_attributes[shipment_id], SHIPMENT_ATTRIBUTES), {})
        };

        return {
          ...acc,
          [id]: request_order,
        };
      }, {});

    const form = {
      service_order: {
        ...service_order,
        operators_service_orders_attributes,
        equipment_request_orders_attributes,
        additional_service_request_orders_attributes,
      },
    };

    window.show_loader();
    axios.patch(`/service_orders/${serviceOrder.id}.json`, form)
      .then(({ data }) => {
        if (data.success) {
          window.location = dashboard ? "/dashboard/index" : "/service_orders";
        } else {
          window.hide_loader();
          if (data.errors.length) {
            data.errors.forEach(error => toastr.warning(error, "Falha ao finalizar OS"));
          } else {
            toastr.warning("Por favor, tente novamente", "Falha ao finalizar OS")
          }
        }
      })
      .catch(error => {
        window.hide_loader();
        toastr.error(error, "Ocorreu um erro inesperado");
      });
  }

  onChangeShipment = (type, id, shipmentId, field) => (value) => {
    this.setState((state) => update(state, {
      data: {
        [`${type}_request_orders_attributes`]: {
          [id]: {
            [`${type}_shipment_attributes`]: {
              [shipmentId]: {
                [field]: { $set: value }
              }
            }
          }
        }
      }
    }));
  }

  onAddOperator = (props) => {
    const { operators_service_orders_attributes } = this.state.data;

    const operators = Object.keys(operators_service_orders_attributes);

    const operatorIds = operators.filter((key) => {
      const operator = operators_service_orders_attributes[key];
      const { code } = operator;

      return code === props.code;
    });

    if (operatorIds.length > 0) {
      const id = operatorIds[0];
      const operator = operators_service_orders_attributes[id];

      if (operator._destroy) {
        this.removeOperator(id, false);
      } else {
        toastr.warning("Este operador já foi adicionado", "Falha ao adicionar operador");
      }
      return;
    }

    const randomId = generateRandomId();

    const operator = {
      [randomId]: {
        ...props,
        id: null,
        _destroy: false,
      }
    };

    this.setState((state) => update(state, {
      data: {
        operators_service_orders_attributes: { $merge: operator }
      }
    }));
  }

  onRemoveOperator = (id) => {
    this.removeOperator(id, true);
  }

  removeOperator = (id, _destroy) => {
    this.setState((state) => update(state, {
      data: {
        operators_service_orders_attributes: {
          [id]: {
            _destroy: { $set: _destroy },
          }
        }
      }
    }));
  }

  onChangeData = (key) => (value) => {
    this.setState(({ data }) => ({
      data: {
        ...data,
        [key]: value,
      }
    }));
  }

  changeStep = (activeStep) => ()=> {
    this.setState({
      activeStep,
    });
  }

  onHide = () => {
    const {
      onHide
    } = this.props;

    this.setState({
      activeStep: steps.CONTRACT_INFO.key,
    });

    if (onHide) {
      onHide();
    } else {
      window.location = '/service_orders';
    }
  }

  render() {
    const {
      show,
      serviceOrder,
      userAdmin,
      permissions,
    } = this.props;

    const os = serviceOrder || {};
    const contract = os.contract || {};
    const client = os.client || {};

    const {
      activeStep,
      data,
    } = this.state;

    const CurrentStep = steps[activeStep].component;

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        bsSize="large"
        backdrop="static"
        className="horizontal-centered"
        dialogClassName="dark end-service-order-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Finalizar ordem de serviço
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className="info">
                <p className="info-item">
                  Peça:
                  <strong className="ml-10">
                    {os.part}
                  </strong>
                </p>

                <div className="dot"/>

                <p className="info-item">
                  Cód. OS:
                  <strong className="ml-10">
                    {os.code}
                  </strong>
                </p>

                <div className="dot"/>

                <p className="info-item">
                  Data:
                  <strong className="ml-10">
                    {os.scheduled_concreting_date}
                  </strong>
                </p>

                <div className="dot"/>

                <p className="info-item">
                  Situação:
                  <strong className="ml-10">
                    {os.status}
                  </strong>
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="step-container">
                <div
                  onClick={this.changeStep(steps.CONTRACT_INFO.key)}
                  className={classNames("step-item", { "active": activeStep === steps.CONTRACT_INFO.key })}
                >
                  <span>Informações do contrato</span>
                </div>

                <div
                  onClick={this.changeStep(steps.EQUIPMENTS_SERVICES.key)}
                  className={classNames("step-item", { "active": activeStep === steps.EQUIPMENTS_SERVICES.key })}
                >
                  <span>Equipamentos e serviços</span>
                </div>

                <div
                  onClick={this.changeStep(steps.TRAVELS.key)}
                  className={classNames("step-item", { "active": activeStep === steps.TRAVELS.key })}
                >
                  <span>Notas fiscais</span>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <CurrentStep
                os={os}
                contract={contract}
                client={client}
                data={data}
                onSave={this.onSave}
                onChangeData={this.onChangeData}
                onChangeShipment={this.onChangeShipment}
                userAdmin={userAdmin}
                permissions={permissions}
                onAddOperator={this.onAddOperator}
                onRemoveOperator={this.onRemoveOperator}
                isFinished={os.status === "finalizada"}
              />
            </Col>
          </Row>

        </Modal.Body>
      </Modal>
    )
  }
}

export default EndServiceOrderModal;
