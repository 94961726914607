import React from "react";
import moment from "moment";

import FilterFinancial from "./filterFinancial/filterFinancial";
import OrdersTable from "./OrdersTable";

class Financial extends React.Component {

  state = {
    selectedOS: "",
    selectedBM: "",
    selectedContract: "",
    selectedStatus: "",
    selectedCompany: "",
    selectedBillingNumber: "",
    selectedInitialDate: "",
    selectedFinalDate: "",
    changedFilter: null,
  }

  handleSelect = stateKey => value => {
    this.setState({
      [stateKey]: value,
      changedFilter: stateKey,
    });
  }

  handleDateChange = stateKey => ({ target: { value } }) => {
    this.setState({
      [stateKey]: value,
      changedFilter: stateKey,
    });
  }

  render() {
    const selectedInitialDate =
      moment(this.state.selectedInitialDate).year() >= 2000
        ? this.state.selectedInitialDate
        : "";

    const selectedFinalDate =
      moment(this.state.selectedFinalDate).year() >= 2000
        ? this.state.selectedFinalDate
        : "";

    return (
      <div>
        <FilterFinancial
          handleSelectOS={this.handleSelect("selectedOS")}
          handleSelectBM={this.handleSelect("selectedBM")}
          handleSelectContract={this.handleSelect("selectedContract")}
          handleSelectStatus={this.handleSelect("selectedStatus")}
          handleSelectCompany={this.handleSelect("selectedCompany")}
          handleSelectBillingNumber={this.handleSelect("selectedBillingNumber")}
          handleInitialDateChange={this.handleDateChange("selectedInitialDate")}
          handleFinalDateChange={this.handleDateChange("selectedFinalDate")}
          {...this.state}
          {...this.props}
        />

        <OrdersTable
          type="OS"
          orders={this.props.serviceOrders}
          fetchUrl="/financial_service_orders"
          dataTotalSize={this.props.serviceOrderCount}
          {...this.state}
          selectedInitialDate={selectedInitialDate}
          selectedFinalDate={selectedFinalDate}
          selectedBM=""
          {...this.props}
        />

        <OrdersTable
          type="BM"
          fetchUrl="/financial_payment_reports"
          orders={this.props.paymentReports}
          dataTotalSize={this.props.paymentReportCount}
          {...this.state}
          selectedInitialDate={selectedInitialDate}
          selectedFinalDate={selectedFinalDate}
          selectedOS=""
          {...this.props}
        />
      </div>
    );
  }
}
export default Financial;
