import React from 'react';

export function onCheckOverlap (timeslots, service_orders, props) {
  const { so_source, time, equipment } = props;

  const moved_start_hour = parseInt(time.slice(0, 2));
  const moved_start_min = parseInt(time.slice(3, 5));

  const moved_end_hour = Math.floor((moved_start_hour * 60 + moved_start_min + so_source.duration) / 60);
  const moved_end_min = (moved_start_min + so_source.duration) % 60;

  const wouldOverlap = array => array.some(item => {

    // If item is not a SO (doesnt have a code) or is not the moved cell itself, it needs to be checked
    if(!item.code || item.id !== so_source.id) {
      const item_start_hour = parseInt(item.start_time.slice(0, 2));
      const item_start_min = parseInt(item.start_time.slice(3, 5));

      const item_end_hour = Math.floor((item_start_hour * 60 + item_start_min + item.duration) / 60);
      const item_end_min = (item_start_min + item.duration) % 60;

      // Cells overlap if item starts after the moved cell starts and the moved cell ends after the item starts
      if(item_start_hour > moved_start_hour &&
        (item_start_hour < moved_end_hour || (item_start_hour === moved_end_hour && item_start_min < moved_end_min))){

        return true;
      }
      // Cells overlap if item starts before the moved cell and ends after the moved cell starts
      else if(item_start_hour <= moved_start_hour &&
        (item_end_hour > moved_start_hour || (item_end_hour === moved_start_hour && item_end_min > moved_start_min))){

        return true;
      }
    }

    return false;
  });

  let equipment_service_orders = service_orders.filter(so => so.equipment_id === equipment.id);
  equipment_service_orders.sort(sortTime);

  if(wouldOverlap(equipment_service_orders)){
    return true;
  }
  else {
    const equipment_timeslots = timeslots.filter(ts => ts.equipment_id === equipment.id);
    equipment_timeslots.sort(sortTime);

    return wouldOverlap(equipment_timeslots);
  }
}

const sortTime = (a,b) => {
  if(a.equipment_id === b.equipment_id){
    const hourA = parseInt(a.start_time.slice(0,2));
    const hourB = parseInt(b.start_time.slice(0,2));

    if(hourA === hourB) {
      const minA = parseInt(a.start_time.slice(3,5));
      const minB = parseInt(b.start_time.slice(3,5));

      return minA - minB;
    }

    return hourA - hourB;
  }
  else {
    return 0;
  }
};

const findPosition = (orderedList, newElem) => {
  const nextElem = orderedList.find(e => e.eq_code > newElem.eq_code);

  return nextElem ? orderedList.indexOf(nextElem) : orderedList.length;
};

export function insertAndReorder (orderedList, newElem) {
  let new_list = [...orderedList];
  let subList = new_list.filter(e => e.equipment_id === newElem.equipment_id);
  const subListIndex = new_list.indexOf(subList[0]);

  if(subListIndex === -1) {
    new_list.splice(findPosition(new_list, newElem), 0, newElem);
  }
  else {
    subList.push(newElem);
    subList.sort(sortTime);

    new_list.splice(subListIndex, subList.length - 1, ...subList);
  }

  return new_list;
}

export function checkEquipment(nextSO, equipment) {
  return (nextSO && nextSO.equipment_id === equipment.id) ? nextSO : null;
}
