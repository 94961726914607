import React, { Component } from 'react';
import axios from 'axios';
import {
  Modal,
  FormControl,
  Button,
  Col,
  Row,
  Grid,
  FormGroup,
  ControlLabel,
  Table,
} from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';
import update from 'immutability-helper';
import allowedParams from './allowedParams';
import moment from "moment";
import Ibox from '../../commons/Ibox';
import CalendarView from './partials/CalendarView';
import SelectRequests from './partials/SelectRequests';
import Swal from 'sweetalert2';

const styles = {
  textArea: {
    height: 75
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  calendar: {
    height: "110vh"
  }
}

const REQUEST_MODAL_BLANK = {
  requestType: null,
  requestAmount: 0,
  requestRepeat: 1,
  requestValue: null,
  requestsModal: false,
};

const SERVICE_ORDER_PARTNERS_ATTRIBUTES = {
  id: null,
  partner: {},
  _destroy: false
};

const REQUEST_ATTRIBUTES = type => ({
  id: null,
  quantity: 0.0,
  comments: "",
  [`${type}_request`]: {},
  [`${type}_request_id`]: null,
  _destroy: false,
});


const REPEAT_OPTIONS = [
  {
    label: "Não se repete",
    value: "DO_NOT_REPEAT",
  },
  {
    label: "Personalizado",
    value: "CUSTOM",
  }
];

const MEASURES = [
  {
    label: "dia",
    value: "days",
    tooltip: "",
  },
  {
    label: "semana",
    value: "weeks",
    tooltip: "Repete",
  },
  {
    label: "mês",
    value: "months",
    tooltip: "",
  },
  {
    label: "ano",
    value: "years",
    tooltip: "",
  },
  {
    label: "nos dias da semana",
    value: "daysOfWeek",
    tooltip: "",
  },
];

class ServiceOrderForm extends Component {
  state = {
    scheduled_concreting_date: "",
    scheduled_start_time: "",
    scheduled_end_time: "",
    part: "",
    client_observations: "",
    internal_observations: "",
    partner_observations: "",
    equipment_request_orders_attributes: {},
    additional_service_request_orders_attributes: {},
    service_order_partners_attributes: {},
    recurrence: [],
    end_date: new Date(),
    measure: MEASURES[0],
    limit: 1,
    units: 1,
    days_of_week: [],
    use_date: true,
    repeat: REPEAT_OPTIONS[0],

    events: [],
    ...REQUEST_MODAL_BLANK
  }

  constructor(props) {
    super(props);

    const {
      end_date,
      ...initialValues,
    } = props.initialValues || {};

    this.state = {
      ...this.state,
      ...initialValues,
      end_date: end_date
        ? moment(end_date, 'DD/MM/YYYY').toDate()
        : new Date(),
      notify: false,
      status: "recent"
    };
  }

  componentDidMount = () => {
    this.loadEvents();
  }

  scheduleServiceOrder = (start, end) => {
    let scheduled_concreting_date = "";
    let scheduled_start_time = "";
    let scheduled_end_time = "";

    if (!!start && !!end) {
      scheduled_concreting_date = moment(start).format("DD/MM/YYYY");
      scheduled_start_time = moment(start).format("HH:mm");
      scheduled_end_time = moment(end).format("HH:mm");
    }

    this.setState({
      scheduled_concreting_date,
      scheduled_start_time,
      scheduled_end_time,
    });
  }

  saveRecurrence = recurrence => this.setState({ recurrence });

  handleChange = key => ({ target: { value } }) => this.setState({ [key]: value });

  handleSelectPartner = key => value => {
    this.setState(
      update(this.state, {
        service_order_partners_attributes: {
          [key]: {
            partner: { $set: value },
            partner_id: { $set: value.id },
          }
        }
      })
    );
  }

  handleSelectRequest = value => this.setState({ requestValue: value });

  handleDeletePartner = key => {
    this.setState(
      update(this.state, {
        service_order_partners_attributes: {
          [key]: {
            _destroy: { $set: true }
          }
        }
      })
    );
  }

  isScheduleValid = () => {
    const { scheduled_concreting_date, scheduled_start_time, scheduled_end_time } = this.state;

    return scheduled_concreting_date && scheduled_start_time && scheduled_end_time;
  }

  isValid = () => {
    if (!this.isScheduleValid()) {
      toastr.warning("É necessário programar a Ordem de Serviço para poder salvá-la.");
      return false;
    }

    return true;
  }

  onSubmit = () => {
    const { onSave, allowRecurrence } = this.props;
    const form = allowRecurrence ? _.pick(this.state, allowedParams) : _.omit(_.pick(this.state, allowedParams), ['recurrence']);

    if (this.isValid()) {
      if (this.props.updating && this.props.allowRecurrence) {
        Swal.fire({
          title: 'Você tem certeza?',
          text: 'Ao salvar você estará aplicando estas alteracões para todas as ordens de serviço recorrentes.',
          type: 'warning',
          confirmButtonText: 'Salvar',
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            show_loader();
            return onSave({ service_order: form });
          } else {
            return null;
          }
        });
      } else {
        show_loader();
        return onSave({ service_order: form });
      }
    }

    return null;
  };

  loadEvents = (date, view) => {
    const formattedDate = date && moment(date).format('DD/MM/YYYY') || false;
    const { serviceOrderId } = this.props || null;
    const url = formattedDate ? `/service_orders_as_events?date=${formattedDate}&view=${view}&service_order_id=${serviceOrderId}` : `/service_orders_as_events?service_order_id=${serviceOrderId}`;
    axios.get(url)
      .then(({ data: { results } }) => {
        const events = results.map(({ start, end, title }) => ({
          title,
          start: moment(start, 'DD/MM/YYYY HH:mm').toDate(),
          end: moment(end, 'DD/MM/YYYY HH:mm').toDate(),
        }));
        this.setState({ events });
      });
  }

  updateRequests = (type, requests) => {
    this.setState({
      [`${type}_request_orders_attributes`]: requests
    });
  }

  saveOpenModal = callback => this.openModal = callback;

  genPartnerKey = () => `${new Date().getTime()}${Object.keys(this.state.service_order_partners_attributes).length}`;

  addPartner = () => {
    this.setState({
      service_order_partners_attributes: {
        ...this.state.service_order_partners_attributes,
        [this.genPartnerKey()]: SERVICE_ORDER_PARTNERS_ATTRIBUTES
      }
    });
  }

  addRequests = (type) => {
    this.setState({ requestsModal: true, requestType: type });
  }

  handleClose = () => {
    this.setState(REQUEST_MODAL_BLANK);
  }

  handleConfirm = () => {
    const {
      requestType,
      requestAmount,
      requestRepeat,
      requestValue,
    } = this.state;

    if (requestValue === null) {
      toastr.warning(`É necessário selecionar um ${requestType === "equipment" ? "equipmamento" : "serviço"}`);
      return;
    }

    if (requestAmount <= 0) {
      toastr.warning(`A quantidade prevista deve ser maior que 0`);
      return;
    }

    if (requestRepeat <= 0 || requestRepeat > 20) {
      toastr.warning(`O número de requisições deve ser entre 1 e 20.`);
      return;
    }

    const generatedRequests = {};
    for (let i = 0; i < requestRepeat; i++) {
      generatedRequests[`${new Date().getTime()}${Object.keys(this.state[`${requestType}_request_orders_attributes`]).length}${i}`] = {
        ...REQUEST_ATTRIBUTES(requestType),
        quantity: requestAmount,
        [`${requestType}_request`]: requestValue,
        [`${requestType}_request_id`]: requestValue.id,
        type: requestValue.type,
        detail: requestValue.detail,
        comments: requestValue.comments,
      }
    }

    this.setState({
      [`${requestType}_request_orders_attributes`]: {
        ...this.state[`${requestType}_request_orders_attributes`],
        ...generatedRequests
      }
    }, this.handleClose());
  }



  renderPartner = (index, key, partner) => {
    return !partner["_destroy"] && (
      <tr key={key}>
        <td className="center-v center-h col-lg-1">{index}</td>
        <td className="center-v col-lg-10">
          <Select
            value={this.state.service_order_partners_attributes[key].partner}
            placeholder="Selecione..."
            onChange={this.handleSelectPartner(key)}
            options={this.props.partners}
            getOptionLabel={(a) => a.partner_name}
            getOptionValue={(a) => a.id}
          />
        </td>
        <td className="center-v center-h col-lg-1">
          <i className="material-icons clickable" onClick={() => this.handleDeletePartner(key)}>
            delete
          </i>
        </td>
      </tr>
    );
  }

  render = () => {
    const {
      onCancel,
      isBlocked,
    } = this.props;

    const {
      internal_observations,
      client_observations,
      partner_observations,
      scheduled_concreting_date,
      scheduled_start_time,
      scheduled_end_time,
      part,
      recurrence,
      end_date,
      measure,
      limit,
      units,
      days_of_week,
      use_date,
      repeat,

      events,
      service_order_partners_attributes,
      equipment_request_orders_attributes,
      additional_service_request_orders_attributes,
      requestsModal,
      requestType,
      requestValue,
      requestAmount,
      requestRepeat
    } = this.state;

    return (
      <div>
        <Ibox title="Empresas parceiras" buttonValue="Adicionar empresa parceira" buttonAction={this.addPartner} tableOnly>
          <Table responsive style={{ marginBottom: 0 }} striped>
            <thead>
              <tr>
                <th className="center-h col-lg-1">#</th>
                <th colSpan={2}>Empresa</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(service_order_partners_attributes).map((key, index) => this.renderPartner(index + 1, key, service_order_partners_attributes[key]))}
            </tbody>
          </Table>
        </Ibox>

        <Ibox title="Equipamentos" buttonValue="Adicionar equipamento" buttonAction={() => this.addRequests("equipment")} tableOnly>
          <SelectRequests
            isBlocked={isBlocked}
            type="equipment"
            attributes={equipment_request_orders_attributes}
            updateRequests={this.updateRequests}
            {..._.pick(this.props, ["additional_service_requests", "equipment_requests"])}
          />
        </Ibox>

        <Ibox title="Serviços" buttonValue="Adicionar serviço" buttonAction={() => this.addRequests("additional_service")} tableOnly>
          <SelectRequests
            isBlocked={isBlocked}
            type="additional_service"
            attributes={additional_service_request_orders_attributes}
            updateRequests={this.updateRequests}
            {..._.pick(this.props, ["additional_service_requests", "equipment_requests"])}
          />
        </Ibox>

        <Ibox
          title="Programação"
          buttonValue="Programar ordem de serviço"
          buttonAction={!isBlocked && this.openModal}
        >
          <Grid fluid>
            <Row>
              <Col md={12}>
                <div style={styles.calendar}>
                  <CalendarView
                    isBlocked={isBlocked}
                    onSaveRecurrence={this.saveRecurrence}
                    onScheduleServiceOrder={this.scheduleServiceOrder}
                    events={events}
                    onInitialize={this.saveOpenModal}
                    initialValue={{
                      scheduled_concreting_date,
                      scheduled_start_time,
                      scheduled_end_time,
                    }}
                    sharedValues={{
                      part,
                      recurrence,
                      end_date,
                      measure,
                      limit,
                      units,
                      days_of_week,
                      use_date,
                      repeat,
                    }}
                    handleChange={this.handleChange}
                    loadEvents={this.loadEvents}
                    MEASURES={MEASURES}
                    REPEAT_OPTIONS={REPEAT_OPTIONS}
                    allowRecurrence={this.props.allowRecurrence}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        </Ibox>

        <Ibox title="Observações">
          <Grid fluid>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Observações para o cliente</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    value={client_observations}
                    onChange={this.handleChange("client_observations")}
                    style={styles.textArea}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Observações para a empresa parceira</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    value={partner_observations}
                    onChange={this.handleChange("partner_observations")}
                    style={styles.textArea}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Observações para uso interno</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    value={internal_observations}
                    onChange={this.handleChange("internal_observations")}
                    style={styles.textArea}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Ibox>

        <div style={styles.actions} className="m-b-lg">
          <Button bsStyle="default" onClick={onCancel}>
            Cancelar
          </Button>
          <Button bsStyle="primary" onClick={this.onSubmit} className="m-l-sm">
            Salvar
          </Button>
        </div>

        <Modal
          className="horizontal-centered"
          show={requestsModal}
          onHide={this.handleClose}
          backdrop={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-navy">
              Adicionar {requestType === "equipment" ? "equipmantos" : "serviços"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <ControlLabel>Selecione uma opção</ControlLabel>
                    <Select
                      value={requestValue}
                      placeholder="Selecione..."
                      onChange={this.handleSelectRequest}
                      options={this.props[`${requestType}_requests`]}
                      getOptionLabel={(r) => `${r.type} ${r.detail} | ${r.comments}`}
                      getOptionValue={(r) => r.id}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Quantidade prevista</ControlLabel>
                    <FormControl
                      type="number"
                      min={0}
                      step={0.01}
                      value={requestAmount}
                      onChange={this.handleChange("requestAmount")}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Número de requisições</ControlLabel>
                    <FormControl
                      type="number"
                      min={0}
                      max={20}
                      step={1}
                      value={requestRepeat}
                      onChange={this.handleChange("requestRepeat")}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={this.handleClose} className="btn-outline">Cancelar</Button>
            <Button bsStyle="primary" onClick={this.handleConfirm} className="btn-outline">Confirmar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ServiceOrderForm;
