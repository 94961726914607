import React from "react";
import {
  Col,
  Row,
  FormGroup,
} from "react-bootstrap";
import { TimePicker } from "@blueprintjs/datetime";

import DarkIbox from "../../../../commons/DarkIbox";

class ContractInfo extends React.Component {

  render() {
    const {
      os,
      contract,
      client,
      data,
      onChangeData,
      userAdmin,
      permissions,
      isFinished,
    } = this.props;

    const {
      periods,
      payment_report,
    } = os;

    const {
      start_time,
      end_time,
    } = data;

    const canUpdate = userAdmin || permissions.includes("update_timers service_orders");

    return (
      <div>
        <Row>
          <Col md={12}>
            <div>
              <DarkIbox>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <p>Cód. cliente</p>
                      <strong>{client.code}</strong>
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <p>Nome do cliente</p>
                      <strong>{client.name}</strong>
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <p>Cód. contrato</p>
                      <strong>{contract.code}</strong>
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <p>Identificação do contrato</p>
                      <strong>{contract.identification}</strong>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <p>Vendedor</p>
                      <strong>{contract.seller}</strong>
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <p>Frequência</p>
                      <strong>{contract.frequency}</strong>
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <p>Limite de crédito</p>
                      <strong>{contract.credit_limit}</strong>
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <p>Forma de pagamento</p>
                      <strong>{contract.payment_method}</strong>
                    </FormGroup>
                  </Col>
                </Row>
              </DarkIbox>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div>
              <DarkIbox minHeight>
                <div className="ibox-green-title">
                  <h5>Programado</h5>
                </div>

                <table className="table table-responsive table-dark">
                  <thead>
                    <tr>
                      <th className="col-md-3 pb-5">
                        Início
                      </th>
                      <th className="col-md-3 pb-5">
                        Final
                      </th>
                      <th className="col-md-3 pb-5">
                        Previsão
                      </th>
                      <th className="col-md-3 pb-5">
                        Períodos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="col-md-3">
                        {os.scheduled_start_time}
                      </td>
                      <td className="col-md-3">
                        {os.scheduled_end_time}
                      </td>
                      <td className="col-md-3">
                        {os.prediction_time}
                      </td>
                      <td className="col-md-3">
                        {
                          periods.scheduled.join(', ')
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </DarkIbox>
            </div>
          </Col>

          <Col md={6}>
            <div>
              <DarkIbox minHeight>
                <div className="ibox-green-title">
                  <h5>Realizado</h5>
                </div>

                <table className="table table-responsive table-dark">
                  <thead>
                    <tr>
                      <th className="col-md-3 pb-5">
                        Início
                      </th>
                      <th className="col-md-3 pb-5">
                        Final
                      </th>
                      <th className="col-md-3 pb-5">
                        Duração
                      </th>
                      <th className="col-md-3 pb-5">
                        Períodos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td className="col-md-3">
                        {
                          canUpdate && !isFinished
                            ? (
                              <TimePicker
                                value={moment(start_time, 'HH:mm').toDate()}
                                onChange={(time) => onChangeData("start_time")(moment(time).format("HH:mm"))}
                                showArrowButtons
                              />
                            )
                            : (
                              os.start_time
                            )
                        }
                      </td>
                      <td className="col-md-3">
                      {
                          canUpdate && !isFinished
                            ? (
                              <TimePicker
                                value={moment(end_time, 'HH:mm').toDate()}
                                onChange={(time) => onChangeData("end_time")(moment(time).format("HH:mm"))}
                                showArrowButtons
                              />
                            )
                            : (
                              os.end_time
                            )
                        }
                      </td>
                      <td className="col-md-3">
                        {os.duration_time}
                      </td>
                      <td className="col-md-3">
                        {
                          periods.realized.join(', ')
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </DarkIbox>
            </div>
          </Col>
        </Row>

        {
          contract.frequency === "boletim de medição" && (
            <Row>
              <Col md={12}>
                <DarkIbox>
                    <div className="ibox-green-title">
                      <h5>Boletim de medição</h5>
                    </div>

                    <table className="table table-responsive table-dark">
                      <thead>
                        <tr>
                          <th className="col-md-3 pb-5">
                            Código
                          </th>
                          <th className="col-md-3 pb-5">
                            Data inicição
                          </th>
                          <th className="col-md-3 pb-5">
                            Data final
                          </th>
                          <th className="col-md-3 pb-5">
                            Data de pagamento
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="col-md-3">
                            {payment_report.code}
                          </td>
                          <td className="col-md-3">
                            {payment_report.initial_date}
                          </td>
                          <td className="col-md-3">
                            {payment_report.final_date}
                          </td>
                          <td className="col-md-3">
                            {payment_report.payment_date}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </DarkIbox>
              </Col>
            </Row>
          )
        }
      </div>
    );
  }
}

export default ContractInfo;
