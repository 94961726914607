const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

const WEEKDAYS_SHORT = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb'
];

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const formatDay = day => day.toDateString();

export const formatMonthTitle = d => `${MONTHS[d.getMonth()]} ${d.getFullYear()}`;

export const formatWeekdayShort = i => WEEKDAYS_SHORT[i];

export const formatWeekdayLong = i =>  WEEKDAYS_LONG[i];

export const getFirstDayOfWeek = () => 0;

export const getMonths = () => MONTHS;

export const getDefaultMaxDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 20);
  date.setMonth(11);
  date.setDate(31);
  return date;
}

export default {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
};
