import React from 'react';
import {
  Col,
  Row,
  Grid,
  FormGroup,
  ControlLabel,
  Button,
  Modal
} from 'react-bootstrap';
import axios from 'axios';

import InputFile from '../inputs/InputFile';

class ImportModal extends React.Component {
  state = {
    file: null,
    provider_id: "",
    total_value: "",
    nf_number: "",
    nf_access_key: "",
    discount: "",
    fees: "",
    competence_date: "",
    xml: "",
  };

  handleSelect = key => value => this.setState({ [key]: value });

  onCancel = () => {
    const { onClose } = this.props;

    this.setState({ file: null });

    onClose();
  }

  onSubmit = () => {
    const { handleUnexpectedError } = this.props;
    const { file } = this.state;

    axios.post("/finances/import_expense", { file })
      .then(({
        data: {
          provider_id,
          total_value,
          nf_number,
          nf_access_key,
          discount,
          fees,
          competence_date,
          xml,
          error,
        }
      }) => {
        if (error) {
          toastr.error(error);
          return;
        }

        this.setState({
          provider_id,
          total_value,
          nf_number,
          xml,
          nf_access_key,
          discount,
          fees,
          competence_date,
        }, this.submitForm);
      })
      .catch(handleUnexpectedError);
  };

  submitForm = () => {
    $('form#create_expense').submit();
  }

  render() {
    const { show } = this.props;
    const {
      provider_id,
      total_value,
      nf_number,
      nf_access_key,
      discount,
      fees,
      competence_date,
      xml,
    } = this.state;

    return (
      <Modal
        className="horizontal-centered"
        show={show}
        onHide={this.onCancel}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Importar nota fiscal
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Selecione a nota fiscal</ControlLabel>
                  <InputFile
                    onChange={this.handleSelect("file")}
                    accept="text/xml"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>

        <form
          id="create_expense"
          action="/finances/accounts_payable"
          method="POST"
        >
          <input type="hidden" value={total_value} name="total_value"/>
          <input type="hidden" value={nf_number} name="nf_number"/>
          <input type="hidden" value={provider_id} name="provider_id"/>
          <input type="hidden" value={nf_access_key} name="nf_access_key"/>
          <input type="hidden" value={discount} name="discount"/>
          <input type="hidden" value={fees} name="fees"/>
          <input type="hidden" value={competence_date} name="competence_date"/>
          <input type="hidden" value={xml} name="xml"/>
        </form>

        <Modal.Footer>
          <Button bsStyle="default" onClick={this.onCancel} className="btn-outline">Cancelar</Button>
          <Button bsStyle="primary" onClick={this.onSubmit} className="btn-outline">Confirmar</Button>
        </Modal.Footer>
      </Modal>
    );
  };
}

export default ImportModal;
