import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DarkIbox from "../../../../commons/DarkIbox";
import OperatorsList from "../../OperatorsList";

class ScannedInvoices extends React.Component {

  renderLink = (id, pdf_link) => {
    const invalidLink = (pdf_link === null || pdf_link === "");
    const link = invalidLink ? "javascript:void(0)" : pdf_link;
    const tooltip = invalidLink ? "DANFE não disponível" : "Download DANFE";
    const target = invalidLink ? "" : "_blank";

    return (
      <OverlayTrigger
        placement="top"
        overlay={(
            <Tooltip id={`${id}-tooltip`}>
              {tooltip}
            </Tooltip>
          )}
      >
        {
          invalidLink
            ? (
              <i className="fa fa-download fa-2x"></i>
            )
            : (
              <a href={link} target={target}>
                <i className="fa fa-download fa-2x"></i>
              </a>
            )
        }
      </OverlayTrigger>
    );
  }

  render() {
    const productInvoices = this.props.invoices || [];

    return (
      <DarkIbox className="overflow-auto">
        <div className="ibox-green-title">
          <h3>Notas fiscais emitidas</h3>
        </div>

        <table className="table table-responsive table-dark" id="dashboard-datatable">
          <thead>
            <tr>
              <th>Número da NF</th>
              <th>Status</th>
              <th>Equipe</th>
              <th>Quantidade</th>
              <th>Início</th>
              <th>Início do carregamento</th>
              <th>Fim do carregamento</th>
              <th>Início da viagem</th>
              <th>Fim</th>
              <th>DANFE</th>
            </tr>
          </thead>
          <tbody>
            {
              productInvoices.map(({
                id,
                number,
                status,
                operators,
                quantity,
                start_time,
                loading_start_time,
                loading_end_time,
                travel_start_time,
                end_time,
                pdf_link,
              }) => (
                <tr key={id}>
                  <td>{number}</td>
                  <td>{status}</td>
                  <td>
                    <OperatorsList
                      operators={operators}
                    />
                  </td>
                  <td>{quantity}</td>
                  <td>{start_time}</td>
                  <td>{loading_start_time}</td>
                  <td>{loading_end_time}</td>
                  <td>{travel_start_time}</td>
                  <td>{end_time}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      {this.renderLink(id, pdf_link)}
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </DarkIbox>
    );
  }
};

export default ScannedInvoices;
