import React from 'react';

const icon = {
  total: "fa-history",
  recent: "fa-star",
  approved: "fa-thumbs-up",
  accepted: "fa-smile-o",
  rejected: "fa-thumbs-down",
  refused: "fa-thumbs-down",
  finished: "fa-check-circle",
  canceled: "fa-times-circle",
}

const OSStatusIcon = ({ type }) => {
  return (
    <i className={`fa ${icon[type]} fa-2x icon-${type}`}></i>
  );
}

export default OSStatusIcon;
