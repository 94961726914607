import React, { Component } from "react";
import {
  Grid,
  FormControl,
  Modal,
  Button,
  Col,
  ControlLabel,
} from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Calendar from "react-calendar";
import moment from "moment";
import fetch from "isomorphic-fetch"
import fileSaver from "file-saver";

const { Header, Body, Footer } = Modal;

class RentalBillModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      equipmentShipments: [],
      serviceShipments: [],
      billComments: "",
      dueDate: moment().toDate(),
    };
  }

  componentWillReceiveProps({
    billingItem: {
      equipmentShipments,
      serviceShipments,
      billComments,
      dueDate,
    }
  }) {
    this.setState({
      equipmentShipments,
      serviceShipments,
      billComments,
      dueDate: dueDate ? moment(dueDate).toDate() : new Date(),
    });
  }

  handleChange = key => ({ target: { value } }) => this.setState({ [key]: value })

  handleDateChange = dueDate => this.setState({ dueDate })

  onAfterSaveCell = state => (row, _cellName, _cellValue) => {
    const newState = this.state[state].map(cell => {
      const { id, fakeId } = cell;
      const shouldUpdate = row.id ? id === row.id : fakeId === row.fakeId;

      if (shouldUpdate) return row;
      return cell;
    });

    this.setState({ [state]: newState });
  }

  priceFormatter = price => price ? `R$ ${parseFloat(price).toFixed(2).toLocaleString("pt-BR")}` : ""

  onAddRow = state => () => {
    const data = this.state[state];
    const fakeIds = data.map(({ fakeId }) => fakeId || 0);
    const biggestFakeId = fakeIds.length ? Math.max(...fakeIds) : 0;

    this.setState({
      [state]: data.concat({ fakeId: biggestFakeId + 1 })
    });
  }

  onDeleteRow = (state, row) => () => {
    const newState = this.state[state].filter(({ id, fakeId }) => (
      row.id ? id !== row.id : fakeId !== row.fakeId
    ));

    this.setState({
      [state]: newState,
    });
  }

  onModalClose = () => this.props.closeModal(this.state)

  shipmentsTotalPrice = () => {
    const { equipmentShipments, serviceShipments } = this.state;

    return equipmentShipments.concat(serviceShipments).reduce(
      (acc, { total_price }) => acc + parseFloat(total_price),
      0
    );
  }

  downloadInvoicePreview = () => {
    fetch("/preview_invoice", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        companyId: this.props.companyId,
        serviceOrderId: this.props.serviceOrderId,
        billingItem: {
          equipment_shipments: this.state.equipmentShipments,
          service_shipments: this.state.serviceShipments,
          bill_comments: this.state.billComments,
          humanized_due_date: moment(this.state.dueDate).format("DD/MM/YYYY"),
          equipment_services_sum: this.shipmentsTotalPrice(),
          billing_number: this.findBillingNumber(),
        },
      })
    })
      .then(response => response.blob())
      .then(blob => fileSaver.saveAs(blob, "Preview Fatura de Locacao.pdf"))
      .catch(error => toastr.error(error, "Erro"));
  }

  findBillingNumber = () => {
    const { billingItem } = this.props;
    return billingItem && billingItem.billing_number;
  }

  renderDeleteCell = state => (_, row) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <i
        className="fa fa-2x fa-trash"
        style={{ cursor: "pointer" }}
        onClick={this.onDeleteRow(state, row)}
      />
    </div>
  )

  renderTextArea = ({ title, textAreaProps }) => (
    <Col sm={12}>
      <ControlLabel style={{ marginBottom: 20 }}>
        {title}
      </ControlLabel>

      <FormControl
        componentClass="textarea"
        style={{ height: 80, marginBottom: 30 }}
        {...textAreaProps}
      />
    </Col>
  )

  renderTable = (title, state) => (
    <Col sm={12}>
      <ControlLabel style={{ marginBottom: 20 }}>
        {title}
      </ControlLabel>

      <BootstrapTable
        data={this.state[state]}
        cellEdit={{
          mode: "click",
          afterSaveCell: this.onAfterSaveCell(state),
        }}
      >
        <TableHeaderColumn dataField="quantity" dataSort>
          Quantidade
        </TableHeaderColumn>

        <TableHeaderColumn dataField="description" dataSort>
          Descrição
        </TableHeaderColumn>

        <TableHeaderColumn dataField="unit_price" dataSort dataFormat={this.priceFormatter}>
          Preço Unitário
        </TableHeaderColumn>

        <TableHeaderColumn dataField="unit" dataSort>
          Unidade
        </TableHeaderColumn>

        <TableHeaderColumn dataField="total_price" dataSort dataFormat={this.priceFormatter}>
          Preço Total
        </TableHeaderColumn>
        <TableHeaderColumn dataField="id" isKey width="50px" dataFormat={this.renderDeleteCell(state)} />
      </BootstrapTable>

      <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 15, marginTop: 15 }}>
        <i
          className="fa fa-2x fa-plus"
          style={{ cursor: "pointer" }}
          onClick={this.onAddRow(state)}
        />
      </div>
    </Col>
  )

  render() {
    const { billingItem } = this.props;
    return (
      <Modal
        className="horizontal-centered"
        show={this.props.show}
        onHide={this.onModalClose}
        dialogClassName="default-modal"
      >
        <Header style={{ border: 0 }} closeButton>
          <h2 style={{ textAlign: "center" }}>
            Emitir Fatura de Locação {billingItem.billing_number ? `№ ${billingItem.billing_number}`  : ""}
          </h2>
        </Header>
        <Body>
          <Grid fluid className="no-padding">
            <Col
              sm={12}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 0,
                marginBottom: 20,
              }}
            >
              <Col componentClass={ControlLabel} md={3}>
                Data de Vencimento:
              </Col>

              <Col md={2}>
                <h2 style={{ margin: 0, fontWeight: "bold" }}>
                  {moment(this.state.dueDate).format("DD/MM/YYYY")}
                </h2>
              </Col>

              <Col md={3}>
                <Calendar
                  value={this.state.dueDate}
                  onClickDay={this.handleDateChange}
                />
              </Col>
            </Col>

            {this.renderTable("Equipamentos na Ordem de Serviço", "equipmentShipments")}
            {this.renderTable("Serviços Adicionais na Ordem de Serviço", "serviceShipments")}
            {this.renderTextArea({
              title: "Observações",
              textAreaProps: {
                disabled: true,
                value: this.props.paymentComments || "",
              },
            })}
            {this.renderTextArea({
              title: "Informações Adicionais",
              textAreaProps: {
                value: this.state.billComments,
                placeholder: "Informações Adicionais",
                onChange: this.handleChange("billComments"),
              },
            })}

            <Col componentClass={ControlLabel} md={3}>
              Prévia da fatura:
            </Col>

            <Col md={2}>
              <a href="javascript:void(0)" onClick={this.downloadInvoicePreview}>
                <i className="fa fa-3x fa-file-pdf-o" />
              </a>
            </Col>
          </Grid>
        </Body>
        <Footer>
          <Button onClick={this.onModalClose}>Close</Button>
        </Footer>
      </Modal>
    );
  }
}

export default RentalBillModal;
