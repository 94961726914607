import React from 'react';
import InfoCard from "../../../commons/InfoCard";
import OSStatusIcon from "./OSStatusIcon";

const styles = {
  info: {
    display: "flex",
    flexDirection: "column",
  }
}

const title = {
  total: "Total",
  recent: "Novas",
  approved: "Aprovadas",
  accepted: "Aceitas",
  rejected: "Rejeitadas",
  refused: "Recusadas",
  finished: "Finalizadas",
  canceled: "Canceladas",
}

const OSStatus = ({
  count,
  type,
}) => {
  return (
    <InfoCard small>
      <span style={styles.info}>
        <span className="text-normal">
          {title[type]}
        </span>
        <span className="text-big">
          {count}
        </span>
      </span>
      <OSStatusIcon type={type} />
    </InfoCard>
  );
}

export default OSStatus;
