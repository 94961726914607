import React from 'react';
import { Row, Col } from 'react-bootstrap';

const OsInfo = ({ isSole, osCode, osDate }) => (
  <Row style={{ marginBottom: 20 }}>
    <Col xs={3}>
      Frequência: <strong>{isSole ? 'Avulso' : 'Boletim de Medição'}</strong>
    </Col>

    <Col xs={3} />

    {isSole && [
      <Col xs={3} key="1">
        OS: <strong>{osCode}</strong>
      </Col>,

      <Col xs={3} key="2">
        Data (OS): <strong>{osDate}</strong>
      </Col>
    ]}
  </Row>
);

export default OsInfo;
