import React from "react";
import flow from "lodash/flow";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { MAP } from "react-google-maps/lib/constants";

class MarkeredGoogleMaps extends React.Component {
  componentDidMount() {
    this.props.savefitBounds(this.fitBounds);
  }

  fitBounds = () => {
    const { markers } = this.props;
    let bounds = new google.maps.LatLngBounds();

    (markers || []).forEach((marker) => {
      bounds.extend(marker.position);
    });

    this.map.fitBounds(bounds);
  }

  saveMapRef = (map) => {
    this.map = map.context[MAP];
    this.props.saveMapRef(this.map);
  }

  render() {
    const {
      markers,
      defaultZoom,
      defaultLat,
      defaultLon,
    } = this.props;

    const center = {
      lat: defaultLat || -15.77972,
      lng: defaultLon || -47.92972,
    };

    return (
      <GoogleMap
        defaultZoom={defaultZoom || 4}
        defaultCenter={center}
        ref={this.saveMapRef}
      >
        {
          markers.map(({
            id,
            label,
            icon_url,
            ...props
          }) => (
            <Marker
              key={id}
              icon={{
                url: icon_url,
                scaledSize: new google.maps.Size(50, 69),
              }}
              {...props}
            />
          ))
        }
      </GoogleMap>
    );
  }
}

export default flow(
  withGoogleMap,
  withScriptjs,
)(MarkeredGoogleMaps);
