import React, { Component } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Grid,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import { Checkbox } from 'react-icheck';
import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import AsyncSelect from 'react-select/lib/Async';

import debounce from "../../utils/debounce.js";
import ExportScheduleModal from "./ExportScheduleModal";
import DarkIbox from "../../commons/DarkIbox";
import IboxTitle from "../../commons/IboxTitle";
import EquipmentsList from "./partials/EquipmentsList";
import ServiceOrdersTable from "./partials/ServiceOrdersTable";
import ServiceOrderStatus from "./partials/ServiceOrderStatus";
import ServiceOrderChart from "./partials/ServiceOrderChart";
import ActiveItemBox from "./partials/ActiveItemBox";
import ServiceOrderDetails from "./ServiceOrderDetails";
import MarkeredGoogleMaps from '../shared/MarkeredGoogleMaps';
import EndServiceOrderModal from "../shared/EndServiceOrderModal/EndServiceOrderModal";

import crane from '../../images/crane.png';
import truck from '../../images/truck.png';
import worker from '../../images/worker.png';

import only_crane from '../../images/only_crane.svg';
import only_truck from '../../images/only_truck.svg';
import only_worker from '../../images/only_worker.svg';

const MODALS = {
  EXPORT_SCHEDULE: 'EXPORT_SCHEDULE',
  SERVICE_ORDER_DETAILS: 'SERVICE_ORDER_DETAILS',
  END_SERVICE_ORDER_MODAL: 'END_SERVICE_ORDER_MODAL',
};

const ICONS = {
  crane: crane,
  truck: truck,
  worker: worker,
};

const DISPLAY_ICONS = {
  crane: only_crane,
  truck: only_truck,
  worker: only_worker,
};

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_ISO = 'YYYY-MM-DD';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const {
      operatorsCount,
      equipmentsCount,
      serviceOrders,
      checklistsCount,
      equipments,
      serviceOrdersCount,
      statuses,
    } = props;

    this.state = {
      date: moment(new Date()).format(DATE_FORMAT),
      statuses,
      openedModal: null,
      serviceOrder: null,
      markers: [],
      operatorsCount,
      equipmentsCount,
      serviceOrders,
      checklistsCount,
      equipments,
      serviceOrdersCount,
    };
  }

  componentWillMount() {
    setTimeout(() => {
      $('#react-datetimepicker')
        .datetimepicker({
          format: DATE_FORMAT,
          locale: 'pt-br',
        })
        .on('dp.change', ({ date }) => {
          this.onChangeDate(date.format(DATE_FORMAT));
        });
    }, 400);
  }

  onChangeDate = (date) => {
    this.setState(
      { date },
      this.fetchServiceOrders,
    );
  }

  saveMapRef = (map) => {
    this.map = map;
  }

  savefitBounds = (fitBounds) => {
    this.fitBounds = fitBounds;
  }

  addMarker = (id, type, label, position, model) => {
    const typedId = `${type}-${id}`;

    this.setState(({ markers }) => {
      if (markers.filter(({ id: markerId  }) => markerId === typedId).length > 0) {
        toastr.warning('Este marcador já está sendo exibido');
        return;
      }

      return {
        markers: [
          ...markers,
          {
            id: typedId,
            icon_url: ICONS[type],
            type,
            label,
            position,
            model,
            modelId: id,
          }
        ]
      };
    }, this.fitBounds);
  }

  removeMarker = (id) => () => {
    this.setState(({ markers }) => ({
      markers: markers.filter(({ id: markerId }) => (markerId !== id))
    }));
  }

  updateMarkers = () => {
    const { markers } = this.state;

    markers.forEach((marker) => {
      if (marker.model) {
        axios.post(`/trackables/fetch_geolocation?id=${marker.modelId}&model=${marker.model}`)
          .then(({ data: { success, lat, lng }}) => {
            if (success) {
              this.setState((state) => ({
                markers: [
                  ...state.markers.filter(({ id: markerId  }) => markerId !== marker.id),
                  {
                    ...marker,
                    position: {
                      lat,
                      lng,
                    }
                  }
                ]
              }), this.fitBounds);
            }
          })
          .catch();
      }
    });

  }

  componentDidMount = () => {
    $('.react-datetimepicker').datetimepicker({
      format: DATE_FORMAT,
      locale: 'pt-br',
    });

    // This will update makers in a 30s interval
    setInterval(this.updateMarkers, 30 * 1000);

    // This will refetch all service orders in a 60s interval
    setInterval(() => this.fetchServiceOrders(true), 60 * 1000);
  }

  handleChange = key => ({ target: { value } }) => {
    this.setState({ [key]: value });
  }

  toggleModal = (modal) => () => {
    this.setState(({ openedModal }) => {
      const nextModal = openedModal === modal ? null : modal;

      return {
        openedModal: nextModal
      };
    });
  }

  onSelectStatus = (key) => () => {
    this.setState((state) => update(state, {
      statuses: {
        $apply: (statuses) => statuses.map((status) => {
          if (status.key === key) {
            return {
              ...status,
              selected: !status.selected,
            };
          }

          return status;
        })
      }
    }), this.fetchServiceOrders);
  }

  openServiceOrder = (serviceOrder, openedModal) => () => {
    this.setState({
      serviceOrder,
      openedModal,
    });
  }

  fetchServiceOrders = (background = false) => {
    const { date, statuses } = this.state;
    const formattedDate = moment(date, DATE_FORMAT).format(DATE_ISO);
    const joinedStatuses = statuses
      .filter(({ selected }) => selected)
      .map(({ key }) => key)
      .join(",");

    // Disables datatable before fetching new data
    if ($.fn.DataTable.isDataTable('#dashboard-datatable') ) {
      $('#dashboard-datatable').DataTable().destroy();
    }

    if (!background) {
      window.show_loader();
    }
    axios.post(`/dashboard/fetch_service_orders?date=${formattedDate}&statuses=${joinedStatuses}`)
      .then(({ data: {
        operatorsCount,
        equipmentsCount,
        serviceOrders,
        equipments,
        checklistsCount,
      }}) => {
        this.setState({
          operatorsCount,
          equipmentsCount,
          serviceOrders,
          equipments,
          checklistsCount,
        });
      })
      .catch(this.handleUnexpectedError)
      .finally(window.hide_loader);
  }

  fetchCounters = (formattedStartDate, formattedEndDate) => () => {
    window.show_loader();
    axios.post(`/dashboard/fetch_counters?start_date=${formattedStartDate}&end_date=${formattedEndDate}`)
      .then(({ data: serviceOrdersCount }) => {
        this.setState({ serviceOrdersCount });
      })
      .catch(this.handleUnexpectedError)
      .finally(window.hide_loader);
  }

  handleUnexpectedError = (error) => {
    console.log(error);
    toastr.error("Por favor, tente novamente mais tarde", "Ocorreu um erro inesperado");
  }

  onFetchError = (error) => {
    console.log(error);
    toastr.warning("Erro ao carregar informações");
  }

  loadOptions = debounce((input, callback) => {
    this.loadTrackables(input, callback);
  }, 500);

  loadTrackables = (input, callback) => {
    axios.post(`/trackables/find?query=${input}`)
      .then(response => {
        callback(response.data);
      })
      .catch(this.onFetchError);
  };

  selectTrackable = (trackable) => {
    const {
      id,
      title,
      type,
      model,
    } = trackable;

    axios.post(`/trackables/fetch_geolocation?id=${id}&model=${model}`)
      .then(({ data: { success, lat, lng }}) => {
        if (success) {
          this.addMarker(id, type, title, { lat, lng }, model);
        } else {
          toastr.warning("Falha ao obter posição GPS");
        }
      })
      .catch(this.onFetchError);
  }

  render = () => {
    const {
      openedModal,
      serviceOrder,
      markers,
      date,
      operatorsCount,
      equipmentsCount,
      serviceOrders,
      checklistsCount,
      equipments,
      serviceOrdersCount,
      statuses,
    } = this.state;

    const {
      googleMapsKey,
      user_admin,
      permissions,
    } = this.props;

    return (
      <div>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <ServiceOrderStatus
                serviceOrdersCount={serviceOrdersCount}
                fetchCounters={this.fetchCounters}
              />
            </Col>
            <Col md={6}>
              <ServiceOrderChart serviceOrdersCount={serviceOrdersCount} />
            </Col>
          </Row>

          <hr className="dashboard"/>

          <Row>
            <Col md={12}>
              <div className="d-flex justify-content-between text-white">
              <h2 className="dashboard">Informacões para <strong>{date}</strong></h2>

              <div className="mb-15">
                <FormGroup>
                  <ControlLabel>Alterar data:</ControlLabel>
                  <FormControl
                    id="react-datetimepicker"
                    defaultValue={date}
                  />
                </FormGroup>
              </div>
            </div>
            </Col>
          </Row>

          <Row>
            <Col md={9}>
              <EquipmentsList
                equipments={equipments}
                checklistsCount={checklistsCount}
              />
            </Col>
            <Col md={3}>
              <ActiveItemBox
                title="Funcionários"
                active={operatorsCount.busy}
                inactive={operatorsCount.available}
              />

              <ActiveItemBox
                title="Equipamentos"
                active={equipmentsCount.busy}
                inactive={equipmentsCount.available}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <DarkIbox className="overflow-auto">
                <IboxTitle>
                  <div className="d-flex justify-content-between">
                    <span>Ordens de serviço</span>

                    <button
                      className="btn btn-primary btn-outline"
                      onClick={this.toggleModal(MODALS.EXPORT_SCHEDULE)}
                    >
                      <i className="fa fa-floppy-o fa-fw"></i>
                      Exportar programação para PDF
                    </button>
                  </div>
                </IboxTitle>

                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Exibir apenas:</ControlLabel>
                      <div className="mb-10">
                        {
                          statuses.map(({
                            key,
                            text,
                            selected,
                          }) => (
                           <span className="mr-20">
                              <Checkbox
                                key={key}
                                checkboxClass="icheckbox_square-green"
                                increaseArea="20%"
                                label={`&emsp;${text}`}
                                checked={selected}
                                onClick={this.onSelectStatus(key)}
                              />
                           </span>
                          ))
                        }
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <ServiceOrdersTable
                  modals={MODALS}
                  serviceOrders={serviceOrders}
                  addMarker={this.addMarker}
                  openServiceOrder={this.openServiceOrder}
                />
              </DarkIbox>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <DarkIbox className="ibox-borderless">
                <AsyncSelect
                  loadingMessage={() => "Carregando..."}
                  placeholder="Procurar GPS..."
                  noOptionsMessage={() => "Nenhum resultado encontrado"}
                  className="custom-select"
                  loadOptions={this.loadOptions}
                  onChange={this.selectTrackable}
                  getOptionLabel={trackable => trackable.title}
                  getOptionValue={trackable => trackable.id}
                />

                <div className="rendered-markers">
                  {
                    markers.map(({ id, type, label }) => (
                      <div key={id} className="marker">
                        <img src={DISPLAY_ICONS[type]} />

                        <span>{label}</span>

                        <div className="icon-close">
                          <a onClick={this.removeMarker(id)}>
                            <i className="fa fa-close"></i>
                          </a>
                        </div>
                      </div>
                    ))
                  }
                </div>

                <MarkeredGoogleMaps
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleMapsKey}&libraries=geometry,drawing,places`}
                  loadingElement={<div className="loading-google-maps" />}
                  containerElement={<div className="container-google-maps" />}
                  mapElement={<div className="map-google-maps" />}
                  markers={markers}
                  saveMapRef={this.saveMapRef}
                  savefitBounds={this.savefitBounds}
                />
              </DarkIbox>
            </Col>
          </Row>
        </Grid>

        <ExportScheduleModal
          date={date}
          show={openedModal === MODALS.EXPORT_SCHEDULE}
          onHide={this.toggleModal(MODALS.EXPORT_SCHEDULE)}
        >
        </ExportScheduleModal>

        <ServiceOrderDetails
          show={openedModal === MODALS.SERVICE_ORDER_DETAILS}
          onHide={this.toggleModal(MODALS.SERVICE_ORDER_DETAILS)}
          serviceOrder={serviceOrder}
        />

        <EndServiceOrderModal
          dashboard
          show={openedModal === MODALS.END_SERVICE_ORDER_MODAL}
          onHide={this.toggleModal(MODALS.END_SERVICE_ORDER_MODAL)}
          serviceOrder={serviceOrder}
          userAdmin={user_admin}
          permissions={permissions}
        />
      </div>
    );
  }
}

export default Dashboard;
