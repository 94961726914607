import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class PaymentsTable extends React.Component {

  paymentStatusClassMapper = {
    "Pago": "payment-paid",
    "Atrasado": "payment-overdue",
    "Pendente": "payment-pending",
    "Não criado": "payment-none",
  }

  rowClassNameFormat = row => this.paymentStatusClassMapper[row.status]

  priceFormatter = price => price ? `R$ ${parseFloat(price).toFixed(2).replace(".", ",")}` : ""

  renderTooltip = tooltip => <Tooltip id={tooltip}>{tooltip}</Tooltip>

  renderActions = (_, { id, noReceipts, paymentMethodType }) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {noReceipts && paymentMethodType === "bank_slip" && (
          <OverlayTrigger
            overlay={this.renderTooltip("Emissão / 2ª via Boleto")}
            placement="right"
          >
            <a href={`/download_payment/${id}`}>
              <i className="fa fa-2x fa-file-pdf-o" />
            </a>
          </OverlayTrigger>
        )}

        {noReceipts && (
          <OverlayTrigger
            overlay={this.renderTooltip("Remover pagamento")}
            placement="right"
          >
            <a href={`/payments/${id}`} data-method="delete">
              <i className="fa fa-2x fa-trash" />
            </a>
          </OverlayTrigger>
        )}
      </div>
    );
  }
  render() {
    return this.props.payments && (
      <BootstrapTable
        data={this.props.payments}
        trClassName={this.rowClassNameFormat}
      >
        <TableHeaderColumn dataField="number" dataSort>
          N. Documento
        </TableHeaderColumn>

        <TableHeaderColumn dataField="creationDate" dataSort>
          Dt. Emissão
        </TableHeaderColumn>

        <TableHeaderColumn dataField="paymentDue" dataSort>
          Dt. Vencimento
        </TableHeaderColumn>

        <TableHeaderColumn dataField="parcel" width="68px" dataSort>
          Parcela
        </TableHeaderColumn>

        <TableHeaderColumn dataField="originalValue" dataFormat={this.priceFormatter} dataSort>
          Valor Original
        </TableHeaderColumn>

        <TableHeaderColumn dataField="paidValue" dataFormat={this.priceFormatter} dataSort>
          Valor Pago
        </TableHeaderColumn>

        <TableHeaderColumn dataField="paymentDate" dataSort>
          Última Dt. Pgto
        </TableHeaderColumn>

        <TableHeaderColumn dataField="paymentMethod" dataSort>
          Forma de Pgto
        </TableHeaderColumn>

        <TableHeaderColumn isKey dataField="id" dataFormat={this.renderActions}>
          Ações
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }
}

export default PaymentsTable;
