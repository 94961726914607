import React from 'react';
import classNames from 'classnames';

const DarkIbox = ({
  fixedHeight,
  minHeight,
  className = '',
  ...props
}) => {
  return (
    <div className="ibox">
      <div className={classNames(
        'ibox-content dark-ibox',
        className,
        {
          'fixed-height': fixedHeight,
          'min-height-135': minHeight,
        }
      )}>
        {props.children}
      </div>
    </div>
  );
}

export default DarkIbox;
