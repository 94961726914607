import React, { Component } from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import {
  Grid,
  Col,
  ControlLabel,
} from "react-bootstrap";
import Select from 'react-select';

import TableHeader from './TableHeader';
import TableBody from './TableBody';
import UnassignedOrdersList from './UnassignedOrdersList';
import { insertAndReorder } from 'utils';
import { dropdownPlaceholder } from '../../commons/strings';
import { cell_colors } from "./TableCell";
import LegendItem from "./LegendItem";

class DailySchedule extends Component {
  allLabel = "Exibindo todos os equipamentos"

  constructor(props) {
    super(props);

    const {
      equipments,
      service_orders,
      unassigned_orders,
      timeslots,
      selected_date,
      types_details,
    } = this.props;

    this.state = {
      types_details,
      equipments,
      unassigned_orders,
      service_orders,
      timeslots,
      selected_date,
      selected_types_details: [],
      deleted_timeslots: [],
    };
  }

  componentDidMount() {
    // Collapse NavBar
    $('.navbar-minimalize').click();

    // Set Unassigned Orders Table Height
    $('.uo-table > tbody').css({
      height: $('.so-table > tbody').height()
    });

    // Update Database
    $('.save-changes').click(this.saveChanges);
  }

  componentDidUpdate() {
    // Set Unassigned Orders Tables Height
    $('.uo-table > tbody').css(
      "height",
      index => $(`.so-table-${index} > tbody`).height()
    );
  }

  confirmServiceOrder = (serviceOrderCode) => {
    const updatedServiceOrders = this.state.service_orders.map((cur) => ({
      ...cur,
      is_confirmed: cur.is_confirmed || cur.code === serviceOrderCode,
    }));

    this.setState({
      service_orders: updatedServiceOrders,
    });
  }

  saveChanges = () => {
    const { service_orders, unassigned_orders, timeslots, selected_date, deleted_timeslots } = this.state;

    $.ajax({
      headers: {
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      url: "/daily_schedule/update",
      type: 'POST',
      data: {"data": JSON.stringify({ service_orders, unassigned_orders, timeslots, selected_date, deleted_timeslots })},
      dataType: 'json',
      success: ({ errors }) => {
        const message = errors.length
          ? `A programação diária foi atualizada, porém ocorreram os seguintes errors: ${errors.join(',')}`
          : "A programação diária foi atualizada";
        const alertClass = !errors.length ? 'success' : 'error';
        toastr[alertClass](message);
      },
      error: () => {
        toastr.error("Problema na conexão com o servidor. Tente novamente mais tarde", "Erro");
      }
    });
  }

  onMoveCell = ({ service_order, time, equipment }) => {
    // service_order == moved_cell; time, equipment == timeTarget and equipmentTarget
    const { service_orders, unassigned_orders } = this.state;

    let new_service_orders = [...service_orders];
    let new_unassigned_orders = [...unassigned_orders];

    // If dropped on unassigned orders list add dropped SO to USO list
    if (time === 'unassigned_so'){
      // If the dropped SO was assigned to an equipment removes it from the SO list
      if (service_order.equipment_id){
        const so_index = service_orders.indexOf(service_order);
        new_service_orders.splice(so_index, 1);
      }
      const uo_index = unassigned_orders.indexOf(service_order);
      // Drop SO on the selected equipment of unassigned list if it's empty
      const suggested_eq_id = (
        (!equipment ||
        unassigned_orders.some(uo => uo.suggested_eq_id === equipment.id && uo !== service_order))
          ? null
          : equipment.id
      );
      if (uo_index === -1){
        new_unassigned_orders.push({
          ...service_order,
          equipment_id: null,
          eq_code: null,
          suggested_eq_id
        });
      }
      else {
        new_unassigned_orders[uo_index].suggested_eq_id = suggested_eq_id;
      }
    }
    else {
      if (service_order.equipment_id){
        const so_index = service_orders.indexOf(service_order);
        new_service_orders.splice(so_index, 1);
      }
      else {
        const uo_index = unassigned_orders.indexOf(service_order);
        new_unassigned_orders.splice(uo_index, 1);
      }

      new_service_orders = insertAndReorder(
        new_service_orders,
        {...service_order, start_time: time, equipment_id: equipment.id, eq_code: equipment.code}
      );
    }

    this.setState({ service_orders: new_service_orders, unassigned_orders: new_unassigned_orders });
  }

  onTimeslotClick = ({ key, item }) => {
    // key == timeslot duration
    const { timeslot, time, equipment } = item.props; // time, equipment == timeTarget and equipmentTarget
    const { timeslots, deleted_timeslots } = this.state;

    let new_timeslots = [...timeslots];
    let new_deleted_timeslots;

    const removeTimeslot = (ts, ts_list) => ts_list.splice(ts_list.indexOf(ts), 1);

    if (key === 'Del'){
      removeTimeslot(timeslot, new_timeslots);

      if(timeslot.id) new_deleted_timeslots = [...deleted_timeslots, timeslot.id];
    }
    else {
      if (timeslot) removeTimeslot(timeslot, new_timeslots);

      new_timeslots = insertAndReorder(new_timeslots, {
        ...timeslot,
        start_time: time,
        equipment_id: equipment.id,
        eq_code: equipment.code,
        equipment_type_detail: equipment.equipment_type_detail,
        duration: parseInt(key)
      });
    }

    this.setState({ timeslots: new_timeslots, deleted_timeslots: new_deleted_timeslots || deleted_timeslots });
  }

  handleUnallocatedOrders = unallocatedOrders => {
    if (unallocatedOrders.length > 0) {
      const { service_orders, unassigned_orders } = this.state;
      let new_unassigned_orders = [...unassigned_orders];

      const new_service_orders = unallocatedOrders.reduce((service_orders_reduced, unallocatedOrder) => {
        new_unassigned_orders.push({
          ...unallocatedOrder,
          equipment_id: null,
          eq_code: null,
        });
        return service_orders_reduced.filter(({ id }) => id !== unallocatedOrder.id);
      }, service_orders);

      this.setState({ service_orders: new_service_orders, unassigned_orders: new_unassigned_orders });
    }
  }

  filterByTypeDetail = (array, type_detail) => {
    if (type_detail === this.allLabel) return array;

    return array.filter(elem => elem.equipment_type_detail === type_detail);
  }

  handleSelect = key => value => this.setState({ [key]: value })

  render() {
    const {
      equipments,
      service_orders,
      unassigned_orders,
      timeslots,
      types_details,
      selected_types_details,
    } = this.state;

    return (
      <div>
        <Grid
          fluid
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: 30,
            marginTop: 10,
          }}
        >
          <Col componentClass={ControlLabel} xs={2} className="text-right">
            Tipo e detalhe de equipamento:
          </Col>
          <Col xs={10}>
            <Select
              placeholder={dropdownPlaceholder}
              value={selected_types_details}
              onChange={this.handleSelect("selected_types_details")}
              isMulti
              options={[this.allLabel, ...types_details].map(value => ({ value, label: value }))}
            />
          </Col>
        </Grid>

        {!selected_types_details.length && (
          <h3 style={{ textAlign: "center", margin: 100 }}>
            Selecione o tipo e detalhe de equipamento
          </h3>
        )}

        {selected_types_details.map(({ value: equipment_type_detail }, index) => {
          const filteredEquipments = this.filterByTypeDetail(equipments, equipment_type_detail);
          const filteredOrders = this.filterByTypeDetail(service_orders, equipment_type_detail);
          const filteredTimeslots = this.filterByTypeDetail(timeslots, equipment_type_detail);

          return (
            <div key={equipment_type_detail}>
              <h2
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                {equipment_type_detail}
              </h2>

              <table
                className={`table so-table so-table-${index} daily-schedule-table table-striped table-bordered`}
                style={{ tableLayout: "fixed", width: "85%", float: "left", marginBottom: 30 }}
              >
                <TableHeader
                  start={0}
                  end={24}
                  step={30}
                />
                <TableBody
                  equipments={filteredEquipments}
                  service_orders={filteredOrders}
                  timeslots={filteredTimeslots}
                  onMoveCell={cells_props => this.onMoveCell(cells_props)}
                  onTimeslotClick={props => this.onTimeslotClick(props)}
                  handleUnallocatedOrders={this.handleUnallocatedOrders}
                  start={0}
                  end={24}
                  step={30}
                  saveChanges={this.saveChanges}
                  confirmServiceOrder={this.confirmServiceOrder}
                />
              </table>

              <UnassignedOrdersList
                soTableClass={`so-table-${index}`}
                equipments={filteredEquipments}
                service_orders={filteredOrders}
                timeslots={filteredTimeslots}
                unassigned_orders={this.filterByTypeDetail(unassigned_orders, equipment_type_detail)}
                onMoveCell={cells_props => this.onMoveCell(cells_props)}
                tableClass="table uo-table daily-schedule-table table-striped table-bordered"
              />

              <div className="row">
                <div className="col-md-12">
                <LegendItem
                    borderColor="#1EA131"
                    description="Ordem de serviço confirmada (não é possível editar)"
                  />

                  <LegendItem
                    color={cell_colors.started}
                    description="Ordem de serviço já iniciada no aplicativo (não é possível editar)"
                  />

                  <LegendItem
                    color={cell_colors.recent}
                    description="Ordem de serviço recente"
                  />

                  <LegendItem
                    color={cell_colors.approved}
                    description="Ordem de serviço aprovada pela empresa parceira"
                  />

                  <LegendItem
                    color={cell_colors.accepted}
                    description="Ordem de serviço aceita pelo cliente"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

// Set up drag and drop context
export default DragDropContext(HTML5Backend)(DailySchedule);
