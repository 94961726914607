import React from "react";
import NumberFormat from 'react-number-format';
import { FormControl } from 'react-bootstrap';
import { DECIMAL_PRECISION } from '../../utils/consts';

class InputFile extends React.Component {

  render() {
    const {
      onChange,
      value,
      className,
      ...props
    } = this.props;

    return (
      <NumberFormat
        className={className}
        customInput={FormControl}
        value={parseFloat(value)}
        thousandSeparator="."
        decimalSeparator=","
        allowedDecimalSeparators={[","]}
        decimalScale={DECIMAL_PRECISION}
        fixedDecimalScale
        prefix="R$ "
        onValueChange={({ value }) => {
          if (onChange) {
            onChange({ target: { value }});
          }
         }}
        {...props}
      />
    );
  }
};

export default InputFile;
