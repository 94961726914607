import React from 'react';

const IboxTitle = ({
  ...props
}) => {
  return (
    <div className="dark-ibox-title">
      {props.children}
    </div>
  );
}

export default IboxTitle;
