import React, { Component } from 'react';
import {
  Modal,
  FormControl,
  Button,
  Col,
  Row,
  Grid,
  FormGroup,
  ControlLabel,
  Table,
} from 'react-bootstrap';
import Select from 'react-select';
import update from 'immutability-helper';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

Moment.locale('pt-BR');
momentLocalizer();

const INITIAL_STATE = {
  request: null,
  requestValue: null,
};

class SelectRequests extends Component {
  state = INITIAL_STATE;

  handleSelectRequest = (value) => {
    if (Array.isArray(value)) {
      this.setState({ requestValue: null });
    } else {
      this.setState({ requestValue: value });
    }
  };

  handleOpen = (request) => {
    this.setState({
      request,
      requestValue: request[`${this.props.type}_request`],
    });
  }

  handleClose = () => {
    this.setState(INITIAL_STATE);
  }

  handleConfirm = () => {
    const {
      request,
      requestValue,
    } = this.state;

    if (!requestValue) {
      toastr.warning(`É necessário selecionar um ${this.props.type === "equipment" ? "equipmamento" : "serviço"}`);
      return;
    }

    this.props.updateRequests(this.props.type,
      update(this.props.attributes, {
        [request.originalKey]: {
          type: { $set: requestValue.type },
          detail: { $set: requestValue.detail },
          [`${this.props.type}_request`]: { $set: requestValue },
          [`${this.props.type}_request_id`]: { $set: requestValue.id },
        }
      })
    );

    this.handleClose();
  }

  handleDeleteRequest = (key) => {
    this.props.updateRequests(this.props.type,
      update(this.props.attributes, {
        [key]: {
          _destroy: { $set: true }
        }
      })
    );
  }

  handleChangeRequest = (key, field) => ({ target: { value } }) => {
    this.props.updateRequests(this.props.type,
      update(this.props.attributes, {
        [key]: {
          [field]: { $set: value }
        }
      })
    );
  }

  groupAttributes = (attributes) => {
    return Object.keys(attributes).reduce((group, key) => {
      const attribute = { ...attributes[key], originalKey: key };
      const attributeType = group[attribute.type] === undefined
        ? [attribute]
        : [...group[attribute.type], attribute]

      return {
        ...group,
        [attribute.type]: attributeType
      };
    }, {});
  };

  /**
   * A user can update a request order, but it has to match the previous
   * type and detail.
   *
   * @param {*} options Request options
   * @param {*} request Current request order
   */
  filterOptions = (options, request) => {
    if (!request) {
      return [];
    }

    const requestType = this.props.type;

    return options.filter((option) => {
      const {
        [`${requestType}_detail_id`]: detail_id,
        [`${requestType}_type_id`]: type_id,
      } = option;

      const {
        [`${requestType}_detail_id`]: target_detail_id,
        [`${requestType}_type_id`]: target_type_id,
      } = request[`${requestType}_request`];

      const matchDetail = target_detail_id === detail_id;
      const matchType = target_type_id === type_id;

      return matchDetail && matchType;
    })
  }

  renderRequest = (index, key, request) => {
    const { isBlocked } = this.props;
    const hasId = !!request.id;
    const isDisabled = isBlocked && hasId;

    return !request["_destroy"] && (
      <tr key={key}>
        <td className="center-v center-h">
          {index}
        </td>
        <td className="center-v">
          {request.type}
        </td>
        <td className="center-v">
          {request.detail}
        </td>
        <td className="center-v">
          <FormControl
            type="number"
            min={0}
            step={0.01}
            value={request.quantity}
            onChange={this.handleChangeRequest(request.originalKey, "quantity")}
            disabled={isDisabled}
          />
        </td>
        <td className="center-v">
          <FormControl
            value={request.comments}
            onChange={this.handleChangeRequest(request.originalKey, "comments")}
            disabled={isDisabled}
          />
        </td>
        <td className="center-v center-h">
          {
            isDisabled
              ? (
                <i className="material-icons clickable" onClick={() => this.handleOpen(request)}>
                  edit
                </i>
              )
              : (
                <i className="material-icons clickable" onClick={() => this.handleDeleteRequest(request.originalKey)}>
                  delete
                </i>
              )
          }
        </td>
      </tr>
    );
  }

  render = () => {
    const {
      request,
      requestValue,
    } = this.state;
    const showModal = !!request;

    const groupedAttibutes = this.groupAttributes(this.props.attributes);
    let counter = 1;

    return (
      <div>
        {Object.keys(groupedAttibutes).sort().map(type => {
          return (
            <div key={type}>
              <h4 className="text-navy attribute-type">{type}</h4>
              <Table responsive style={{ marginBottom: 0 }} striped>
                <thead>
                  <tr>
                    <th className="center-h">#</th>
                    <th className="col-md-3">Tipo</th>
                    <th className="col-md-3">Detalhe</th>
                    <th className="col-md-1">Quantidade</th>
                    <th colSpan={2} className="col-md-4">Observações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(groupedAttibutes[type])
                      .map((key) => this.renderRequest(counter++, key, groupedAttibutes[type][key]))
                  }
                </tbody>
              </Table>
            </div>
          );
        })}

        <Modal
          className="horizontal-centered"
          show={showModal}
          onHide={this.handleClose}
          backdrop={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-navy">
              Editar {this.props.type === "equipment" ? "equipmantos" : "serviços"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <ControlLabel>Selecione uma opção</ControlLabel>
                    <Select
                      value={requestValue}
                      placeholder="Selecione..."
                      onChange={this.handleSelectRequest}
                      options={this.filterOptions(this.props[`${this.props.type}_requests`], request)}
                      getOptionLabel={(r) => `${r.type} ${r.detail} | ${r.comments}`}
                      getOptionValue={(r) => r.id}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={this.handleClose} className="btn-outline">Cancelar</Button>
            <Button bsStyle="primary" onClick={this.handleConfirm} className="btn-outline">Confirmar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SelectRequests;
