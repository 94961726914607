import React from 'react';

const equipment_colors = {
  normal: '#BCE0A3',
  warning: '#FCFB91',
  danger: '#FAA2A2'
};

export default ({ equipment }) => {
  return (
    <td style={{ backgroundColor: equipment_colors[equipment.status] }} >
      <div className="cell-equipment-container">
        <div style={{ fontSize: '1.45rem', fontWeight: 900 }}>{`${equipment.code}`}</div>
        <div style={{ fontSize: '1.1rem', fontWeight: 600 }}>{`${equipment.description}`}</div>
      </div>
    </td>
  )
}
