import React, { Component } from 'react';

import { onCheckOverlap, checkEquipment } from 'utils';
import TableCell from './TableCell';
import EquipmentCell from './EquipmentCell';
import TimeslotCell from './TimeslotCell';
import TimeslotMenu from './TimeslotMenu';

const equipment_colors = {
  normal: '#BCE0A3',
  warning: '#FCFB91',
  danger: '#FAA2A2'
};

class TableBody extends Component {
  state = {
    openedCode: null
  };

  unallocatedOrders = []

  componentDidMount() {
    this.props.handleUnallocatedOrders(this.unallocatedOrders);
  }

  componentDidUpdate() {
    this.props.handleUnallocatedOrders(this.unallocatedOrders);
  }

  openPopover = (code) => () => {
    if (code === this.state.openedCode) {
      this.setState({ openedCode: null });
    } else {
      this.setState({ openedCode: code });
    }
  };

  buildTimeArray = ({ start, end, step }) => {
    let time = new Date();
    time.setHours(start);
    time.setMinutes(0);
    const numCells = (end - start)*(60/step);

    return [...new Array(numCells)].map((_, index) => {
      if(index > 0){
        time.setMinutes(time.getMinutes() + step);
      }

      return `${time.getHours().toString().length === 2 ? time.getHours() : '0' + time.getHours()}:` +
        `${time.getMinutes().toString().length === 2 ? time.getMinutes() : '0' + time.getMinutes()}`;
    });
  }

  checkNextCell = (next_so, next_ts, nextTime) => (
    (next_so && next_so.start_time === nextTime) || (next_ts && next_ts.start_time === nextTime)
  )

  findNextSODistance = (remainingServiceOrders, equipmentId, nextTime) => {
    let nextSODistance = 0;
    remainingServiceOrders.find(({ equipment_id, start_time }) => {
      nextSODistance++;
      return (
        (equipment_id === equipmentId && start_time >= nextTime) ||
        (equipment_id !== equipmentId)
      );
    });
    return nextSODistance || 1;
  }

  Rows = () => {
    const { equipments, service_orders, timeslots, onMoveCell, onTimeslotClick, start, end, step } = this.props;
    const timeArray = this.buildTimeArray({ start, end, step });
    let so_index, ts_index, so_cells_left, ts_cells_left, next_so, next_ts;
    so_index = ts_index = 0;
    this.unallocatedOrders = [...service_orders];

    return (
      <tbody>
      {equipments.map((equipment, e_index) => {
        if(so_cells_left > 0) {
          this.unallocatedOrders = this.unallocatedOrders.filter(({ id }) => id !== service_orders[so_index].id);
          so_index++;
        }

        so_cells_left = ts_cells_left = 0;
        next_so = checkEquipment(service_orders[so_index], equipment);
        next_ts = checkEquipment(timeslots[ts_index], equipment);

        return (
          <tr key={e_index}>
            <EquipmentCell equipment={equipment} />

            {timeArray.map((time, t_index) => {
              // When timeslot === 1h one cell must be skipped, because of the use of colSpan === 2
              if(ts_cells_left === 1) {
                ts_cells_left = 0;
              }
              else if(so_cells_left > 0 || (next_so && next_so.start_time === time)) {
                const service_order = next_so;

                if(so_cells_left === 0){
                  so_cells_left = service_order.duration/step - 1;
                }
                else {
                  so_cells_left--;
                }

                if(so_cells_left === 0) {
                  this.unallocatedOrders = this.unallocatedOrders.filter(({ id }) => id !== service_orders[so_index].id);

                  const next_so_distance = this.findNextSODistance(
                    service_orders.slice(so_index + 1),
                    equipment.id,
                    timeArray[t_index + 1]
                  );
                  so_index = so_index + next_so_distance;
                  next_so = checkEquipment(service_orders[so_index], equipment);
                }

                return (
                  <TableCell
                    key={t_index}
                    time={time}
                    equipment={equipment}
                    so_cells_left={so_cells_left}
                    service_order={service_order}
                    onMoveCell={cells_props => onMoveCell(cells_props)}
                    onCheckOverlap={props => onCheckOverlap(timeslots, service_orders, props)}
                    openPopover={this.openPopover}
                    openedCode={this.state.openedCode}
                    saveChanges={this.props.saveChanges}
                    confirmServiceOrder={this.props.confirmServiceOrder}
                  />
                )
              }
              else if(next_ts && next_ts.start_time === time) {
                const timeslot = next_ts;

                ts_cells_left = timeslot.duration === 60 ? 1 : 0;
                ts_index++;
                next_ts = checkEquipment(timeslots[ts_index], equipment);

                return (
                  <TimeslotCell key={t_index} ts_cells_left={ts_cells_left} >
                    <TimeslotMenu
                      time={time}
                      timeslot={timeslot}
                      content_on_next_cell={this.checkNextCell(next_so, next_ts, timeArray[t_index + 1])}
                      equipment={equipment}
                      onTimeslotClick={onTimeslotClick}
                    />
                  </TimeslotCell>
                )
              }
              else {
                return (
                  <TableCell
                    key={t_index}
                    isEven={t_index % 2 === 0}
                    time={time}
                    equipment={equipment}
                    onCheckOverlap={props => onCheckOverlap(timeslots, service_orders, props)}
                    openPopover={this.openPopover}
                    openedCode={this.state.openedCode}
                    saveChanges={this.props.saveChanges}
                    confirmServiceOrder={this.props.confirmServiceOrder}
                  >
                    <TimeslotMenu
                      time={time}
                      content_on_next_cell={this.checkNextCell(next_so, next_ts, timeArray[t_index + 1])}
                      equipment={equipment}
                      onTimeslotClick={onTimeslotClick}
                    />
                  </TableCell>
                );
              }
            })}
          </tr>
        )
      })}
      </tbody>
    );
  }

  render() {
    const { Rows } = this;
    return <Rows />;
  }
}

export default TableBody;
