import React, { Component } from 'react';
import {
  Grid, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup
} from 'react-bootstrap';
import PropTypes from "prop-types";

class CompanyRatio extends Component {

  componentDidMount = () => {
    const { billingItems } = this.props;

    Object.keys(billingItems).map((key, index) => {
      this.calculateApportionments({ companyId: key, newPercent: billingItems[key].percent });
    });
  }

  calculatePrice = percent => this.props.billingPrice * percent / 100

  calculateApportionments = ({ companyId, newPercent }) => {
    const {
      billingItems,
      companies,
    } = this.props;
    const billingItem = billingItems[companyId];
    const newPrice = this.calculatePrice(newPercent);
    let newBillingItems = {
      ...billingItems,
      [companyId]: {
        ...billingItem,
        percent: newPercent,
        totalValue: newPrice,
      }
    };
    const lastCompany = companies[companies.length - 1];

    delete newBillingItems[lastCompany.id];

    const totalPercent = Object.values(newBillingItems).reduce(
      (acc, { percent }) => acc + (percent || 0),
      0
    );
    const lastCompanyNewPercent = this.saturateRatio(100 - totalPercent);

    if (totalPercent > 100) {
      newBillingItems = billingItems;
    } else {
      newBillingItems[lastCompany.id] = {
        ...billingItems[lastCompany.id],
        percent: lastCompanyNewPercent,
        totalValue: this.calculatePrice(lastCompanyNewPercent),
      }
    }

    this.props.updateBillingItems(newBillingItems);
  }

  saturateRatio = percent => (
    (!percent || percent < 0)
      ? 0
      : percent > 100
        ? 100
        : percent
  );

  companyRatioChanged = companyId => ({ target: { value } }) => {
    this.calculateApportionments({
      companyId,
      newPercent: this.saturateRatio(parseInt(value)),
    });
  }

  render() {
    const {
      companies,
      billingItems,
    } = this.props;
    return (
      <Grid fluid>
        {companies.map(({ id, nickname }, index) => {
          const billingItem = billingItems[id] || {};

          return (
            <Col xs={6} key={id} style={{ height: 85, marginBottom: (index >= companies.length - 2) ? 0 : 20 }}>
              <Row>
                <Col componentClass={ControlLabel} sm={5}>
                  % {nickname}:
                </Col>

                <Col xs={3} className='form-inline' style={{ marginBottom: 10 }}>
                  <FormGroup bsSize='small'>
                    <InputGroup>
                      <FormControl
                        type='text'
                        style={{ width: 80 }}
                        value={billingItem.percent}
                        onChange={this.companyRatioChanged(id)}
                        readOnly={companies.length === index + 1}
                      />

                      <InputGroup.Addon style={{ minWidth: 43 }}>%</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col componentClass={ControlLabel} sm={5}>
                  Valor Bruto:
                </Col>

                <Col xs={3} className='form-inline'>
                  <FormGroup controlId="percent" bsSize='small'>
                    <InputGroup>
                      <FormControl
                        type='text'
                        style={{ width: 80 }}
                        value={billingItem.totalValue}
                        readOnly={true}
                      />

                      <InputGroup.Addon style={{ minWidth: 43 }}>R$</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Grid>
    );
  }
}

CompanyRatio.propTypes = {
  billingPrice: PropTypes.number.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  billingItems: PropTypes.object,
};

export default CompanyRatio;
