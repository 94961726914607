import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import allowedParams from './allowedParams';
import ServiceOrderForm from './ServiceOrderForm';
import ClientContractInfo from './partials/ClientContractInfo';


class ServiceOrderUpdate extends Component {
  onSave = (formData) => {
    const { serviceOrder } = this.props;

    axios.patch(`/service_orders/${serviceOrder.id}.json`, formData)
      .then(({ data }) => {
        if (data.success) {
          window.location = "/service_orders";
        } else {
          window.hide_loader();
          if (data.errors.length) {
            data.errors.forEach(error => toastr.warning(error, "Falha ao salvar OS"));
          } else {
            toastr.warning("Por favor, tente novamente", "Falha ao salvar OS")
          }
        }
      })
      .catch(error => {
        window.hide_loader();
        toastr.error(error, "Erro interno");
      });
  }

  onCancel = () => {
    window.location = "/service_orders";
  }

  render = () => {
    const {
      contract,
      currentUser,
      serviceOrder,
      partners,
      additional_service_requests,
      equipment_requests,
      allowRecurrence,
    } = this.props;

    const {
      equipment_request_orders_attributes,
      additional_service_request_orders_attributes,
    } = serviceOrder;

    const equipments = { ...equipment_request_orders_attributes }
    const additional_services = { ...additional_service_request_orders_attributes }

    const initialValues = {
      ..._.pick(serviceOrder, allowedParams),
      equipment_request_orders_attributes: equipments,
      additional_service_request_orders_attributes: additional_services
    }

    return (
      <div>
        <ClientContractInfo
          title="Edição de Ordem de Serviço"
          contract={contract}
          currentUser={currentUser}
          serviceOrder={serviceOrder}
        />
        <ServiceOrderForm
          serviceOrderId={serviceOrder.id}
          initialValues={{ ..._.pickBy(initialValues, _.identity), use_date: serviceOrder.use_date }}
          partners={partners}
          onSave={this.onSave}
          onCancel={this.onCancel}
          additional_service_requests={additional_service_requests}
          equipment_requests={equipment_requests}
          allowRecurrence={allowRecurrence}
          isBlocked={serviceOrder.is_blocked}
          updating
        />
      </div>
    );
  }
}

export default ServiceOrderUpdate;
