import React from 'react';

import IboxTitle from "../../../commons/IboxTitle";
import DarkIbox from "../../../commons/DarkIbox";
import InfoCard from "../../../commons/InfoCard";

const ActiveItemBox = ({
  title,
  active,
  inactive,
}) => {
  return (
    <DarkIbox>
      <IboxTitle>
        {title}
      </IboxTitle>
      <InfoCard>
        <span className="text-big">
          Ativos
        </span>
        <span className="text-big">
          {active}
        </span>
      </InfoCard>
      <InfoCard>
        <span className="text-big">
          Ociosos
        </span>
        <span className="text-big">
          {inactive}
        </span>
      </InfoCard>
    </DarkIbox>
  );
}

export default ActiveItemBox;
