import React from 'react';

const icon = {
  normal: "fa-check-circle",
  warning: "fa-exclamation-circle",
  danger: "fa-times-circle",
}

const StatusIcon = ({ type }) => {
  return (
    <i className={`fa ${icon[type]} fa-2x icon-${type}`}></i>
  );
}

export default StatusIcon;
