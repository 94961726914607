import React from 'react';
import EquipmentChecklist from './EquipmentChecklist';

import IboxTitle from "../../../commons/IboxTitle";
import InfoCard from "../../../commons/InfoCard";
import DarkIbox from "../../../commons/DarkIbox";

const EquipmentsList = ({
  equipments,
  checklistsCount: {
    done,
    remaining,
  }
}) => {
  return (
    <DarkIbox fixedHeight>
      <IboxTitle>
        <div className="d-flex justify-content-between">
          <span>Checklists realizados</span>
          <span>
            <span>{done}</span>
            <span className="checklists-remaining">/ {remaining}</span>
          </span>
        </div>
      </IboxTitle>
      <div className="scroll-view">
        <InfoCard border={status} >
          <span className="text-normal text-bold w-25-p">
            Código
          </span>
          <span className="text-normal text-bold w-25-p">
            Tipo
          </span>
          <span className="text-normal text-bold w-25-p">
            Detalhe
          </span>
          <span className="text-normal text-bold w-25-p">
            Realizado por
          </span>
          <span className="text-normal">
            Status
          </span>
        </InfoCard>
        {
          equipments.map(({
            id,
            code,
            type,
            detail,
            operator,
            status,
            has_checklist,
          }) => (
            <EquipmentChecklist
              key={id}
              id={id}
              status={status}
              code={code}
              type={type}
              detail={detail}
              operator={operator}
              has_checklist={has_checklist}
            />
          ))
        }
      </div>
    </DarkIbox>
  );
}

export default EquipmentsList;
