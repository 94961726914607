import React from 'react';
import Menu, { SubMenu, MenuItem } from 'rc-menu';

export default ({ time, timeslot, content_on_next_cell, equipment, onTimeslotClick }) => {
  const isOneHour = timeslot && timeslot.duration === 60;

  return (
    <Menu
      mode='horizontal'
      openAnimation='slide-up'
      openSubMenuOnMouseEnter={false}
      closeSubMenuOnMouseLeave={true}
      onClick={props => onTimeslotClick(props)}
    >
      <SubMenu
        key="1"
        title={
          <span>
            {timeslot
              ? isOneHour
                ? '01:00'
                : `${timeslot.duration}'`
              : ''
            }
          </span>
        }
      >
        <MenuItem
          key={timeslot ? "Del" : "30"}
          time={time}
          equipment={equipment}
          timeslot={timeslot}
        >
          {timeslot ? "Del" : "30'"}
        </MenuItem>
        {content_on_next_cell
          ? null
          : <MenuItem
              key={timeslot
                    ? isOneHour
                      ? "30"
                      : "60"
                    : "60"
              }
              time={time}
              equipment={equipment}
              timeslot={timeslot}
            >
              {timeslot
                ? isOneHour
                  ? "30'"
                  : "01:00"
                : "01:00"}
            </MenuItem>
        }
      </SubMenu>
    </Menu>
  );
};
