import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';

const ContractInfo = ({ clientCode, clientName, contractCode, contractName,
                        seller, creditLimit, paymentInfo, partnerPaymentInfo }) => {

  return (
    <Row>
      <Col xs={6}>
        <Table>
          <tbody>
            <tr>
              <th>Cliente:</th>
              <td>{clientCode}</td>
              <td>{clientName}</td>
            </tr>

            <tr>
              <th>Contrato:</th>
              <td>{contractCode}</td>
              <td>{contractName}</td>
            </tr>

            <tr>
              <th>Vendedor:</th>
              <td>{seller}</td>
              <td></td>
            </tr>

            <tr>
              <th>Limite de crédito:</th>
              <td>{creditLimit}</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Col>

      <Col xs={6}>
        <Table>
          <tbody>
            <tr>
              <th></th>
              <th>Empresa</th>
              <th>Empresa Parceira</th>
            </tr>

            <tr>
              <th>Forma de Pagamento:</th>
              <td>{paymentInfo.paymentMethod && paymentInfo.paymentMethod.name}</td>
              <td>{partnerPaymentInfo.paymentMethod}</td>
            </tr>

            <tr>
              <th>Prazo de Pagamento</th>
              <td>{paymentInfo.paymentTime}</td>
              <td>{partnerPaymentInfo.paymentTime}</td>
            </tr>

            <tr>
              <th>Parcelas</th>
              <td>{paymentInfo.parcels}</td>
              <td>{partnerPaymentInfo.parcels}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ContractInfo;
