import React from 'react';
import axios from 'axios';
import {
  Table,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { currencyHumanized } from "../../utils/helpers";

const STATUS = {
  archived: "Arquivado",
  paid: "Pago",
  pending: "Pendente",
  canceled: "Cancelado",
  late: "Atrasado",
};

class InstallmentsTable extends React.Component {

  archiveInstallment = (id) => {
    const {
      refetchInstallments,
      handleUnexpectedError,
    } = this.props;

    const endpoint = `/installments/archive/${id}.json`;

    axios.post(endpoint)
      .then(({ data: { success, error } }) => {
        if (success) {
          toastr.success('Item arquivado com sucesso');

          refetchInstallments();
        } else {
          toastr.error(error, 'Não foi possível arquivar este item.');
        }
      })
      .catch(handleUnexpectedError);
  }

  cancelExpense = (expense_id) => {
    const {
      refetchInstallments,
      handleUnexpectedError,
    } = this.props;

    const endpoint = `/expenses/cancel/${expense_id}.json`;

    axios.post(endpoint)
      .then(({ data: { success } } ) => {
        if (success) {
          toastr.success('Desepsa cancelada com sucesso.');

          refetchInstallments();
        } else {
          toastr.error('Não foi possível cancelar esta despesa.');
        }
      })
      .catch(handleUnexpectedError);

  }

  onArchive = ({ id }) => () => {
    Swal.fire({
      title: 'Você quer arquivar esta parcela?',
      text: 'Esta alteração não pode ser desfeita.',
      type: 'warning',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(({ value: confirmed }) => {
      if (confirmed) {
        this.archiveInstallment(id);
      }
    });
  }

  onCancel = ({ expense_id }) => () => {
    Swal.fire({
      title:'Você quer cancelar esta despesa?',
      html: '<strong>Todos as parcelas serão afetadas.</strong><p>Esta alteração não pode ser desfeita.</p>',
      type: 'warning',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(({ value: confirmed }) => {
      if (confirmed) {
        this.cancelExpense(expense_id);
      }
    });
  }

  onEdit = ({ expense_id }) => () => {
    const {
      fetchExpense,
    } = this.props;

    fetchExpense(expense_id);
  }

  onDelete = ({ expense_id }) => () => {
    const {
      deleteExpense,
    } = this.props;

    Swal.fire({
      title:'Você quer excluir esta despesa?',
      html: '<strong>Todos as parcelas serão afetadas.</strong><p>Esta alteração não pode ser desfeita.</p>',
      type: 'warning',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(({ value: confirmed }) => {
      if (confirmed) {
        deleteExpense(expense_id);
      }
    });
  }

  onSetAsPaid = (installment) => () => {
    const {
      handleOpenInstallment,
    } = this.props;

    handleOpenInstallment(installment);
  }

  onView = (installment) => () => {
    const {
      handleOpenInstallment,
    } = this.props;

    handleOpenInstallment(installment);
  }

  onDownload = ({ id }) => () => {
    axios({
      url: `/installments/download/${id}.json`,
      method: "POST",
      responseType: "blob",
    })
      .then(({ data, headers }) => {
        const [_, filename] = headers['content-disposition'].split(";");

        const blob = new Blob(
          [data],
          { type: "application/octet-stream" }
        );

        saveAs(blob, filename.replace(/filename="(.*)"/, '$1'));
      })
      .catch(() => toastr.error('Não há anexos para download.'));
  }

  view = {
    divider: false,
    label: "Visualizar parcela",
    action: this.onView,
  }

  archive = {
    divider: false,
    label: "Arquivar parcela",
    action: this.onArchive,
  }

  setAsPaid = {
    divider: false,
    label: "Definir parcela como paga",
    action: this.onSetAsPaid,
  }

  cancel = {
    divider: false,
    label: "Cancelar",
    action: this.onCancel,
  }

  download = {
    divider: false,
    label: "Download",
    action: this.onDownload,
  }

  edit = {
    divider: false,
    label: "Editar",
    action: this.onEdit,
  }

  delete = {
    divider: false,
    label: "Excluir",
    action: this.onDelete,
  }

  divider = {
    divider: true,
  }

  getActions = () => {
    const {
      permissions,
      userAdmin,
    } = this.props;

    const canDelete = userAdmin || permissions.includes("delete_expense finances");

    const permittedActions = canDelete
      ? [this.delete]
      : [];

    return {
      archived: [],
      paid: [
        this.view,
        this.archive,
        this.divider,
        this.download,
        ...permittedActions,
      ],
      pending: [
        this.setAsPaid,
        this.divider,
        this.edit,
        this.download,
        this.cancel,
        ...permittedActions,
      ],
      canceled:  [
        this.archive,
        this.divider,
        this.download,
        ...permittedActions,
      ],
      late: [
        this.setAsPaid,
        this.divider,
        this.edit,
        this.download,
        this.cancel,
        ...permittedActions,
      ],
    };
  }

  render() {
    const {
      installments
    } = this.props;

    const statuses = this.getActions();

    return (
      <div className="mt-10">
        <Table responsive striped id="installments-table">
          <thead>
            <tr>
              <th className="col-md-3">
                Descrição
              </th>
              <th className="col-md-3">
                Fornecedor
              </th>
              <th className="col-md-2">
                Nota fiscal
              </th>
              <th className="col-md-1">
                Valor
              </th>
              <th className="col-md-1">
                Parcela
              </th>
              <th className="col-md-1">
                Data vencimento
              </th>
              <th className="col-md-1">
                Status pagamento
              </th>
            </tr>
          </thead>
          <tbody>
            {
              installments.map(installment => {
                const statusTitle = installment.archived ? `${STATUS[installment.status]} e arquivado` : STATUS[installment.status];

                return (
                  <tr key={installment.id}>
                    <td>{installment.description}</td>
                    <td>{installment.supplier}</td>
                    <td>{installment.nf_number}</td>
                    <td>{currencyHumanized(installment.value)}</td>
                    <td>{installment.number_parcel}</td>
                    <td>{installment.due_date}</td>
                    <td>
                    <DropdownButton
                      title={statusTitle}
                      key={`${installment.id}-actions`}
                      id={`${installment.id}-actions`}
                    >
                      {
                        statuses[installment.status].map((status, index) => (
                          status.divider
                            ? (
                                <MenuItem
                                  key={index}
                                  divider
                                />
                            )
                            : (
                                <MenuItem
                                  key={index}
                                  id={index}
                                  eventKey={index}
                                  onClick={status.action(installment)}
                                >
                                  {status.label}
                                </MenuItem>
                            )
                        ))
                      }
                    </DropdownButton>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

export default InstallmentsTable;
