import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Panel, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { dropdownPlaceholder, loadingLabel } from '../../commons/strings';

import BillingItemsTable from "./BillingItemsTable";

const { Heading, Body } = Panel;

class OrdersTable extends React.Component {

  paymentStatusClassMapper = {
    "Pago": "payment-paid",
    "Atrasado": "payment-overdue",
    "Pendente": "payment-pending",
    "Não criado": "payment-none",
  }

  state = {
    orders: this.props.orders,
    page: 1,
    sizePerPage: this.props.initialLimit,
    loadingItemId: null,
    dataTotalSize: this.props.dataTotalSize,
    refetchLoading: false,
  }

  componentWillReceiveProps({ changedFilter, ...nextProps }) {
    if (nextProps[changedFilter] !== this.props[changedFilter]) {
      this.setState(
        { page: 1 },
        () => this.fetchMore(nextProps)
      );
    }
  }

  remote = remoteObj => {
    remoteObj.pagination = true;
    return remoteObj;
  }

  rowClassNameFormat = row => this.paymentStatusClassMapper[row.paymentStatus]

  isExpandableRow = ({ billingItems }) => billingItems.length >= 1

  sendOrderEmails = orderId => _event => {
    this.setState(
      { loadingItemId: orderId },
      () => {
        axios.put(`/send_billing_emails/${orderId}`)
          .then(({ data: { message } }) => {
            this.setState(
              { loadingItemId: null },
              () => toastr.success(message)
            );
          })
          .catch(() => {
            this.setState(
              { loadingItemId: null },
              () => toastr.error("Erro ao enviar os emails aos clientes")
            );
          });
      }
    );
  }

  fetchMore = (filters = this.props) => {
    const { page, sizePerPage, } = this.state;
    const {
      selectedOS,
      selectedBM,
      selectedContract,
      selectedStatus,
      selectedCompany,
      selectedInitialDate,
      selectedFinalDate,
      selectedBillingNumber,
    } = filters;

    this.setState({ refetchLoading: true });

    axios.get(this.props.fetchUrl, {
      params: {
        page,
        sizePerPage,
        selectedOS,
        selectedBM,
        selectedContract,
        selectedStatus,
        selectedCompany,
        selectedInitialDate,
        selectedFinalDate,
        selectedBillingNumber,
      }
    })
      .then(({ data: { orders, dataTotalSize } }) => {
        this.setState({ orders, dataTotalSize, refetchLoading: false });
      })
      .catch(() => {
        this.setState({ refetchLoading: false });
        toastr.error("Erro ao obter dados", "Erro");
      });
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      { page, sizePerPage },
      this.fetchMore
    );
  }

  expandComponent = ({ billingItems }) => {
    const { selectedCompany } = this.state;

    return billingItems && (
      <div>
        <Body>
          <BillingItemsTable
            billingItems={
              selectedCompany && selectedCompany.company_name !== dropdownPlaceholder
                ? billingItems.filter(({ companyName }) => companyName === selectedCompany.company_name)
                : billingItems
            }
          />
        </Body>
      </div>
    );
  }

  expandColumnComponent = ({ isExpandableRow, isExpanded }) => isExpandableRow && (
    <div>
      {isExpanded ? "(-)" : "(+)"}
    </div>
  )

  renderTooltip = tooltip => <Tooltip id={tooltip}>{tooltip}</Tooltip>

  renderActions = (orderId, { billingItems }) => {
    if (orderId && this.state.loadingItemId === orderId) {
      return (
        <div className="sk-spinner-pulse sk-spinner" role="status" style={{ background: "#337ABA" }}>
          <span className="sr-only">{loadingLabel}</span>
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around"
        }}
      >
        {orderId && (
          <OverlayTrigger
            overlay={this.renderTooltip("Faturar ordem de serviço")}
            placement="right"
          >
            <a href={`/close_service_order/${orderId}`}>
              <i className="fa fa-2x fa-dollar" />
            </a>
          </OverlayTrigger>
        )}

        {orderId && billingItems.length > 0 && (
          <OverlayTrigger
            overlay={this.renderTooltip("Reenviar email com fatura")}
            placement="right"
          >
            <a href="javascript:void(0)" onClick={this.sendOrderEmails(orderId)}>
              <i className="fa fa-2x fa-envelope" />
            </a>
          </OverlayTrigger>
        )}
      </div>
    );
  }

  render() {
    const { refetchLoading } = this.state;

    return (
      <Panel>
        <Heading>{`Lista de ${this.props.type}`}</Heading>

        <Body>
          {refetchLoading && (
            <div className="sk-spinner-pulse sk-spinner" role="status" style={{ background: "#337ABA" }}>
              <span className="sr-only">{loadingLabel}</span>
            </div>
          )}
          {!refetchLoading && (
            <BootstrapTable
              search
              pagination
              remote={this.remote}
              data={this.state.orders}
              fetchInfo={{ dataTotalSize: this.state.dataTotalSize }}
              options={{
                expandRowBgColor: "white",
                sizePerPageList: [5, 10, 20],
                sizePerPage: this.state.sizePerPage,
                onPageChange: this.onPageChange,
                page: this.state.page,
              }}
              expandableRow={this.isExpandableRow}
              expandColumnOptions={{
                expandColumnVisible: true,
                expandColumnComponent: this.expandColumnComponent,
                columnWidth: 50,
              }}
              expandComponent={this.expandComponent}
              trClassName={this.rowClassNameFormat}
              expandColumnOptions={{ expandColumnVisible: true }}
            >
              <TableHeaderColumn isKey dataField="code" dataSort>
                {this.props.type}
              </TableHeaderColumn>

              <TableHeaderColumn dataField="contract" dataSort>
                Contrato
              </TableHeaderColumn>

              <TableHeaderColumn dataField="client" dataSort>
                Cliente
              </TableHeaderColumn>

              <TableHeaderColumn dataField="address" dataSort>
                Endereço
              </TableHeaderColumn>

              <TableHeaderColumn dataField="billingDate" dataSort>
                Data Faturamento
              </TableHeaderColumn>

              <TableHeaderColumn dataField="orderId" dataFormat={this.renderActions}>
                Ações
              </TableHeaderColumn>
            </BootstrapTable>
          )}
        </Body>
      </Panel>
    );
  }
}
export default OrdersTable;
