import React from "react";

import DarkIbox from "../../../../commons/DarkIbox";
import OperatorsList from "../../OperatorsList";

class ScannedInvoices extends React.Component {

  render() {
    const scannedInvoices = this.props.invoices || [];
    const team = this.props.team || [];
    const {
      onAddOperator,
      onRemoveOperator,
      userAdmin,
      permissions,
      isFinished,
      pdfLink,
    } = this.props;

    const canUpdate = userAdmin || (permissions || []).includes("update_operators service_orders");

    return (
      <DarkIbox>
        <div className="ibox-green-title">
          <h3>Notas fiscais escaneadas</h3>
        </div>

        <table className="table table-responsive table-dark" id="dashboard-datatable">
          <thead>
            <tr>
              <th>Número NF</th>
              <th>Chave de acesso</th>
              <th>Quantidade</th>
              <th>Leitura</th>
              <th>Observações</th>
            </tr>
          </thead>
          <tbody>
            {
              scannedInvoices.map(({
                id,
                key,
                number,
                quantity,
                scan_time,
                comments,
              }) => (
                <tr key={id}>
                  <td>{key}</td>
                  <td>{number}</td>
                  <td>{quantity}</td>
                  <td>{scan_time}</td>
                  <td>{comments}</td>
                </tr>
              ))
            }
          </tbody>
        </table>

        <div className="mt-10">
          <h4 className="mt-20 mb-20">Equipe utilizada</h4>

          <OperatorsList
            canUpdate={canUpdate && !isFinished}
            operators={team}
            onAddOperator={onAddOperator}
            onRemoveOperator={onRemoveOperator}
          />
        </div>

        <div className="mt-10">
          <h4 className="mt-20 mb-20">Arquivo PDF</h4>

          {
            pdfLink
              ? (
                <a
                  className="btn btn-primary"
                  href={pdfLink}
                  download
                >
                  Fazer download
                </a>
              )
              : (
                <span>Não há arquivo disponível para download</span>
              )
          }
        </div>
      </DarkIbox>
    );
  }
};

export default ScannedInvoices;
