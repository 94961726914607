import React from "react";

import DarkIbox from "../../../commons/DarkIbox";

class ServiceOrderChart extends React.Component {
  state = {
    recent: 0,
    accepted: 0,
    rejected: 0,
    approved: 0,
    refused: 0,
    finished: 0,
    canceled: 0,
  }

  componentWillReceiveProps(props) {
    const {
      recent,
      accepted,
      rejected,
      approved,
      refused,
      finished,
      canceled,
    } = props.serviceOrdersCount;

    this.setState({
      recent,
      accepted,
      rejected,
      approved,
      refused,
      finished,
      canceled,
    });
  }

  componentDidMount() {
    const {
      recent,
      accepted,
      rejected,
      approved,
      refused,
      finished,
      canceled,
    } = this.props.serviceOrdersCount;

    this.setState({
      recent,
      accepted,
      rejected,
      approved,
      refused,
      finished,
      canceled,
    });
  }

  componentDidUpdate(_, nextState) {
    const {
      recent,
      accepted,
      rejected,
      approved,
      refused,
      finished,
      canceled,
    } = this.state;

    const {
      recent: newRecent,
      accepted: newAccepted,
      rejected: newRejected,
      approved: newApproved,
      refused: newRefused,
      finished: newFinished,
      canceled: newCanceled,
    } = nextState;

    if (
      recent !== newRecent
      || accepted !== newAccepted
      || rejected !== newRejected
      || approved !== newApproved
      || finished !== newFinished
      || canceled !== newCanceled
      || refused !== newRefused
    ) {
      this.build({
        recent,
        accepted,
        rejected,
        approved,
        refused,
        finished,
        canceled,
      });
    }
  }

  build = ({
    recent,
    accepted,
    rejected,
    approved,
    refused,
    finished,
    canceled,
  }) => {
    window.buildChart(
      recent,
      accepted,
      rejected,
      approved,
      refused,
      finished,
      canceled,
    );
  }

  render() {
    const {
      recent,
      accepted,
      rejected,
      approved,
      refused,
      finished,
      canceled,
    } = this.state;

    const sum = (
      recent
      + accepted
      + rejected
      + approved
      + refused
      + finished
      + canceled
    );

    const hasData = sum > 0;

    return (
      <DarkIbox>
        <div className="os-chart-container">
          {
            !hasData && <span>Não há dados para exibir</span>
          }

          <canvas
            id="os-chart"
            width="200"
            className={hasData ? "d-block" : "d-none"}
          />
        </div>
      </DarkIbox>
    )
  }
}

export default ServiceOrderChart;
