import React, { Component } from 'react';
import { OverlayTrigger, Button, Popover } from 'react-bootstrap';
import renderHTML from 'react-render-html';
import axios from 'axios';

const LOADER = '<div class="lds-facebook"><div></div><div></div><div></div></div>';

class BankSlipPreview extends Component {
  state = {
    html: LOADER
  }

  popover = () => (
    <Popover id="popover-basic" title="Visualização prévia de boleto" style={{ zIndex: 9999 }}>
      {renderHTML(`${this.state.html}`)}
    </Popover>
  );

  clear = () => {
    this.setState({
      html: LOADER
    });
  };

  update = () => {
    const {
      companyId,
      bankAccountId,
      clientId,
      contractId,
      billingItemId,
      value,
      dueDate,
      instruction,
      additionalInstruction,
      interest,
      installmentNumber,
      maxOverdueDays,
    } = this.props;

    axios.post("/bank_slip_preview", {
      company_id: companyId,
      bank_account_id: bankAccountId,
      client_id: clientId,
      contract_id: contractId,
      billing_item_id: billingItemId,
      payment_value: value,
      payment_due_date: dueDate,
      instruction: instruction,
      additional_instruction: additionalInstruction,
      monthly_interest: interest,
      installment_number: installmentNumber,
      max_overdue_days: maxOverdueDays,
    })
      .then(({ data: { success, content, error } }) => {
        if (success) {
          this.setState({ html: content });
        } else {
          toastr.warning('Verifique se todos os campos foram preenchidos', 'A prévia não pode ser carregada');
        }
      })
      .catch(error => toastr.error(error, "Erro ao carregar visualização"));
  };

  render() {
    return (
      <OverlayTrigger trigger="focus" placement="left" overlay={this.popover()} onExited={this.clear} onEntered={this.update}>
        <Button className="btn-sm">Ver prévia</Button>
      </OverlayTrigger>
    );
  }
}

export default BankSlipPreview;
