import React from "react";
import {
  Col,
  Row,
  FormControl,
} from "react-bootstrap";
import Select from "react-select";

import { currencyHumanized } from "../../../../utils/helpers";
import calculateShipmentPrice from "../../../../utils/calculateShipmentPrice";
import DarkIbox from "../../../../commons/DarkIbox";
import CurrencyInput from '../../../inputs/CurrencyInput';

const actionIds = {
  "0": "bill",
  "1": "not_bill",
  "2": "partner",
};

const actions = {
  bill: {
    id: 0,
    text: 'Faturar',
  },
  not_bill: {
    id: 1,
    text: 'Não cobrar',
  },
  partner: {
    id: 2,
    text: 'Empresa parceira'
  },
}

class EquipmentsServices extends React.Component {

  onChangeShipment = (type, id, shipmentId, key, request = null) => (value) => {
    const {
      target: {
        value: newValue,
      },
    } = value;

    const {
      onChangeShipment,
    } = this.props;

    if (key == 'quantity') {
      onChangeShipment(type, id, shipmentId, 'price')(calculateShipmentPrice(request, newValue));
    }

    onChangeShipment(type, id, shipmentId, key)(newValue);
  }

  render() {
    const {
      os: {
        equipments,
        services,
      },
      data: {
        equipment_request_orders_attributes,
        additional_service_request_orders_attributes,
      },
      onChangeShipment,
      isFinished,
    } = this.props;

    const initialPrices = {
      billablePrice: 0,
      totalPrice: 0,
    };

    const billableEquipments = equipment_request_orders_attributes
      ? Object.values(equipment_request_orders_attributes).reduce((acc, current) => {
        const { equipment_shipment_attributes } = current;

        const shipments = Object.values(equipment_shipment_attributes).reduce((innerAcc, shipment) => {
          const totalPrice = parseFloat(shipment.price) || 0;
          const billablePrice = shipment.action === actions.bill.id ? parseFloat(shipment.price) : 0;

          return {
            totalPrice: innerAcc.totalPrice + totalPrice,
            billablePrice: innerAcc.billablePrice + billablePrice,
          };
        }, initialPrices);

        return {
          totalPrice: acc.totalPrice + shipments.totalPrice,
          billablePrice: acc.billablePrice + shipments.billablePrice,
        };
      }, initialPrices)
    : initialPrices;

    const totalizers = additional_service_request_orders_attributes
      ? Object.values(additional_service_request_orders_attributes).reduce((acc, current) => {
        const { additional_service_shipment_attributes } = current;

        const shipments = Object.values(additional_service_shipment_attributes).reduce((innerAcc, shipment) => {
          const totalPrice = parseFloat(shipment.price) || 0;
          const billablePrice = shipment.action === actions.bill.id ? parseFloat(shipment.price) : 0;

          return {
            totalPrice: innerAcc.totalPrice + totalPrice,
            billablePrice: innerAcc.billablePrice + billablePrice,
          };
        }, initialPrices)

        return {
          totalPrice: acc.totalPrice + shipments.totalPrice,
          billablePrice: acc.billablePrice + shipments.billablePrice,
        };
      }, billableEquipments)
    : billableEquipments;

    return (
      <div>
        <Row>
          <Col md={12}>
            <DarkIbox>
                <div className="ibox-green-title">
                  <h5>Equipamentos</h5>
                </div>

                <table className="table table-responsive table-dark">
                  <thead>
                    <tr>
                      <th className="col-md-1 pb-5">
                        Tipo/Detalhe
                      </th>
                      <th className="col-md-1 pb-5">
                        Cód. Equipamento
                      </th>
                      <th className="col-md-1 pb-5">
                        Quant. mínima
                      </th>
                      <th className="col-md-1 pb-5">
                        Preço unitário
                      </th>
                      <th className="col-md-1 pb-5">
                        Taxa mínima
                      </th>
                      <th className="col-md-1 pb-5">
                        Observações
                      </th>
                      <th className="col-md-1 pb-5">
                        Quant. progamada
                      </th>
                      <th className="col-md-1 pb-5">
                        Quant. realizada
                      </th>
                      <th className="col-md-1 pb-5">
                        Valor do item
                      </th>
                      <th className="col-md-1 pb-5">
                        Ação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      equipments.map(({
                        id,
                        type,
                        detail,
                        min_quantity,
                        unit,
                        unit_price,
                        total_price,
                        comments,
                        quantity,
                        shipment,
                      }) => {
                        const code = (shipment && shipment.equipment) || "-";
                        const shipmentQuantity = (shipment && equipment_request_orders_attributes[id]['equipment_shipment_attributes'][shipment.id].quantity) || 0;
                        const price = (shipment && equipment_request_orders_attributes[id]['equipment_shipment_attributes'][shipment.id].price) || 0;
                        const actionId = (shipment && equipment_request_orders_attributes[id]['equipment_shipment_attributes'][shipment.id].action) || 0;

                        const action = actions[actionIds[actionId]];

                        const request = {
                          min_quantity,
                          unit_price,
                          total_price,
                        };

                        return (
                          <tr key={id}>
                            <td>{`${type} ${detail}`}</td>
                            <td>{code}</td>
                            <td>{`${min_quantity} ${unit}`}</td>
                            <td>{currencyHumanized(unit_price)}</td>
                            <td>{currencyHumanized(total_price)}</td>
                            <td>{comments}</td>
                            <td>{quantity}</td>
                            {
                              shipment && (
                                <td>
                                  <FormControl
                                    className="table-input"
                                    disabled={isFinished}
                                    type="number"
                                    value={shipmentQuantity}
                                    onChange={this.onChangeShipment('equipment', id, shipment.id, 'quantity', request)}
                                    min={0}
                                  />
                                </td>
                              )
                            }
                            {
                              shipment && (
                                <td>
                                  <CurrencyInput
                                    className="table-input"
                                    disabled
                                    value={price}
                                  />
                                </td>
                              )
                            }
                            {
                              shipment && (
                                <td>
                                  <Select
                                    isDisabled={isFinished}
                                    value={action}
                                    placeholder="Selecione..."
                                    onChange={(value) => onChangeShipment('equipment', id, shipment.id, 'action')(value.id)}
                                    options={Object.values(actions)}
                                    getOptionLabel={(action) => action.text}
                                    getOptionValue={(action) => action.id}
                                    className="table-input"
                                  />
                                </td>
                              )
                            }
                            {
                              !shipment && <td colSpan="3">Não há equipamento associado à esta ordem.</td>
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
            </DarkIbox>

            <DarkIbox>
                <div className="ibox-green-title">
                  <h5>Serviços</h5>
                </div>

                <table className="table table-responsive table-dark">
                  <thead>
                    <tr>
                      <th className="col-md-1 pb-5">
                        Tipo/Detalhe
                      </th>
                      <th className="col-md-1 pb-5">
                        Cód. Serviço
                      </th>
                      <th className="col-md-1 pb-5">
                        Quant. mínima
                      </th>
                      <th className="col-md-1 pb-5">
                        Preço unitário
                      </th>
                      <th className="col-md-1 pb-5">
                        Taxa mínima
                      </th>
                      <th className="col-md-1 pb-5">
                        Observações
                      </th>
                      <th className="col-md-1 pb-5">
                        Quant. progamada
                      </th>
                      <th className="col-md-1 pb-5">
                        Quant. realizada
                      </th>
                      <th className="col-md-1 pb-5">
                        Valor do item
                      </th>
                      <th className="col-md-1 pb-5">
                        Ação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      services.map(({
                        id,
                        type,
                        detail,
                        min_quantity,
                        unit,
                        unit_price,
                        total_price,
                        comments,
                        quantity,
                        shipment,
                      }) => {
                        const code = (shipment && shipment.service) || "-";
                        const shipmentQuantity = (shipment && additional_service_request_orders_attributes[id]['additional_service_shipment_attributes'][shipment.id].quantity) || 0;
                        const price = (shipment && additional_service_request_orders_attributes[id]['additional_service_shipment_attributes'][shipment.id].price) || 0;
                        const actionId = (shipment && additional_service_request_orders_attributes[id]['additional_service_shipment_attributes'][shipment.id].action) || 0;

                        const action = actions[actionIds[actionId]];

                        const request = {
                          min_quantity,
                          unit_price,
                          total_price,
                        };

                        return (
                          <tr key={id}>
                            <td>{`${type} ${detail}`}</td>
                            <td>{code}</td>
                            <td>{`${min_quantity} ${unit}`}</td>
                            <td>{currencyHumanized(unit_price)}</td>
                            <td>{currencyHumanized(total_price)}</td>
                            <td>{comments}</td>
                            <td>{quantity}</td>
                            {
                              shipment && (
                                <td>
                                  <FormControl
                                    className="table-input"
                                    disabled={isFinished}
                                    value={shipmentQuantity}
                                    onChange={this.onChangeShipment('additional_service', id, shipment.id, 'quantity', request)}
                                    min={0}
                                    type="number"
                                  />
                                </td>
                              )
                            }
                            {
                              shipment && (
                                <td>
                                  <CurrencyInput
                                    className="table-input"
                                    disabled
                                    value={price}
                                  />
                                </td>
                              )
                            }
                            {
                              shipment && (
                                <td>
                                  <Select
                                    isDisabled={isFinished}
                                    value={action}
                                    placeholder="Selecione..."
                                    onChange={(value) => onChangeShipment('additional_service', id, shipment.id, 'action')(value.id)}
                                    options={Object.values(actions)}
                                    getOptionLabel={(action) => action.text}
                                    getOptionValue={(action) => action.id}
                                    className="table-input"
                                  />
                                </td>
                              )
                            }
                            {
                              !shipment && <td colSpan="3">Não há serviço associado à esta ordem.</td>
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
            </DarkIbox>
          </Col>
        </Row>

        <Row>
          <Col>
            <DarkIbox>
              <div className="totals">
                <span className="total">
                  Valor total
                  <strong className="ml-10">
                    {currencyHumanized(totalizers.totalPrice)}
                  </strong>
                </span>

                <div className="dot"/>

                <span className="billing">
                  Valor a faturar
                  <strong className="ml-10">
                    {currencyHumanized(totalizers.billablePrice)}
                  </strong>
                </span>
              </div>
            </DarkIbox>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EquipmentsServices;
