import React from 'react';
import { Row, Table } from 'react-bootstrap';

const PaymentReportTable = ({ id, initialDate, finalDate, paymentDate, status }) => (
  <Row>
    <Table>
      <thead>
        <tr>
          <th>BM</th>
          <th>Data Inicial (BM)</th>
          <th>Data Final (BM)</th>
          <th>Data de Pagamento</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>{id}</td>
          <td>{initialDate}</td>
          <td>{finalDate}</td>
          <td>{paymentDate}</td>
          <td>{status}</td>
        </tr>
      </tbody>
    </Table>
  </Row>
);

export default PaymentReportTable;
