const calculateShipmentPrice = (request, quantity) => {
  if (request && quantity) {
    const {
      min_quantity,
      unit_price,
      total_price,
    } = request;

    const calculatedPrice = ((parseFloat(quantity) - parseFloat(min_quantity)) * parseFloat(unit_price)) + parseFloat(total_price);
    const price = parseFloat(quantity) < parseFloat(min_quantity) ? parseFloat(total_price) : calculatedPrice;

    return price;
  } else {
    return 0;
  }
}

export default calculateShipmentPrice;
