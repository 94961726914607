import React, { Component } from 'react';
import axios from 'axios';
import ServiceOrderForm from './ServiceOrderForm';
import ClientContractInfo from './partials/ClientContractInfo';

class ServiceOrderCreate extends Component {
  onSave = (formData) => {
    const { contract } = this.props;

    axios.post(`/contracts/${contract.id}/service_orders`, formData)
      .then(({ data }) => {
        if (data.success) {
          window.location = "/service_orders";
        } else {
          window.hide_loader();
          if (data.errors.length) {
            data.errors.forEach(error => toastr.warning(error, "Falha ao salvar OS"));
          } else {
            toastr.warning("Por favor, tente novamente", "Falha ao salvar OS")
          }
        }
      })
      .catch(error => {
        window.hide_loader();
        toastr.error(error, "Erro interno");
      });
  }

  onCancel = () => {
    window.location = "/service_orders";
  }

  render = () => {
    const {
      contract,
      currentUser,
      serviceOrder,
      partners,
      additional_service_requests,
      equipment_requests,
    } = this.props;

    return (
      <div>
        <ClientContractInfo
          title="Cadastro de Ordem de Serviço"
          contract={contract}
          currentUser={currentUser}
          serviceOrder={serviceOrder}
          creating
        />
        <ServiceOrderForm
          onSave={this.onSave}
          onCancel={this.onCancel}
          partners={partners}
          additional_service_requests={additional_service_requests}
          equipment_requests={equipment_requests}
          allowRecurrence
          updating={false}
        />
      </div>
    );
  }
}

export default ServiceOrderCreate;
