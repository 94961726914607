import React from 'react';
import { Decimal } from 'decimal.js';
import NumberFormat from 'react-number-format';
import { DECIMAL_PRECISION } from './consts';

export const coerce = (value) => new Decimal(value || 0);

/**
 * This function converts arrays into objects
 *
 * @param {*} array  An array of objects
 * @param {*} getKey A function to extract the key
 * @param {*} append A function to extract extra result
 */
export const objectlize = (array, getKey, append) => {
  if (!array) {
    return {};
  }

  return (
    array.reduce((result, current) => ({
      ...result,
      [getKey(current)]: {
        ...current,
        ...append ? append(current) : {}
      }
    }), {})
  );
}

export const sum = (array, getValue) => array.reduce((sum, object) => {
  const a = coerce(getValue(object));
  const b = coerce(sum);

  return a.plus(b).toFixed(DECIMAL_PRECISION);
}, 0);

export const generateRandomId = () => Math.random().toString().substr(2, 9) + "" + new Date().getTime();

export const currencyHumanized = (value) => {
  return (
    <NumberFormat
      value={parseFloat(value)}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={DECIMAL_PRECISION}
      fixedDecimalScale
      prefix="R$ "
      renderText={value => <span>{value}</span>}
    />
  );
}
