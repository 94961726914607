import React from "react";
import Calendar from "react-big-calendar";
import {
  Modal,
  Button,
  FormControl,
  Col,
  Row,
  Grid,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import { DatePicker, TimePicker } from "@blueprintjs/datetime";
import { Checkbox } from 'react-icheck';
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import Select from 'react-select';
import everydate from "everydate";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import LocaleUtils, { getDefaultMaxDate } from "../../../utils/LocaleUtils";

moment.locale('pt-BR');
const messages = {
  date: 'data',
  time: 'hora',
  event: 'programação',
  allDay: 'dia inteiro',
  week: 'semana',
  work_week: 'dias úteis',
  day: 'dia',
  month: 'mês',
  previous: 'anterior',
  next: 'próximo',
  yesterday: 'ontem',
  tomorrow: 'amanhã',
  today: 'hoje',
  agenda: 'horários',
  noEventsInRange: 'Não há programações neste intervalo.',
  showMore: total => `+${total} mais`,
}

const localizer = Calendar.momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const NEW_EVENT_COLOR = "#3B86FF";
const RECURRENT_EVENT_COLOR = "#0048a7";
const OTHER_EVENTS_COLOR = "#595959";
const PT_DATE_TEMPLATE = 'DD/MM/YYYY HH:mm';
const NEW_EVENT_NAME = "Nova OS";
const WEEK = "DOM_SEG_TER_QUA_QUI_SEX_SÁB".split("_");

const STARTED_IN_APP_WARNING = "Não é possível alterar o horário de ordens de serviço já iniciadas pelo aplicativo.";

class CalendarView extends React.Component {
  constructor(props) {
    super(props);
    const {
      scheduled_concreting_date,
      scheduled_start_time,
      scheduled_end_time,
    } = props.initialValue;
    let startTime = new Date();
    let endTime = new Date(moment().add(30, "minutes"));
    if (scheduled_concreting_date && scheduled_start_time && scheduled_end_time) {
      startTime = moment(scheduled_concreting_date + ' ' + scheduled_start_time, PT_DATE_TEMPLATE).toDate();
      endTime = moment(scheduled_concreting_date + ' ' + scheduled_end_time, PT_DATE_TEMPLATE).toDate();
    }

    this.state = {
      show: false,
      view: 'week',
      flip: false,
      startTime,
      endTime,

      ...this.construct(props)
    };

    props.onInitialize(this.onButtonClick);
  }

  componentWillReceiveProps(props) {
    this.setState(this.construct(props));
  }

  construct = props => {
    const {
      scheduled_concreting_date,
      scheduled_start_time,
      scheduled_end_time,
    } = props.initialValue;
    const {
      recurrence,
    } = props.sharedValues;

    const { events } = props;
    const newEvents = [];

    if (scheduled_concreting_date && scheduled_start_time && scheduled_end_time) {
      const startTime = moment(scheduled_concreting_date + ' ' + scheduled_start_time, PT_DATE_TEMPLATE).toDate();
      const endTime = moment(scheduled_concreting_date + ' ' + scheduled_end_time, PT_DATE_TEMPLATE).toDate();
      newEvents.push({ title: NEW_EVENT_NAME, start: startTime, end: endTime });

      recurrence.map((event, index) => {
        const start = moment(event.scheduled_concreting_date + ' ' + event.scheduled_start_time, PT_DATE_TEMPLATE).toDate();
        const end = moment(event.scheduled_concreting_date + ' ' + event.scheduled_end_time, PT_DATE_TEMPLATE).toDate();

        newEvents.push({ title: `${NEW_EVENT_NAME} ${event.code}`, start, end });
      });
    }

    return {
      events: [...events, ...newEvents]
    };
  }

  buildRecurrence = (startTime, endDate, units, daysOfWeek, measure, useDate, limit) => {
    const recur = everydate({
      start: startTime,
      units: measure.value === "daysOfWeek" ? daysOfWeek : [units],
      measure: measure.value,
      returnDates: true,
    });
    if (useDate) {
      recur.setEnd(endDate);
      return recur.all();
    } else {
      return recur.next(limit);
    }
  }

  flipModal = () => this.setState({ flip: !this.state.flip });

  handleSelectRepeat = repeat => {
    this.props.handleChange("repeat")({ target: { value: repeat } });
    if (repeat.value === "CUSTOM") {
      this.flipModal();
    }
  };

  toggleUse = () => {
    const {
      handleChange,
      sharedValues: {
        use_date,
      },
    } = this.props;
    handleChange("use_date")({ target: { value: !use_date } });
  }

  handleSelect = key => value => {
    const { handleChange } = this.props;
    handleChange(key)({ target: { value } });
  };

  handleCancel = () => {
    const confimed = window.confirm("Você deseja cancelar a programação desta OS?");
    if (confimed) {
      this.props.onScheduleServiceOrder("", "");
      this.handleClose();
    } else {
      toastr.info("Nada foi alterado. Por favor, confira a programação para confirmá-la.")
    }
  }

  handleConfirm = () => {
    const {
      onScheduleServiceOrder,
      onSaveRecurrence,
      sharedValues: {
        end_date,
        measure,
        limit,
        units,
        use_date,
        days_of_week,
        repeat,
      },
    } = this.props;
    const { startTime, endTime } = this.state;

    if (this.props.allowRecurrence) {
      if (repeat.value === "CUSTOM") {
        const recurrences = this.buildRecurrence(startTime, end_date, units, days_of_week, measure, use_date, limit);
        recurrences.shift();
        const recurrence = recurrences.map((event, index) => {
          return {
            scheduled_concreting_date: moment(event).format("DD/MM/YYYY"),
            scheduled_start_time: moment(startTime).format("HH:mm"),
            scheduled_end_time: moment(endTime).format("HH:mm"),
            code: index + 1,
          }
        });
        onSaveRecurrence(recurrence);
      } else {
        onSaveRecurrence([]);
      }
    }

    onScheduleServiceOrder(startTime, endTime);

    this.handleClose();
  }

  handleClose = () => {
    this.setState({ show: false, flip: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleDateChange = value => {
    this.setState({ startTime: value });
  }

  getTime = datetime => {
    const timeString = moment(datetime).format("HH:mm");
    return moment(timeString, 'HH:mm').toDate();
  }

  updateTime = value => {
    const date = moment(this.state.startTime).format("DD/MM/YYYY");
    const time = moment(value).format("HH:mm");

    return moment(`${date} ${time}`, 'DD/MM/YYYY HH:mm').toDate();
  }

  handleTimeChange = key => value => {
    const start = this.getTime(this.state.startTime);
    const end = this.getTime(this.state.endTime);
    const timeValue = this.getTime(value);

    let diff = moment(end).diff(start);
    if (key === "startTime") {
      diff = moment(end).diff(timeValue);
    } else if (key === "endTime") {
      diff = moment(timeValue).diff(start);
    }

    const duration = moment.duration(diff);
    const minutes = duration.asMinutes();
    if (minutes < 30) {
      toastr.warning("A duração mínima de uma OS é de 30 minutos.")
      return;
    }

    this.setState({ [key]: this.updateTime(value) });
  }

  onNavigate = date => {
    this.props.loadEvents(date, this.state.view);
  };

  onView = view => {
    this.setState(
      { view },
    );
  }

  onSelectEvent = event => {
    if (this.props.isBlocked) {
      this.warnCantChange(STARTED_IN_APP_WARNING);
      return;
    }

    if (event.title === NEW_EVENT_NAME || event.title.includes(NEW_EVENT_NAME) && allowRecurrence) {
      this.handleShow();
    } else {
      this.warnCantChange();
    }
  }

  onChangeEvent = (start, end, event) => {
    if (this.props.isBlocked) {
      this.warnCantChange(STARTED_IN_APP_WARNING);
      return;
    }

    const {
      onScheduleServiceOrder,
      onSaveRecurrence,
      allowRecurrence,
      sharedValues
    } = this.props;
    const { recurrence } = sharedValues;
    if (event.title === NEW_EVENT_NAME) {
      this.setState({ startTime: start, endTime: end });
      onScheduleServiceOrder(start, end);
    } else if (event.title.includes(NEW_EVENT_NAME) && allowRecurrence) {
      const value = [
        ...recurrence.filter(r => event.title !== `${NEW_EVENT_NAME} ${r.code}`),
        {
          scheduled_concreting_date: moment(start).format("DD/MM/YYYY"),
          scheduled_start_time: moment(start).format("HH:mm"),
          scheduled_end_time: moment(end).format("HH:mm"),
          code: parseInt(event.title.replace(`${NEW_EVENT_NAME} `, "")),
        }
      ];
      onSaveRecurrence(value);
    } else {
      this.warnCantChange();
    }
  }

  onEventResize = (type, { event, start, end, allDay }) => {
    if (this.props.isBlocked) {
      this.warnCantChange(STARTED_IN_APP_WARNING);
      return;
    }

    if (event.title.includes(NEW_EVENT_NAME)) {
      this.onChangeEvent(start, end, event);
    } else {
      this.warnCantChange();
    }
  };

  onEventDrop = ({ event, start, end, allDay }) => {
    if (this.props.isBlocked) {
      this.warnCantChange(STARTED_IN_APP_WARNING);
      return;
    }

    if (event.title.includes(NEW_EVENT_NAME)) {
      this.onChangeEvent(start, end, event);
    } else {
      this.warnCantChange();
    }
  };

  onButtonClick = this.handleShow;

  onSelectSlot = ({ start, end }) => {
    if (this.props.isBlocked) {
      this.warnCantChange(STARTED_IN_APP_WARNING);
      return;
    }

    const endTime = start === end ? moment(start).add(30, "minutes").toDate() : end;
    this.setState({ startTime: start, endTime }, this.handleShow);
  };

  warnCantChange = (message = "Você não pode mudar a programação de outras ordens de serviço agora.") => toastr.warning(message);

  eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.title === NEW_EVENT_NAME ? NEW_EVENT_COLOR : event.title.includes(NEW_EVENT_NAME) ? RECURRENT_EVENT_COLOR : OTHER_EVENTS_COLOR;
    const style = {
      backgroundColor,
      color: "#fff",
      borderRadius: '0px',
      opacity: 0.95,
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
  };

  renderModalTitle = () => {
    const { sharedValues: { repeat }, allowRecurrence } = this.props;
    const { flip } = this.state;

    if (!flip) {
      return (
        <div className="d-flex space-between">
          <span>Informações da ordem de serviço</span>
          {(repeat.value === "CUSTOM" && allowRecurrence) && <i className="fa fa-chevron-right clickable" onClick={this.flipModal}></i>}
        </div>
      );
    } else {
      return (
        <div className="d-flex space-between">
          <span>Recorrência personalizada</span>
          <i className="fa fa-chevron-left clickable" onClick={this.flipModal}></i>
        </div>
      );
    }
  }

  toggleDayOfWeek = day => {
    const {
      handleChange,
      sharedValues: {
        days_of_week,
      },
    } = this.props;
    if (days_of_week.includes(day)) {
      handleChange("days_of_week")({ target: { value: days_of_week.filter(value => value != day) } });
    } else {
      handleChange("days_of_week")({ target: { value: [...days_of_week, day] } });
    }
  }

  renderWeekDay = (day, index) => {
    const { days_of_week } = this.props.sharedValues;
    return <button
      className={`btn btn-primary btn-outline btn-sm ${days_of_week.includes(index) ? "active" : ""}`}
      type="button"
      key={day}
      onClick={() => this.toggleDayOfWeek(index)}
    >
      {day}
    </button>;
  }

  renderModal = () => {
    const {
      sharedValues: {
        part,
        end_date,
        measure,
        limit,
        units,
        use_date,
        repeat,
      },
      handleChange,
      MEASURES,
      REPEAT_OPTIONS,
    } = this.props;
    const {
      startTime,
      endTime,
      flip,
    } = this.state;

    return !flip && (
      <Grid fluid>
        <Row>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Data prevista</ControlLabel>
              <DatePicker
                maxDate={getDefaultMaxDate()}
                value={startTime}
                onChange={this.handleDateChange}
                localeUtils={LocaleUtils}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Peça</ControlLabel>
              <FormControl
                value={part}
                onChange={handleChange("part")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Horário de início previsto</ControlLabel>
              <TimePicker
                value={startTime}
                onChange={this.handleTimeChange("startTime")}
                showArrowButtons
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Horário de término previsto</ControlLabel>
              <TimePicker
                value={endTime}
                onChange={this.handleTimeChange("endTime")}
                showArrowButtons
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Duração</ControlLabel>
              <FormControl
                disabled
                value={moment.utc(moment(this.state.endTime).diff(this.state.startTime)).format("HH:mm")}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Repetir</ControlLabel>
              <Select
                value={repeat}
                isDisabled={!this.props.allowRecurrence}
                placeholder="Selecione..."
                onChange={this.handleSelectRepeat}
                options={REPEAT_OPTIONS}
                getOptionLabel={(a) => a.label}
                getOptionValue={(a) => a.value}
              />
            </FormGroup>
          </Col>
        </Row>
      </Grid>
    )
      || (
        <Grid fluid>
          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Repetir a cada</ControlLabel>
                <Row>
                  <Col md={8}>
                    {measure.value === "daysOfWeek"
                      ? <div className="btn-group">
                        {WEEK.map((dayOfWeek, index) => this.renderWeekDay(dayOfWeek, index))}
                      </div>
                      : <FormControl
                        type="number"
                        step="1"
                        value={units}
                        onChange={handleChange("units")}
                      />
                    }

                  </Col>
                  <Col md={4}>
                    <Select
                      value={measure}
                      placeholder="Selecione..."
                      onChange={this.handleSelect("measure")}
                      options={MEASURES}
                      getOptionLabel={(a) => a.label}
                      getOptionValue={(a) => a.value}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>

              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Termina</ControlLabel>
              </FormGroup>
              <Grid fluid>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Checkbox
                        checked={use_date}
                        checkboxClass="iradio_square-green"
                        increaseArea="20%"
                        label="&emsp;Em"
                        onClick={this.toggleUse}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={9}>
                    <FormGroup>
                      {use_date ? (
                        <DatePicker
                          maxDate={getDefaultMaxDate()}
                          value={end_date}
                          onChange={value => handleChange("end_date")({ target: { value } })}
                          localeUtils={LocaleUtils}
                        />
                      ) : <FormControl
                          disabled={true}
                          value={moment(end_date).format("DD/MM/YYYY")}
                        />
                      }
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Checkbox
                        checked={!use_date}
                        checkboxClass="iradio_square-green"
                        increaseArea="20%"
                        label="&emsp;Após"
                        onClick={this.toggleUse}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={9}>
                    <FormGroup>
                      <FormControl
                        type="number"
                        step="1"
                        disabled={use_date}
                        value={limit}
                        onChange={handleChange("limit")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Grid>
            </Col>
          </Row>
        </Grid>
      );
  }

  render() {
    const {
      events,
      show,
    } = this.state;

    return (
      <div>
        <DnDCalendar
          messages={messages}
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="week"
          views={['month', 'week', 'day']}
          events={events}
          onEventDrop={this.onEventDrop}
          onEventResize={this.onEventResize}
          resizable
          onSelectSlot={this.onSelectSlot}
          selectable
          style={{ height: "100vh" }}
          eventPropGetter={(this.eventStyleGetter)}
          onNavigate={this.onNavigate}
          onView={this.onView}
          onSelectEvent={this.onSelectEvent}
        />
        <div className="labels-container">
          <h4 className="labels-title">Legenda</h4>
          <div className="labels-items">
            <div className="label-item">
              <div className="label-box" style={{ backgroundColor: NEW_EVENT_COLOR }}></div>
              <div className="label-text">Nova ordem de serviço</div>
            </div>
            <div className="label-item">
              <div className="label-box" style={{ backgroundColor: RECURRENT_EVENT_COLOR }}></div>
              <div className="label-text">Repetições da nova ordem de serviço</div>
            </div>
            <div className="label-item">
              <div className="label-box" style={{ backgroundColor: OTHER_EVENTS_COLOR }}></div>
              <div className="label-text">Ordens de serviço já cadastradas</div>
            </div>
          </div>
        </div>

        <Modal
          className="horizontal-centered"
          show={show}
          onHide={this.handleClose}
        >
          <Modal.Header>
            <Modal.Title className="text-navy">
              {this.renderModalTitle()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderModal()}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Button bsStyle="danger" onClick={this.handleCancel} className="btn-outline">Excluir programação</Button>
              <div>
                <Button bsStyle="default" onClick={this.handleClose} className="btn-outline">Cancelar</Button>
                <Button bsStyle="primary" onClick={this.handleConfirm} className="btn-outline">Confirmar</Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CalendarView;
