import React, { Component } from 'react';
import { Button, Panel, Alert } from 'react-bootstrap';
import axios from "axios";

import PaymentReportTable from "./PaymentReportTable";
import OsInfo from "./OsInfo";
import ContractInfo from "./ContractInfo";
import CompanyRatio from "./CompanyRatio";
import BillingItem from "./BillingItem"
import PaymentsModal from "./PaymentsModal"
import RentalBillModal from "./RentalBillModal";
import ServiceInvoiceModal from "./ServiceInvoiceModal";
import { loadingLabel } from "../../commons/strings";
import { loadPaymentState } from "../../utils/payments.js";

class Closing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      billingItems: this.initBillingItems(props),
      payments: this.initPayments(props),
      selectedCompany: {},
      nfseModalOpen: false,
      rentalBillModalOpen: false,
      paymentsModalOpen: false,
      showAlert: {
        open: false,
        message: ''
      },
      submitLoading: false,
    };
  }

  initPayments = (props) => {
    const {
      companies,
      billingItems,
    } = props;

    return companies.reduce(
      (acc, company) => {
        const id = company.id;
        const billingItem = billingItems.find(({ company_id }) => company_id === id) || {};

        return {
          ...acc,
          [id]: loadPaymentState(props, billingItem, company),
        }
      },
      {}
    );
  };

  initBillingItems = ({
    billingItems,
    billingPrice,
    companies,
    contractInfo,
    equipmentShipments,
    additionalServiceShipments,
    osInfo,
  }) => companies.reduce(
    (acc, { id }, index) => {
      const billingItem = billingItems.find(({ company_id }) => company_id === id) || {};
      const {
        service_discrimination,
        service_uf_location,
        service_city_location,
        service_address_location,
        service_cep_location,
        service_neighborhood_location,
        service_number_location,
        custom_service_location,
        service_location_from_contract,
        iss_withheld_source,
        municipal_service_code,
        municipal_service_description,
        reason,
        status,
      } = billingItem.last_service_invoice || {};

      return {
        ...acc,
        [id]: {
          ...billingItem,
          ...this.initApportionments(billingItem, billingPrice, index, osInfo.billingDate !== "-"),
          shouldEmitNfse: billingItem.emit_nfse || false,
          shouldEmitPayments: billingItem.emit_payments || false,
          shouldEmitRentalBill: billingItem.emit_bill || false,
          shouldEmitReport: billingItem.attach_report || false,
          equipmentShipments: billingItem.equipment_shipments || equipmentShipments,
          serviceShipments: billingItem.service_shipments || additionalServiceShipments,
          dueDate: billingItem.due_date ? moment(billingItem.due_date).toDate() : contractInfo.paymentInfo.firstDueDate,
          billComments: billingItem.bill_comments || "",
          nsfeComments: service_discrimination || "",
          ufServiceLocation: service_uf_location || "",
          cityServiceLocation: service_city_location || "",
          serviceAddressLocation: service_address_location || "",
          serviceCepLocation: service_cep_location || "",
          serviceNeighborhoodLocation: service_neighborhood_location || "",
          serviceNumberLocation: service_number_location || "",
          customServiceLocation: !!custom_service_location,
          serviceLocationFromContract: !!service_location_from_contract,
          withheldTaxes: !!iss_withheld_source,
          serviceCode: municipal_service_code || null,
          serviceDescription: municipal_service_description || null,
          reason: reason || "",
          status: status || "",
          lastServiceInvoice: billingItem.last_service_invoice || null,
          accountPlan: billingItem.account_plan,
        },
      };
    },
    {}
  )

  initApportionments = ({ percent, total_value, }, billingPrice, index, isEmitted) => {
    const defaultPercent = index === 0 && !isEmitted ? 100 : 0;
    const defaultTotalValue = index === 0 && !isEmitted ? (billingPrice * defaultPercent / 100) : 0;

    return {
      percent: (percent === null || percent === undefined) ? defaultPercent : percent,
      totalValue: (total_value === null || total_value === undefined) ? defaultTotalValue : total_value,
    };
  };

  updateBillingItem = (billingItem, companyId) => {
    this.setState(prevState => ({
      billingItems: {
        ...prevState.billingItems,
        [companyId]: {
          ...prevState.billingItems[companyId],
          ...billingItem,
        },
      },
    }));
  }

  updateBillingItems = billingItems => this.setState({ billingItems })

  toggleModal = ({
    modalKey,
    company,
  }) => {
    const selectedCompany = company || this.state.selectedCompany;

    this.setState(prevState => {
      const billingItem = prevState.billingItems[selectedCompany.id];
      let shouldEmitKey;

      if (modalKey === "nfseModalOpen") {
        shouldEmitKey = "shouldEmitNfse";
      }
      else if (modalKey === "rentalBillModalOpen") {
        shouldEmitKey = "shouldEmitRentalBill";
      }
      else if (modalKey === "paymentsModalOpen") {
        shouldEmitKey = "shouldEmitPayments";
      }
      else if (modalKey === "shouldEmitReport") {
        shouldEmitKey = "shouldEmitReport";
      }

      const shouldEmit = !billingItem[shouldEmitKey];

      return {
        selectedCompany,
        [modalKey]: shouldEmit,
        billingItems: {
          ...prevState.billingItems,
          [selectedCompany.id]: {
            ...billingItem,
            [shouldEmitKey]: shouldEmit,
          }
        },
      };
    });
  }

  closeBillingModal = (billingState = {}) => {
    const { selectedCompany } = this.state;

    this.setState(prevState => {
      const billingItem = prevState.billingItems[selectedCompany.id];

      return {
        nfseModalOpen: false,
        rentalBillModalOpen: false,
        paymentsModalOpen: false,
        billingItems: {
          ...prevState.billingItems,
          [selectedCompany.id]: {
            ...billingItem,
            ...billingState,
          },
        },
      };
    });
  }

  closePaymentsModal = payments => {
    const { selectedCompany } = this.state;

    this.setState(prevState => ({
      nfseModalOpen: false,
      rentalBillModalOpen: false,
      paymentsModalOpen: false,
      payments: {
        ...prevState.payments,
        [selectedCompany.id]: payments,
      },
    }));
  }

  handleSubmit = () => {
    const invalidBillingItems = Object.values(this.state.billingItems).filter((billingItem) => {
      const hasBillOrInvoice = billingItem.shouldEmitNfse || billingItem.shouldEmitRentalBill;

      return hasBillOrInvoice && !billingItem.shouldEmitPayments;
    });

    if (invalidBillingItems.length > 0) {
      Swal.fire({
        title: 'Você tem certeza que deseja continuar?',
        text: 'Você está emitindo faturas de locação ou notas fiscais de serviço sem pagamentos.',
        type: 'warning',
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      }).then(({ value: confirmed }) => {
        if (confirmed) {
          this.submitData();
        }
      });
      return;
    }

    this.submitData();
  }

  submitData = () => {
    this.setState({ submitLoading: true });

    axios.post("/billing_items/emit_bill", {
      billingItems: this.state.billingItems,
      payments: this.state.payments,
      osInfo: this.props.osInfo,
      prInfo: this.props.prInfo,
    })
      .then(({ data: { errors } }) => {
        if (errors.length) {
          toastr.error(`Os seguintes erros ocorreram: ${errors.join(", ")}`, "Atenção");
        } else {
          window.location = "/financial";
        }

        this.setState({ submitLoading: false });
      })
      .catch(() => {
        toastr.error("Ocorreu um erro inesperado, tente novamente mais tarde ou contate o suporte.", "Erro ao emitir fatura");

        this.setState({ submitLoading: false });
      });
  }

  getBilledCompanies = () => this.props.companies.filter(({ id }) => {
    const billingItem = this.state.billingItems[id];
    return parseFloat(billingItem.percent) !== 0;
  });

  canSubmit = billedCompanies => billedCompanies.some(({ id }) => (
    this.state.billingItems[id].shouldEmitNfse ||
    this.state.billingItems[id].shouldEmitPayments ||
    this.state.billingItems[id].shouldEmitRentalBill
  ))

  renderBillingItemTable = (companies, billingItems) => (
    <div>
      {companies.map(company => {
        const billingItem = billingItems[company.id];

        return (
          <Panel key={company.id}>
            <Panel.Heading>Faturamento para a razão social: {company.company_name} ({company.nickname})</Panel.Heading>
            <Panel.Body>
              <BillingItem
                company={company}
                billingItem={billingItem}
                percent={parseInt(billingItem.percent)}
                totalValue={parseFloat(billingItem.totalValue)}
                updateBillingItem={this.updateBillingItem}
                toggleModal={this.toggleModal}
                accountPlans={this.props.accountPlans}
              />
            </Panel.Body>
          </Panel>
        )
      })}
    </div>
  )

  render() {
    if (this.props.hasError) {
      return (
        <div>
          <strong style={{ color: 'red' }}>Erro: </strong>
          A OS está marcada como não avulsa, porém não existe Boletim de Medição associado a ela.
        </div>
      );
    }

    const {
      billingPrice,
      contractInfo,
      companies,
      osInfo,
      prInfo,
    } = this.props;
    const {
      billingItems,
      payments,
      selectedCompany,
      submitLoading,
      showAlert,
    } = this.state;
    const billingItem = billingItems[selectedCompany.id] || {};
    const discount = billingItem.discount || 0;
    const billedCompanies = this.getBilledCompanies();

    return (
      <div>
        {showAlert.open && (
          <Alert bsStyle="warning">
            <strong>{showAlert.message}</strong>
          </Alert>
        )}

        <div className="container-fluid">
          <OsInfo {...osInfo} />

          {!osInfo.isSole && <PaymentReportTable {...prInfo} />}

          <Panel>
            <Panel.Heading>Informações do Contrato</Panel.Heading>
            <Panel.Body>
              <ContractInfo {...contractInfo} />
            </Panel.Body>
          </Panel>

          <h3>
            Valor total (bruto) a faturar: <strong style={{ color: 'green' }}>R$ {billingPrice.toFixed(2)}</strong>
          </h3>

          <Panel>
            <Panel.Heading>Rateio entre empresas</Panel.Heading>
            <Panel.Body>
              <CompanyRatio
                billingItems={billingItems}
                companies={companies}
                billingPrice={billingPrice}
                updateBillingItems={this.updateBillingItems}
              />
            </Panel.Body>
          </Panel>

          {this.renderBillingItemTable(billedCompanies, billingItems)}

          <ServiceInvoiceModal
            show={this.state.nfseModalOpen}
            billingItem={billingItem}
            companyId={selectedCompany.id}
            selectedCompany={selectedCompany}
            contractInfo={contractInfo}
            toggleModal={this.toggleModal}
            closeModal={this.closeBillingModal}
          />

          <RentalBillModal
            show={this.state.rentalBillModalOpen}
            companyId={selectedCompany.id}
            serviceOrderId={this.props.osInfo.id}
            billingItem={billingItem}
            closeModal={this.closeBillingModal}
          />

          <PaymentsModal
            show={this.state.paymentsModalOpen}
            closeModal={this.closePaymentsModal}
            company={selectedCompany}
            price={(billingItem.totalValue || 0) - discount}
            client={{
              id: this.props.contractInfo.clientId,
              code: this.props.contractInfo.clientCode,
              name: this.props.contractInfo.clientName,
            }}
            contractId={this.props.contractInfo.contractId}
            paymentMethods={this.props.paymentMethods}
            paymentInfo={this.props.contractInfo.paymentInfo}
            bankAccounts={this.props.bankAccounts}
            payments={payments[selectedCompany.id]}
            billingItem={billingItem}
          />

          <Button
            disabled={!this.canSubmit(billedCompanies) || submitLoading}
            bsStyle={!!contractInfo.billingDate ? "warning" : "primary"}
            bsSize="large"
            style={{ float: "right" }}
            onClick={this.handleSubmit}
          >
            {
              !!contractInfo.billingDate
                ? "Reemitir fatura"
                : "Emitir fatura"
            }
            {submitLoading && (
              <div className="sk-spinner-pulse sk-spinner" role="status" style={{ background: "#337ABA" }}>
                <span className="sr-only">{loadingLabel}</span>
              </div>
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default Closing;
