import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  ControlLabel,
  Button,
  Modal
} from 'react-bootstrap';
import { Checkbox } from 'react-icheck';
import axios from 'axios';
import { saveAs } from 'file-saver';

const TYPE = {
  equipments: 'equipments',
  services: 'services',
  both: 'both',
};

class ExportScheduleModal extends React.Component {
  state = {
    type: TYPE.equipments,
    show_values: true,
  }

  onChangeType = (type) => () => this.setState({ type });

  onChangeShowValues = () => this.setState(({ show_values }) => ({ show_values: !show_values }));

  onExport = () => {
    const { date } = this.props;
    const {
      type,
      show_values,
    } = this.state;

    const url = `/service_orders/daily_service_orders_pdf_download/`;

    window.show_loader();

    axios({
      url,
      data: {
        type,
        date,
        show_values,
      },
      method: 'POST',
      responseType: 'blob',
    })
      .then(({ data, headers }) => {
        const [_, filename] = headers['content-disposition'].split(";");

        const blob = new Blob(
          [data],
          { type: "application/octet-stream" }
        );

        saveAs(blob, filename.replace(/filename="(.*)"/, '$1'));

        this.props.onHide();
      })
      .catch(() => toastr.error('Nenhuma ordem de serviço para a data selecionada.'))
      .finally(() => window.hide_loader());
  }

  render() {
    const {
      show,
      onHide,
    } = this.props;
    const {
      type,
      show_values,
    } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        bsSize="large"
        backdrop="static"
        className="horizontal-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Exportar programação para PDF
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Selecione:</ControlLabel>
                <div className="d-flex">
                  <div className="mr-10 w-200">
                    <Checkbox
                      checked={type === TYPE.equipments}
                      checkboxClass="iradio_square-green"
                      increaseArea="20%"
                      label="&emsp;Somente equipamentos"
                      onClick={this.onChangeType(TYPE.equipments)}
                    />
                  </div>

                  <div className="mr-10 w-200">
                    <Checkbox
                      checked={type === TYPE.services}
                      checkboxClass="iradio_square-green"
                      increaseArea="20%"
                      label="&emsp;Somente serviços"
                      onClick={this.onChangeType(TYPE.services)}
                    />
                  </div>

                  <div className="mr-10 w-200">
                    <Checkbox
                      checked={type === TYPE.both}
                      checkboxClass="iradio_square-green"
                      increaseArea="20%"
                      label="&emsp;Equipamentos e serviços"
                      onClick={this.onChangeType(TYPE.both)}
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Selecione:</ControlLabel>
                <div>
                  <Checkbox
                    checked={show_values}
                    checkboxClass="icheckbox_square-green"
                    increaseArea="20%"
                    label="&emsp;Exibir valores"
                    onClick={this.onChangeShowValues}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Button onClick={this.onExport} bsClass="btn btn-primary mr-10">
                  Exportar
                </Button>

                <Button onClick={onHide} bsClass="btn btn-default">
                  Cancelar
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExportScheduleModal;
