import React from "react";
import {
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from 'axios';
import AsyncSelect from 'react-select/lib/Async';

import debounce from "../../utils/debounce.js";

class OperatorsList extends React.Component {

  loadOptions = debounce((input, callback) => {
    this.loadOperators(input, callback);
  }, 500);

  loadOperators = (input, callback) => {
    axios.post(`/operators/find?query=${input}`)
      .then(response => {
        callback(response.data);
      })
      .catch(this.onFetchError);
  };

  renderOperator = ({ id, code, name, photo, _destroy }) => {
    const {
      canUpdate,
      onRemoveOperator,
    } = this.props;

    if (_destroy) {
      return null;
    }

    return (
      <OverlayTrigger
        key={code}
        placement="top"
        overlay={(
            <Tooltip id={code}>
              {name}
            </Tooltip>
          )}
      >
        <div className="dashboard-operator-wrapper">
          <img
            className="dashboard-operator"
            src={photo}
            alt={name}
          />

          {
            onRemoveOperator && canUpdate  && (
              <div onClick={() => onRemoveOperator(id)} className="ic-close">
                <i className="fa fa-close" />
              </div>
            )
          }
        </div>
      </OverlayTrigger>
    );
  }

  selectOperator = ({ operator_id, photo, code, name }) => {
    const { onAddOperator } = this.props;

    onAddOperator({ operator_id, photo, code, name });
  }

  render() {
    const {
      operators,
      canUpdate,
      onAddOperator,
    } = this.props;

    const team = operators || [];

    return (
      <div>
        <div className="dashboard-operators">
          {
            team.map((operator) => this.renderOperator(operator))
          }
        </div>

        {
          onAddOperator && canUpdate && (
            <div className="add-operators">
              <AsyncSelect
                loadingMessage={() => "Carregando..."}
                placeholder="Adicionar operador..."
                noOptionsMessage={() => "Nenhum resultado encontrado"}
                className="custom-select"
                loadOptions={this.loadOptions}
                onChange={this.selectOperator}
                getOptionLabel={operator => operator.name}
                getOptionValue={operator => operator.operator_id}
              />
            </div>
          )
        }
      </div>
    );
  }
}

export default OperatorsList;
